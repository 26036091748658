import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import FeatureList from "../feature-list";
import FeatureSelectionInfo from "../feature-selection-info";
import { useMainPanelContext } from "./context";
function MainPanel() {
    const { contentType, panelPosition, collapsed, feature } = useMainPanelContext();
    // we keep using the old content stored in contentRef.current if we do not have any new content
    // to allow for a smooth transition when collapsing the container
    const contentRef = useRef();
    const isShowingSelectionInfoRef = useRef(false);
    if (contentType) {
        isShowingSelectionInfoRef.current = contentType === "selectionInfo";
        contentRef.current = (_jsxs(_Fragment, { children: [_jsx(FeatureList, { enableKeyboardControl: !collapsed }), isShowingSelectionInfoRef.current && (_jsx(FeatureSelectionInfo, { feature: feature, enableKeyboardControl: !collapsed }))] }));
    }
    return (_jsx("div", { className: `ms3-panel ms3-panel--docked-${panelPosition} ${collapsed ? "ms3-panel--empty" : ""} ${isShowingSelectionInfoRef.current ? "ms3-panel--hide-list" : ""}`, children: _jsx("div", { className: "ms3-panel__inner", children: contentRef.current }) }));
}
export default MainPanel;
