import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useSelector } from "react-redux";
import useStickyHeader from "../../hooks/useStickyHeader";
import { featureSelectionInfoStuckHeaderHeightSelector } from "../../store/selectors";
import Container from "./container";
const stopEventPropagation = (e) => e.stopPropagation();
function WithStickyHeader({ header, content, feature, close, renderWrapper }) {
    const stuckHeaderHeight = useSelector(featureSelectionInfoStuckHeaderHeightSelector);
    const { isHeaderStuck, stickyHeaderRef, stickyScrollAreaRef, onScroll } = useStickyHeader({
        stuckHeaderSize: stuckHeaderHeight,
        resetDeps: [feature.id],
    });
    const stuckClass = (prefix) => prefix + " " + (isHeaderStuck ? prefix + "--stuck" : "");
    const stuckHeaderClasses = `ms3-sticky-header__header ${isHeaderStuck
        ? "ms3-sticky-header__header--stuck"
        : "ms3-sticky-header__header--stuck-placeholder"}`;
    const stickyHeaderClasses = `ms3-sticky-header__header ${isHeaderStuck
        ? "ms3-sticky-header__header--sticky-placeholder"
        : "ms3-sticky-header__header--sticky"}`;
    return (_jsxs(Container, { className: stuckClass("ms3-sticky-header__container"), children: [_jsx("div", { "aria-hidden": "true", className: stuckHeaderClasses, children: header }), renderWrapper({
                ref: stickyScrollAreaRef,
                className: stuckClass("ms3-sticky-header__scroll-area"),
                onTouchMove: stopEventPropagation,
                onScroll: onScroll,
                children: (_jsxs(_Fragment, { children: [close, _jsx("div", { className: stickyHeaderClasses, ref: stickyHeaderRef, children: header }), content] })),
            })] }));
}
export default WithStickyHeader;
