import { getAndObserveState } from "@mapsight/lib-redux/observe-state";
import toggleClass from "@neonaut/lib-js/es/dom/access/toggle-class";
import { VIEW_FULLSCREEN, VIEW_MAP_ONLY } from "../../config/constants/app";
import { viewSelector } from "../../store/selectors";
function onTouchMoveNoScroll(e) {
    e.preventDefault();
}
/**
 * create a no-scroll-handler
 *
 * @param {function} selector             redux store selector to test if scroll should be inhibited
 * @param {string}   [noScrollCssClass]   class to set, default = 'ms3--map-no-scroll'
 * @returns {function}                      function to update no-scroll state.
 */
export function createNoScrollHandler(selector, noScrollCssClass = "ms3--map-no-scroll") {
    return function noScrollHandler(state, forceAllow) {
        const isNoScroll = !forceAllow && selector(state);
        toggleClass(window.document.documentElement, noScrollCssClass, isNoScroll);
        window.document[isNoScroll ? "addEventListener" : "removeEventListener"]("touchmove", onTouchMoveNoScroll);
        return isNoScroll;
    };
}
export const defaultNoScrollHandler = createNoScrollHandler(function (state) {
    const view = viewSelector(state);
    return view === VIEW_MAP_ONLY || view === VIEW_FULLSCREEN;
});
export const defaultNoScrollObserveSelector = viewSelector;
/**
 * This plugin will observe the state for changes to the view (default) and will call the defined handler.
 * By default, this will disable scrolling the document and add a class to the document element
 * when the view is in either "mapOnly" or "fullscreen" view.
 *
 * @param {object} [options] options
 * @param {function(object): void} [options.noScrollHandler] handler to call (will be passed the current state)
 * @param {function(object): *} [options.noScrollObserveSelector] selector to observe state with
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { noScrollHandler = defaultNoScrollHandler, noScrollObserveSelector = defaultNoScrollObserveSelector, } = options;
    if (typeof window === "undefined") {
        console.info("This plugin might not work as intended, if not run in the browser!");
    }
    return {
        afterCreate: function noScrollPlugin(context) {
            const { store } = context;
            getAndObserveState(store, noScrollObserveSelector, (a, b, state) => noScrollHandler(state, false));
        },
    };
}
//// complex example for no-scroll if on mobile and @mapsight/ui wants it, but site has reasons to keep scrolling
//import setDocumentScroll from '@neonaut/simplejs/dom/set-document-scroll';
//import {noScrollHandler, noScrollObserveSelector} from '@mapsight/ui';
//
//let currentIsMainNavigationOpen = getIsNavigationOpen();
//subscribeNavigationOpenChange(isMainNavigationOpen => {
//	currentIsMainNavigationOpen = isMainNavigationOpen;
//	noScrollHandler(store.getState(), currentIsMainNavigationOpen);
//});
//observeState(store, noScrollObserveSelector, newView => {
//	const isNoScroll = noScrollHandler(store.getState(), currentIsMainNavigationOpen);
//	if (isNoScroll) { // zurückscrollen, bei Wechsel der Ansicht oder dem Schließen der Navigation, falls vorher gescrollt worden ist und es jetzt verboten ist.
//		setDocumentScroll(0);
//	}
//	closeNavigation();
//});
//noScrollHandler(store.getState(), currentIsMainNavigationOpen);
