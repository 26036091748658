var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, memo } from "react";
import SwitcherHeader from "./SwitcherHeader";
const defaultRenderHeader = (label, props) => (_jsx(SwitcherHeader, Object.assign({ label: label }, props)));
function Switcher(props) {
    const { as: T = "div", headlineAs = "h3", entriesAs: V = "ul", baseClass = "ms3-layer-switcher", // TODO: Move to generic class name
    renderEntry, renderHeader = defaultRenderHeader, ids = [], group = null, headerAttributes = {}, entriesAttributes = {} } = props, attributes = __rest(props, ["as", "headlineAs", "entriesAs", "baseClass", "renderEntry", "renderHeader", "ids", "group", "headerAttributes", "entriesAttributes"]);
    attributes.className = `${baseClass} ${attributes.className || ""}`;
    headerAttributes.className = `${baseClass}__header ${headerAttributes.className || ""}`;
    entriesAttributes.className = `${baseClass}__entries ${entriesAttributes.className || ""}`;
    if (group) {
        attributes["data-ms3-switcher-group"] = group;
        headerAttributes["data-ms3-switcher-header-group"] = group;
        entriesAttributes["data-ms3-switcher-entries-group"] = group;
    }
    return (_jsxs(T, Object.assign({}, (T === Fragment ? {} : attributes), { children: [group
                ? renderHeader(group, Object.assign({ as: headlineAs }, headerAttributes))
                : null, _jsx(V, Object.assign({}, entriesAttributes, { children: ids.map((id) => renderEntry(id, group)) }))] })));
}
export default memo(Switcher);
