import { async } from "../../../../lib/base/actions";
import { getFilteredFeatures, } from "../../../../lib/feature-selections/selectors";
import { deselect, select, selectExclusively, } from "../../../../lib/feature-selections/actions";
const featureSelectionsObserverInstances = new WeakMap();
export default class FeatureSelectionConnector {
    _featureSelectionsController;
    _reverseData;
    _data;
    _previousData;
    _listeners;
    _unsubscribeFeatureSelections = null;
    constructor(featureSelectionsController) {
        this._featureSelectionsController = featureSelectionsController;
        this._reverseData = new Map();
        this._data = new Map();
        this._previousData = new Map();
        this._listeners = [];
    }
    static getInstance(featureSelectionsController) {
        if (featureSelectionsObserverInstances.has(featureSelectionsController)) {
            return featureSelectionsObserverInstances.get(featureSelectionsController);
        }
        const instance = new FeatureSelectionConnector(featureSelectionsController);
        featureSelectionsObserverInstances.set(featureSelectionsController, instance);
        return instance;
    }
    select(selectionId, featureId, exclusive = true) {
        const action = exclusive ? selectExclusively : select;
        this._featureSelectionsController.dispatch(async(action(this._featureSelectionsController.getName(), selectionId, featureId)));
    }
    get(featureId) {
        return this._data.get(featureId);
    }
    getPrevious(featureId) {
        return this._previousData.get(featureId);
    }
    getAllFeaturesWithState(state) {
        return this._reverseData.get(state) || [];
    }
    deselect(selectionId, featureId) {
        this._featureSelectionsController.dispatch(async(deselect(this._featureSelectionsController.getName(), selectionId, featureId)));
    }
    subscribe(listener) {
        this._listeners.push(listener);
        if (this._unsubscribeFeatureSelections === null &&
            this._listeners.length >= 1) {
            this._unsubscribeFeatureSelections =
                this._featureSelectionsController.observeUncontrolled((s) => s, // select all
                (selections) => {
                    if (!selections) {
                        return;
                    }
                    // update selection maps
                    const previous = this._previousData;
                    this._previousData = this._data;
                    this._data = previous;
                    this._data.clear();
                    this._reverseData.clear();
                    Object.keys(selections).forEach((state) => {
                        getFilteredFeatures(selections[state])?.forEach((featureId) => {
                            if (this.get(featureId) !== undefined) {
                                return;
                            }
                            this._data.set(featureId, state);
                            this._reverseData.set(state, this.getAllFeaturesWithState(state).concat([featureId]));
                        });
                    });
                    // feature changes
                    this._emit();
                });
        }
        return () => this.unsubscribe(listener);
    }
    unsubscribe(listener) {
        this._listeners = this._listeners.filter((l) => l !== listener);
        if (this._listeners.length === 0 &&
            this._unsubscribeFeatureSelections) {
            this._unsubscribeFeatureSelections();
            this._unsubscribeFeatureSelections = null;
        }
    }
    _emit() {
        this._listeners.forEach((listener) => listener());
    }
}
