var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect } from "react-redux";
import { MAP } from "../../config/constants/controllers";
import Switcher from "../switcher/Switcher";
const selector = (state, { layerIdsSelector }) => ({
    ids: layerIdsSelector(state[MAP]),
});
export default connect(selector, null, (stateProps, dispatchProps, _a) => {
    var { 
    // Need to list the selectors here so they do not get passed as attribute props to the element but get filtered:
    layerIdsSelector } = _a, attributes = __rest(_a, ["layerIdsSelector"]);
    return (Object.assign(Object.assign({}, stateProps), attributes));
})(Switcher);
