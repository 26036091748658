import { APP_EVENT_PARTIAL_CONTENT_CHANGED } from "../../components/helping/app-channel";
/**
 * This plugin will call the partialChangeHandler (@see {CreateOptions.partialChangeHandler}) when some partial content
 * has changed in the mapsight ui controlled dom, allowing for manipulation of the changed dom (e.g. displaying charts in the
 * feature selection info)
 *
 * @param {object} [options] options currently not used
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    return {
        afterCreate: function partialContentChangedEventPlugin(context) {
            if (context.createOptions.partialChangeHandler) {
                context.appChannelListeners.push([
                    APP_EVENT_PARTIAL_CONTENT_CHANGED,
                    context.createOptions.partialChangeHandler,
                ]);
            }
        },
    };
}
