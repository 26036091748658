import { useEffect } from "react";
import useUpdateMapSizeCallback from "./useUpdateMapSizeCallback";
export default function useUpdateMapSizeOnTransitionEnd(target, 
// TODO: use hook
dispatch, 
// TODO: why is this a ref?
positionRef, reCenter = true) {
    const updateMapSize = useUpdateMapSizeCallback(dispatch, positionRef, reCenter);
    useEffect(() => {
        if (target === undefined) {
            return;
        }
        const handleTransitionEnd = (e) => {
            if (e.target === target) {
                console.log("UPDATE MAP SIZE ON TRANSITION END");
                updateMapSize();
            }
        };
        target.addEventListener("transitionend", handleTransitionEnd);
        return () => {
            target.removeEventListener("transitionend", handleTransitionEnd);
        };
    }, [target, updateMapSize]);
}
