import { buffer, createEmpty, createOrUpdateFromCoordinate, } from "ol/extent";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { isNonNullable } from "@neonaut/lib-js/nonNullable";
import getCentroidForFeatures from "../features/getCentroidForFeatures";
import getCentroid from "./getCentroid";
import getUid from "./getUid";
const defaultCreateClusterFeature = (centroid, features) => {
    const clusterFeature = new Feature(new Point(centroid));
    clusterFeature.set("features", features);
    return clusterFeature;
};
const defaultDistance = 20;
export default function cluster(features, resolution, getFeaturesInExtent, distance = defaultDistance, createClusterFeature = defaultCreateClusterFeature, mapFeatureToCoordinate = getCentroid) {
    if (resolution === undefined) {
        return features;
    }
    const mapDistance = distance * resolution;
    const extent = createEmpty();
    const isClusteredMap = {};
    const markClustered = (feature) => {
        isClusteredMap[getUid(feature)] = true;
    };
    const isAlreadyClustered = (feature) => getUid(feature) in isClusteredMap;
    const isNotAlreadyClustered = (feature) => !isAlreadyClustered(feature);
    return features
        .flatMap((feature) => {
        if (isAlreadyClustered(feature)) {
            return undefined;
        }
        const coordinate = mapFeatureToCoordinate(feature);
        const canCluster = coordinate &&
            coordinate.length >= 2 &&
            coordinate[0] &&
            coordinate[1];
        if (canCluster) {
            // reuse extent to reduce object creation
            createOrUpdateFromCoordinate(coordinate, extent);
            buffer(extent, mapDistance, extent);
            const neighbors = getFeaturesInExtent(extent).filter(isNotAlreadyClustered);
            if (neighbors.length >= 2) {
                neighbors.forEach(markClustered);
                return createClusterFeature(getCentroidForFeatures(neighbors), neighbors);
            }
        }
        return feature;
    })
        .filter(isNonNullable);
}
