import { jsx as _jsx } from "react/jsx-runtime";
import { createFeatureSelectionSelector, getFilteredFeatures, } from "@mapsight/core/lib/feature-selections/selectors";
import { findFeatureInFeatureSourcesById } from "@mapsight/core/lib/feature-sources/selectors";
import { mapSizeSelector as getMapSize } from "@mapsight/core/lib/map/selectors";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { FEATURE_SELECTIONS, FEATURE_SOURCES, MAP, } from "../../config/constants/controllers";
import { FEATURE_SELECTION_HIGHLIGHT } from "../../config/feature/selections";
import getFeatureProperty from "../../helpers/get-feature-property";
import TooltipContent from "./tooltip-content";
const featureSelector = createSelector((state) => state[FEATURE_SOURCES], createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT), (featureSources, selection) => {
    const features = getFilteredFeatures(selection);
    const featureId = features && features[0];
    if (featureId) {
        return findFeatureInFeatureSourcesById(featureSources, featureId);
    }
    return null;
});
function mapSizeSelector(state) {
    return getMapSize(state[MAP]);
}
/**
 * @param feature feature
 * @param tooltipElementRef feature
 * @returns position and withTransition
 */
function useGetPositionWithTransition(feature, tooltipElementRef) {
    const [withTransition, setWithTransition] = useState(true);
    const mapSize = useSelector(mapSizeSelector);
    const [position, setPosition] = useState(null);
    useEffect(() => {
        setWithTransition(false);
    }, [mapSize]);
    useEffect(() => {
        var _a, _b;
        const mapElNull = (_b = (_a = tooltipElementRef.current) === null || _a === void 0 ? void 0 : _a.closest(".ms3-map-wrapper")) === null || _b === void 0 ? void 0 : _b.querySelector(".ms3-map-target");
        if (!mapElNull || !feature) {
            return undefined;
        }
        const mapEl = mapElNull;
        /**
         * @param {MouseEvent} e event
         */
        function onMouseMove(e) {
            const x = e.clientX;
            const y = e.clientY;
            const { left, right, top, bottom } = mapEl.getBoundingClientRect();
            setPosition({
                left: x - left,
                right: right - x,
                top: y - top,
                bottom: bottom - y,
            });
            setWithTransition(true);
        }
        function onMouseOut() {
            setPosition(null);
            setWithTransition(true);
        }
        mapEl.addEventListener("mousemove", onMouseMove);
        mapEl.addEventListener("mouseout", onMouseOut);
        return () => {
            setPosition(null);
            setWithTransition(true);
            mapEl.removeEventListener("mousemove", onMouseMove);
            mapEl.removeEventListener("mouseout", onMouseOut);
        };
    }, [feature, setPosition, tooltipElementRef]);
    return [position, withTransition];
}
function Tooltip() {
    const tooltipElementRef = useRef(null);
    const feature = useSelector(featureSelector);
    // Keeping the last feature to allow fade out without losing the content
    const [lastFeature, setLastFeature] = useState(undefined);
    useEffect(() => {
        if (!feature) {
            setLastFeature(feature);
        }
    }, [feature, setLastFeature]);
    const [position, withTransition] = useGetPositionWithTransition(feature, tooltipElementRef);
    const classes = `ms3-tooltip ms3-tooltip--${feature ? "active" : "inactive"}`;
    const classesInner = `ms3-tooltip__inner ms3-tooltip__inner--${feature ? "active" : "inactive"}`;
    const styles = {};
    if (position) {
        styles.transform = `translate(${-position.right}px, ${position.top}px)`;
    }
    else {
        styles.display = "none";
    }
    if (!withTransition) {
        styles.transition = "none";
    }
    const localFeature = feature || lastFeature;
    const html = getFeatureProperty(localFeature, "tooltip");
    const text = html ? null : getFeatureProperty(localFeature, "name");
    let content = null;
    if (html) {
        content = (_jsx("div", { className: classesInner, dangerouslySetInnerHTML: { __html: html } }));
    }
    else if (text) {
        content = _jsx("div", { className: classesInner, children: text });
    }
    return (_jsx("div", { className: classes, style: styles, ref: tooltipElementRef, children: _jsx(TooltipContent, { feature: localFeature, html: html, text: text, children: content }) }));
}
export default memo(Tooltip);
