import { LOAD_FEATURE_SOURCE_SUCCESS } from "@mapsight/core/lib/feature-sources/actions";
import { findFeatureInFeatureSourcesById } from "@mapsight/core/lib/feature-sources/selectors";
import get from "@neonaut/lib-js/es/object/getPath";
import { compose } from "redux";
import { FEATURE_SELECTIONS, FEATURE_SOURCES, } from "../../config/constants/controllers";
import createActionWatcher from "../../helpers/create-action-watcher";
import getFeatureProperty from "../../helpers/get-feature-property";
import { FETCH_TEXT_FAILURE, FETCH_TEXT_SUCCESS, setFeatureDetailsUrl, } from "../../store/actions";
const defaultFeatureSelection = "select";
const defaultFeatureSelectionsController = FEATURE_SELECTIONS;
const defaultFeaturePropertyDetailsUrl = "detailsUrl";
/**
 * This plugin will delay the render until the details of the feature has been loaded
 *
 * @param {object} [options] options
 * @param {string} [options.featureSelectionsController] name of the feature selections controller, defaults to mapsight ui default
 * @param {string} [options.featureSelection="select"] name of the feature selection to track
 * @param {string} [options.featurePropertyDetailsUrl="detailsUrl"] feature property to get the details url from
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createFeatureDetailsLoadedPlugin(options = {}) {
    const { featureSelection = defaultFeatureSelection, featureSelectionsController = defaultFeatureSelectionsController, featurePropertyDetailsUrl = defaultFeaturePropertyDetailsUrl, } = options;
    const actionWatcherSources = createActionWatcher();
    const actionWatcherDetails = createActionWatcher();
    return {
        afterInit: function runAwaitFeatureDetailsLoadedInitPlugin(context) {
            context.storeEnhancer = compose(context.storeEnhancer, actionWatcherSources.enhancer, actionWatcherDetails.enhancer);
        },
        beforeRender: function runAwaitFeatureDetailsLoadedRenderPlugin(context) {
            if (!context.canPluginsDelayRender) {
                return Promise.resolve(undefined);
            }
            const featureId = getSelectedFeatureIdFromConfig(context.baseMapsightConfig, featureSelection, featureSelectionsController);
            return new Promise(function (resolve) {
                if (!featureId) {
                    resolve();
                    return;
                }
                function checkSources() {
                    const feature = findFeatureInFeatureSourcesById(context.store.getState()[FEATURE_SOURCES], featureId);
                    if (!feature) {
                        return;
                    }
                    const detailsUrl = getFeatureProperty(feature, featurePropertyDetailsUrl);
                    if (!detailsUrl) {
                        // no need to wait, because we do not have a details url to load
                        resolve();
                        return;
                    }
                    actionWatcherDetails.handler = (action) => {
                        if (action.type === FETCH_TEXT_SUCCESS ||
                            action.type === FETCH_TEXT_FAILURE) {
                            resolve();
                            actionWatcherDetails.handler = undefined;
                        }
                    };
                    context.store.dispatch(setFeatureDetailsUrl(getFeatureProperty(feature, featurePropertyDetailsUrl)));
                }
                checkSources();
                actionWatcherSources.handler = (action) => {
                    // wait for feature sources to be loaded
                    if (action.type === LOAD_FEATURE_SOURCE_SUCCESS) {
                        checkSources();
                    }
                };
            });
        },
    };
}
function getSelectedFeatureIdFromConfig(baseMapsightConfig, featureSelection, featureSelectionsControllerName) {
    if (get(baseMapsightConfig, [
        featureSelectionsControllerName,
        featureSelection,
        "features",
        "length",
    ])) {
        return baseMapsightConfig[featureSelectionsControllerName][featureSelection].features[0];
    }
    return undefined;
}
