import { jsx as _jsx } from "react/jsx-runtime";
import { reduceLayersToLegends, visibleLayersWithLegendsSelector, } from "@mapsight/core/lib/map/selectors";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MAP } from "../../config/constants/controllers";
import { translate } from "../../helpers/i18n";
import { setOverlayModalVisible } from "../../store/actions";
import Attribution from "./attribution";
const legendsSelector = createSelector((state) => visibleLayersWithLegendsSelector(state[MAP]), 
// we need to run this as well, cause it's filtering stuff
reduceLayersToLegends);
function InfoOverlayRight() {
    const dispatch = useDispatch();
    const legends = useSelector(legendsSelector);
    const expand = useCallback(() => {
        dispatch(setOverlayModalVisible(true));
    }, [dispatch]);
    const hasLegend = Object.keys(legends).length > 0;
    return (_jsx("div", { className: "ms3-info-overlay__desktop-content ms3-info-overlay__area ms3-info-overlay__area--right", children: _jsx(Attribution, { children: hasLegend && (_jsx("button", { type: "button", onClick: expand, children: translate("ui.map-overlay.info.legend") })) }) }));
}
export default memo(InfoOverlayRight);
