var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, memo } from "react";
import FeatureListEmptyMessage from "./empty-message";
const FeatureListContent = memo(forwardRef(function FeatureListContent(props, ref) {
    const { status, children, showFeatureListInfo, as: T = "div", emptyAs, featureSourceId } = props, attributes = __rest(props, ["status", "children", "showFeatureListInfo", "as", "emptyAs", "featureSourceId"]);
    let content = children;
    if (!content) {
        const hasSource = featureSourceId !== null &&
            featureSourceId !== undefined &&
            featureSourceId !== "";
        content = (_jsx(FeatureListEmptyMessage, { as: emptyAs, hasSource: hasSource }));
    }
    const statusClass = status ? " ms3-list--status-" + status : "";
    return (_jsx(T, Object.assign({ ref: ref, className: `[ ms3-list ms3-list--features ${statusClass} ] ${attributes.className || ""}` }, attributes, { children: content })));
}));
export default FeatureListContent;
