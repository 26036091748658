import React, { createContext, useCallback, useContext, } from "react";
export const ComponentsContext = createContext({});
ComponentsContext.displayName = "ComponentsContext";
/** @deprecated */
export function useContextComponentWithFallback(componentName) {
    const Comp = useContext(ComponentsContext)[componentName];
    return useCallback((fallback, props) => {
        if (Comp) {
            return React.createElement(Comp, Object.assign(Object.assign({}, props), { fallback }));
        }
        return fallback;
    }, [Comp]);
}
export function makeReplaceableComponent(componentName, Component) {
    const NewComp = (props) => {
        const CtxComp = useContext(ComponentsContext)[componentName];
        if (CtxComp !== undefined) {
            return React.createElement(CtxComp, props);
        }
        return React.createElement(Component, props);
    };
    NewComp.displayName = `MsUiReplaceable${componentName}`;
    return NewComp;
}
