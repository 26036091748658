import { createFeatureSelectionSelector, getFilteredFeatures, } from "@mapsight/core/lib/feature-selections/selectors";
import { observeState } from "@mapsight/lib-redux/observe-state";
import { trackEvent } from "@neonaut/lib-js/es/misc/piwik";
import * as c from "../../config/constants/controllers";
import { FEATURE_SELECTION_SELECT } from "../../config/feature/selections";
const defaultCategory = "Mapsight";
const defaultAction = "SelectedFeature";
const defaultFeatureSelectionsController = c.FEATURE_SELECTIONS;
const defaultFeatureSelection = FEATURE_SELECTION_SELECT;
/**
 * This plugin will track piwik actions when the a feature is selected
 *
 * @see `@neonaut/lib-js/es/misc/piwik`
 *
 * @param {object} [options] options
 * @param {string} [options.featureSelectionsController] name of the feature selections controller, defaults to mapsight ui default
 * @param {string} [options.featureSelection="select"] name of the feature selection to track
 * @param {string} [options.category="Mapsight"] piwik category to track
 * @param {string} [options.action="SelectedFeature"]  piwik action to track
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { featureSelectionsController = defaultFeatureSelectionsController, featureSelection = defaultFeatureSelection, category = defaultCategory, action = defaultAction, } = options;
    const { name = featureSelection } = options;
    return {
        afterCreate: function piwikTrackFullscreenToggleEvent(context) {
            const { store } = context;
            observeState(store, createFeatureSelectionSelector(featureSelectionsController, featureSelection), (selection) => {
                const filteredFeatures = getFilteredFeatures(selection);
                if (!filteredFeatures)
                    return;
                const hasFeatures = !!filteredFeatures.length;
                if (hasFeatures) {
                    trackEvent(category, action, name, JSON.stringify(filteredFeatures));
                }
            }); // TODO: Check!
        },
    };
}
