import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import getFeatureProperty from "../../helpers/get-feature-property";
import { makeReplaceableComponent } from "../../helpers/components";
const Header = memo(function FeatureSelectionInfoHeader({ feature }) {
    const name = getFeatureProperty(feature, "name");
    return (_jsx("header", { className: "ms3-feature-selection-info__header", children: _jsx("h3", { className: "ms3-feature-selection-info__headline", children: name }) }));
});
export default makeReplaceableComponent("FeatureSelectionInfoHeader", Header);
export { Header as NonReplaceableHeader };
