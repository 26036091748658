var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { deselectAll } from "@mapsight/core/lib/feature-selections/actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FEATURE_SELECTIONS } from "../../config/constants/controllers";
import { FEATURE_SELECTION_SELECT } from "../../config/feature/selections";
import { setView } from "../../store/actions";
import { isMapOutOfViewportSelector, isViewMobile, viewSelector, viewToggleOptionsSelector, } from "../../store/selectors";
import ViewToggleButton from "./view-toggle-button";
export default connect(createStructuredSelector({
    view: viewSelector,
    isMapOutOfViewport: isMapOutOfViewportSelector,
    options: viewToggleOptionsSelector,
}), null, (_a, _b, ownProps) => {
    var { options } = _a, stateProps = __rest(_a, ["options"]);
    var dispatch = _b.dispatch;
    return (Object.assign(Object.assign(Object.assign({}, stateProps), { dispatch: dispatch, changeView: (currentView, nextView) => {
            dispatch(setView(nextView));
            if (options.deselectFeaturesOnToggle && isViewMobile(currentView)) {
                dispatch(deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT));
            }
        } }), ownProps));
})(ViewToggleButton);
