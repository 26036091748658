import { mergeAll } from "@mapsight/core/lib/base/actions";
import { createStorage } from "@mapsight/lib-redux/local-storage";
import merge from "lodash/merge";
const defaultLocalStorageKey = "nn-mapsight--v3-ui";
const defaultLocalStoragePaths = [
    //['app', 'isFullscreen'],
    //['app', 'view'],
    ["app", "userPreferenceListVisible"],
];
/**
 * This plugin will synchronize the some paths of the store with the browser's local storage and restored on initialization.
 * See {CreateOptions.localStorageKey} and {CreateOptions.localStoragePaths} for configuration.
 *
 * @param {object} [options] options
 * @param {string} [options.localStorageKey="nn-mapsight--v3-ui"] storage key
 * @param {string[][]} [options.localStoragePaths] paths to store
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin({ localStorageKey = defaultLocalStorageKey, localStoragePaths = defaultLocalStoragePaths, } = {}) {
    if (typeof window === "undefined") {
        console.error("This plugin will only work as intended in the browser!");
    }
    let restoredState;
    let isRestored = false;
    let storage;
    return {
        afterInit: function localStorageInitPlugin({ initialState, isStateReHydrated, }) {
            storage = createStorage(localStorageKey);
            // override initial state from local storage
            restoredState = storage.getLocalStorageState();
            // We cannot change the state before we have not at least rendered the
            // dehydrated html at once to prevent a mixed up DOM when server-side
            // state and client-side determined state mismatch or has changed
            // since page load. If the app is not re-hydrated but initially rendered
            // in the client we can omit the additional render and set the restored
            // state on creation (before render).
            if (!isStateReHydrated) {
                isRestored = true;
                // FIXME: This should only merge the paths specified and not ALL!
                merge(initialState, restoredState);
            }
        },
        afterCreate: function localStorageCreatePlugin({ store }) {
            // sync local storage
            if (localStoragePaths) {
                storage.synchronizePathsToLocalStorage(store, localStoragePaths);
            }
        },
        afterRender: function localStorageAfterRenderPlugin({ store }) {
            if (!isRestored) {
                store.dispatch(mergeAll(restoredState));
            }
        },
    };
}
