import KeyboardPan from "ol/interaction/KeyboardPan";
import base from "./_base";
export default {
    type: "KeyboardPanInteraction",
    Constructor: KeyboardPan,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        condition: "condition",
        duration: "duration",
        pixelDelta: "pixelDelta",
    },
};
