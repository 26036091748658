import { setViewportAnchor } from "@mapsight/core/lib/map/actions";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { MAP } from "../config/constants/controllers";
import useUpdateMapSizeOnTransitionEnd from "./useUpdateMapSizeOnTransitionEnd";
function useMapsightPanel(containerRef, panelPosition, collapsed) {
    var _a;
    const previousPositionRef = useRef(null);
    const previousCollapsedRef = useRef();
    const dispatch = useDispatch();
    useEffect(() => {
        if (previousPositionRef.current &&
            (previousPositionRef.current !== panelPosition ||
                previousCollapsedRef.current !== collapsed)) {
            dispatch(setViewportAnchor(MAP, "right"));
        }
        previousPositionRef.current = panelPosition;
        previousCollapsedRef.current = collapsed;
    }, [collapsed, dispatch, panelPosition]);
    useUpdateMapSizeOnTransitionEnd((_a = containerRef.current) !== null && _a !== void 0 ? _a : undefined, dispatch, 
    // TODO: behh 🤮
    previousPositionRef);
}
export default useMapsightPanel;
