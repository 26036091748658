import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { hasGeolocationSupport } from "@mapsight/core/lib/helpers";
import { memo, useCallback, useEffect, useState } from "react";
import FocusTrap from "focus-trap-react";
import { translate } from "../../helpers/i18n";
import StatusIndicator from "./status-indicator";
/**
 * @param places places
 * @param keyPath key path
 * @returns option elements
 */
function renderOptions(places, keyPath = []) {
    return Object.entries(places).map(([key, place]) => {
        const keyArr = [...keyPath, key];
        const keyStr = keyArr.join(",");
        if ("type" in place && place.type === "group") {
            return (_jsx("optgroup", { label: place.title, children: renderOptions(place.entries, keyArr) }, keyStr));
        }
        else {
            return (_jsx("option", { value: keyStr, children: place.title }, keyStr));
        }
    });
}
/* NOTICE: using onChange instead of onBlur as the change occurs just below this input and should be clear */
/* eslint-disable jsx-a11y/no-onchange */
function FeatureSorter({ places, sorting, geolocationStatus, onChange, requestGeolocation, }) {
    const [supportsGeoloc, setSupportsGeoloc] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setSupportsGeoloc(hasGeolocationSupport);
    }, []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);
    const onSelectChange = useCallback((e) => {
        const newValue = e.target.value;
        if (newValue === "geolocation") {
            requestGeolocation();
        }
        onChange(newValue);
    }, [onChange, requestGeolocation]);
    return (_jsxs("div", { className: `ms3-features-sorting ms3-features-sorting--${isOpen ? "active" : "inactive"}`, children: [_jsx("button", { type: "button", className: "ms3-filter-button " +
                    (isOpen
                        ? " ms3-filter-button--icon-sort-active"
                        : " ms3-filter-button--icon-sort"), onClick: toggleOpen, title: translate("ui.feature-list.sorting.announcements"), children: _jsx("i", { children: isOpen ? translate("close") : translate("open") }) }), _jsx("div", { children: isOpen && (_jsx(FocusTrap, { focusTrapOptions: {
                        clickOutsideDeactivates: true,
                        onDeactivate: handleClose,
                    }, children: _jsxs("div", { className: "ms3-features-sorting__panel", children: [translate("ui.feature-list.sorting.byDistance"), _jsxs("select", { value: sorting || "", onChange: onSelectChange, children: [_jsx("option", { value: "", children: translate("ui.feature-list.sorting.choose") }), supportsGeoloc && (_jsx("option", { value: "geolocation", children: translate("ui.feature-list.sorting.own") })), renderOptions(places)] }), _jsx(StatusIndicator, { status: geolocationStatus })] }) })) })] }));
}
export default memo(FeatureSorter);
