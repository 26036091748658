import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
const FeatureListContext = createContext(null);
FeatureListContext.displayName = "FeatureListContext";
export const FeatureListContextProvider = ({ value, children, }) => _jsx(FeatureListContext.Provider, { value: value, children: children });
export const useFeatureListContext = () => {
    const value = useContext(FeatureListContext);
    if (value === null) {
        throw new Error("FeatureListContext is not provided");
    }
    return value;
};
