import Select from "ol/interaction/Select";
import base from "./_base";
export default {
    type: "SelectInteraction",
    Constructor: Select,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
    },
};
