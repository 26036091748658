import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useRef } from "react";
import { translate } from "../helpers/i18n";
import { forwardRefValue } from "../helpers/react";
/* eslint-disable jsx-a11y/label-has-for */
const QueryInput = forwardRef(function QueryInput({ label, placeholder, className, groupClassName, resetButtonClassName, name, query, onChange, autoFocus, }, forwardedRef) {
    const inputRef = useRef(null);
    forwardRefValue(inputRef, forwardedRef);
    const handleInput = useCallback((e) => onChange(e.target.value), [onChange]);
    const handleReset = useCallback(() => {
        var _a;
        onChange("");
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [onChange, inputRef]);
    return (_jsxs("fieldset", { className: groupClassName, children: [_jsxs("label", { children: [_jsx("span", { className: "ms3-visuallyhidden", children: label }), _jsx("input", { ref: inputRef, className: className, placeholder: placeholder, type: "search", name: name, value: query, onChange: handleInput, autoComplete: "off", autoFocus: autoFocus })] }), query !== "" && (_jsx("button", { className: resetButtonClassName, type: "button", onClick: handleReset, children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.query-input.reset") }) }))] }));
});
export default QueryInput;
