/** Stores definitions */
export default class DependencyManager {
    _dependencies = {};
    constructor(dependencies = {}) {
        this._dependencies = dependencies;
    }
    static makeInstance(definition, ...args) {
        try {
            definition.events?.beforeCreation?.(...args);
            const instance = new definition.Constructor(...args);
            definition.events?.afterCreation?.(instance, ...args);
            return instance;
        }
        catch (exception) {
            DependencyManager._handleError(definition, `Failed to construct object "${definition.type}".`, exception, {
                Constructor: definition.Constructor,
                args,
            });
            return null;
        }
    }
    static _handleError(definition, ...errorMessages) {
        console.error(...errorMessages);
        const creationFailed = definition.events?.creationFailed;
        if (creationFailed) {
            creationFailed(...errorMessages);
        }
    }
    static checkObjectType(definition, obj) {
        return !!(obj && definition && obj instanceof definition.Constructor);
    }
    injectDefinitions(definitions) {
        for (const def of definitions) {
            this._dependencies[def.type] = def;
        }
    }
    getDefinition(type) {
        return this._dependencies[type];
    }
    makeInstance(type, ...args) {
        const def = this.getDefinition(type);
        if (!def) {
            return null;
        }
        return DependencyManager.makeInstance(def, ...args);
    }
    checkObjectType(type, obj) {
        if (typeof type !== "string") {
            return false;
        }
        return DependencyManager.checkObjectType(this.getDefinition(type), obj);
    }
}
