import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import * as nonNull from "@neonaut/lib-js/nonNullable";
import getCentroid from "@mapsight/lib-ol/feature/getCentroid";
import spreadPointClusterInRadius from "@mapsight/lib-ol/points/spreadPointClusterInRadius";
export default function createCreateClusterIntoSpreadRadiusFeature(resolution, radius) {
    return function clusterIntoSpreadRadiusFeature(clusterCenter, clusterFeatures) {
        const featureCentroids = clusterFeatures
            .map(getCentroid)
            .filter(nonNull.is);
        const spreadPoints = spreadPointClusterInRadius(clusterCenter, resolution, radius, featureCentroids);
        const result = [];
        Object.entries(spreadPoints).forEach(function createSpreadFeaturesForPoint([pointKey, spreadPoint]) {
            const pointI = Number(pointKey);
            nonNull.assert(spreadPoint[0]);
            nonNull.assert(spreadPoint[1]);
            const feature = nonNull.ensure(clusterFeatures[pointI]);
            const basePoint = nonNull.ensure(featureCentroids[pointI]);
            nonNull.assert(basePoint[0]);
            nonNull.assert(basePoint[1]);
            const translateX = spreadPoint[0] - basePoint[0];
            const translateY = spreadPoint[1] - basePoint[1];
            const id = feature.getId();
            // Offset feature
            const spreadFeature = feature.clone();
            spreadFeature.set("spread", true, true);
            spreadFeature.getGeometry()?.translate(translateX, translateY);
            spreadFeature.setId(id);
            result.push(spreadFeature);
            // Line from spread (translated) feature to base position
            const lineFeature = new Feature({
                id: "cluster-spread-line||" + id,
                spreadFeatureLine: true,
                geometry: new LineString([basePoint, spreadPoint]),
                selectable: false,
            });
            result.push(lineFeature);
            // Dot at original position
            const dotFeature = new Feature({
                id: "cluster-spread-dot||" + id,
                spreadFeatureDot: true,
                geometry: new Point(basePoint),
                selectable: false,
            });
            result.push(dotFeature);
        });
        return result;
    };
}
