import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRegionIdAndAnimateMap } from "../../store/actions";
import { regionsSelector, selectedRegionIdSelector } from "../../store/selectors";
const RegionSelectorEntry = memo(
/**
 * @param {{
 *   regionId: string,
 *   region: any
 * }} props props
 * @returns {import('react').ReactElement} element
 */
function RegionSelectorEntry({ regionId, region }) {
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        dispatch(setSelectedRegionIdAndAnimateMap(regionId));
    }, [dispatch, regionId]);
    const selectedRegionId = useSelector(selectedRegionIdSelector);
    return (_jsx("li", { className: `ms3-region-selector-entry${regionId === selectedRegionId
            ? " ms3-region-selector-entry--selected"
            : ""}`, children: _jsx("button", { type: "button", onClick: onClick, children: region.label }) }));
});
const RegionSelector = memo(function RegionSelector({ as: T = "ul", style = {}, }) {
    const regions = useSelector(regionsSelector);
    if (!regions) {
        return null;
    }
    return (_jsx(T, { className: "ms3-region-selector [ ms3-hint--right ms3-hint--rounded ]", style: style, "aria-label": "Gebiet auf Karte zentrieren \u2026", children: Object.entries(regions).map(([id, region]) => (_jsx(RegionSelectorEntry, { regionId: id, region: region }, id))) }));
});
export default RegionSelector;
