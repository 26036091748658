var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import merge from "lodash/merge";
import { createMapsightStore } from "@mapsight/core";
import { layerIdsExternalSwitcherSelector } from "@mapsight/core/lib/map/selectors";
import { siteConfig } from "./config";
import { VIEW_MOBILE } from "./config/constants/app";
import * as c from "./config/constants/controllers";
import { createDefaultControllers } from "./controllers/defaults";
import uiReducers from "./store/reducers";
import * as nonNull from "@neonaut/lib-js/nonNullable";
/**
 * Default mapsight ui renderer (Does nothing, but log a warning!).
 */
const defaultRenderer = (_container, _props, _hydrate = false) => {
    console.info("create has to be passed a renderer to render the app");
};
// FIXME: What's the point in having this if it's all undefined?
const defaultCreateOptions = {
    baseUrl: undefined,
    imagesUrl: undefined,
    // search
    searchUrl: undefined,
    searchQueryParameter: undefined,
    reHydratedState: undefined,
    uiState: {
        isFullscreen: false,
        listQuery: "",
        listSorting: undefined,
        listPage: 0,
        searchQuery: "",
        searchResult: {},
        searchResultSelectionFeatures: [],
        title: "",
        userPreferenceListVisible: true,
        mapIsOutOfViewport: false,
        // views
        view: VIEW_MOBILE,
        viewBreakpoints: [],
        embeddedMap: false,
        searchInMap: true,
        pageTitle: {
            show: true, // show page title
        },
        tagSwitcher: {
            show: false,
            featureSourceId: undefined,
            featureSourcesControllerName: c.FEATURE_SOURCES,
            toggleableGroups: false,
            sortTags: false, // sort tags with locale sort, but not the tagGroups. to sort tagGroups add all tagGroups to first feature entry (but with empty tags if the entry doesn't have tags for a tagGroup)
        },
        viewToggle: {
            show: true,
            deselectFeaturesOnToggle: true,
        },
        layerSwitcher: {
            show: {
                internal: true,
                external: false,
            },
            internal: {
                layerIdsSelector: undefined,
                grouped: false,
            },
            external: {
                // FIXME: function in redux state?
                layerIdsSelector: layerIdsExternalSwitcherSelector,
                grouped: true,
            },
        },
        list: {
            selectionBehavior: {
                desktop: "scrollToMap",
                mobile: "expandInList", // either 'expandInList', 'scrollToMap', 'showInMapOnlyView'
            },
            detailsInList: false,
            showSelectedOnly: false,
            highlightOnMouse: true,
            selectOnClick: true,
            deselectOnClick: false,
            cyclingControl: false,
            sortControl: true,
            filterControl: true,
            paginationControl: false,
            itemsPerPage: 10,
            stickyHeader: true,
            showVaryingListInfoOnly: true, // show info column only, if the contents vary (determined before list filter)
        },
        featureSelectionInfo: {
            stickyHeader: false,
            stuckHeaderHeight: 30, // px
        },
        regions: {},
        places: {},
    },
    plugins: [],
    renderer: defaultRenderer,
    components: {},
    renderBreakpoints: {
        // NOTE: Keep in sync with css!
        mobile: [0, 767],
        tablet: [768, 1014],
        desktop: [1015, -1],
    },
    partialChangeHandler: undefined,
    // add additional reducers
    reducers: Object.assign({}, uiReducers),
};
/**
 * Creates an mapsight ui instance
 *
 * @param container element the mapsight ui app should be rendered into
 * @param styleFunction the mapsight core vector style function (see @mapsight/core)
 * @param [baseMapsightConfig] base mapsight configuration TODO: document further
 * @param [createOptions] ui creation options TODO: document further
 * @returns mapsight ui instance
 */
export function create(container, styleFunction, baseMapsightConfig = {}, createOptions = {}) {
    var _a, _b;
    const context = {
        hasRendered: false,
        container: container,
        styleFunction: styleFunction,
        baseMapsightConfig: baseMapsightConfig,
        createOptions: merge({}, defaultCreateOptions, createOptions),
        appChannelListeners: [],
    };
    // transfer some create options to global site config to make them available even outside of the redux context
    // TODO: Replace this mechanic with an explicit API, e.g. exposing the site config directly as `@mapsight/ui/site-config` or
    //    make them local to the mapsight ui context to allow for several independent instances with different site configs
    siteConfig.baseUrl = (_a = context.createOptions.baseUrl) !== null && _a !== void 0 ? _a : siteConfig.baseUrl;
    siteConfig.imagesUrl =
        context.createOptions.imagesUrl || siteConfig.imagesUrl;
    siteConfig.searchUrl =
        context.createOptions.searchUrl || siteConfig.searchUrl;
    siteConfig.searchQueryParameter =
        context.createOptions.searchQueryParameter ||
            siteConfig.searchQueryParameter;
    // initial state
    context.initialState = merge({}, context.baseMapsightConfig, {
        app: context.createOptions.uiState,
    });
    delete context.createOptions.uiState;
    // override initial state by re-hydration
    context.isStateReHydrated = false;
    if (context.createOptions.reHydratedState !== undefined) {
        context.isStateReHydrated = true;
        merge(context.initialState, context.createOptions.reHydratedState);
    }
    // setup controllers
    context.controllers = Object.assign(createDefaultControllers(context), context.createOptions.controllers);
    // store enhancer
    const uiStoreEnhancer = applyMiddleware(thunk);
    context.storeEnhancer = context.createOptions.storeEnhancer
        ? compose(uiStoreEnhancer, context.createOptions.storeEnhancer)
        : uiStoreEnhancer;
    // plugin: afterInit
    callPlugins(context, "afterInit");
    context.store = createMapsightStore(context.controllers, Object.fromEntries(Object.entries((_b = context.createOptions.reducers) !== null && _b !== void 0 ? _b : {})
        .map(([name, reducer]) => nonNull.map(reducer, (reducer) => [name, reducer]))
        .filter(nonNull.is)), context.initialState, context.storeEnhancer);
    // render
    function internalRender() {
        const hydrate = !context.hasRendered && context.isStateReHydrated;
        nonNull.assert(context.store);
        nonNull.assert(context.createOptions.renderer);
        const props = Object.assign({ store: context.store, components: context.createOptions.components || {}, appChannelListeners: context.appChannelListeners }, context.rendererProps);
        context.renderRef = context.createOptions.renderer(context.container, props, hydrate);
        context.hasRendered = true;
        callPlugins(context, "afterRender");
        return context.renderRef;
    }
    // Render without waiting for plugins (for e.g. waiting on external data)
    context.render = function mapsightUiRender(rendererProps) {
        context.rendererProps = rendererProps;
        context.canPluginsDelayRender = false;
        callPlugins(context, "beforeRender");
        return internalRender();
    };
    // Render allowing plugins to delay render (for e.g. waiting on external data)
    context.renderAsync = function mapsightUiRenderAsync(rendererProps) {
        return __awaiter(this, void 0, void 0, function* () {
            context.rendererProps = rendererProps;
            context.canPluginsDelayRender = true;
            yield callAndAwaitPlugins(context, "beforeRender");
            return internalRender();
        });
    };
    // plugin: afterCreate
    callPlugins(context, "afterCreate");
    return context;
}
/**
 * Overrides plugins with the same name (last to come wins), null unsets the plugin,
 * Flattened order is in order of first occurrence of a name.
 *
 * @param plugins (sorted) array of plugin definitions to be flattened, null unsets
 * @returns flattened plugin definitions
 */
function flattenPlugins(plugins) {
    const pluginPositionByName = {};
    const flattenedPlugins = [];
    plugins.forEach(function filterPlugin(plugin) {
        const [key] = plugin;
        const position = pluginPositionByName[key];
        if (position !== undefined) {
            flattenedPlugins.splice(position, 1, plugin);
        }
        else {
            pluginPositionByName[key] = flattenedPlugins.length;
            flattenedPlugins.push(plugin);
        }
    });
    return flattenedPlugins.filter(([_, plugin]) => !!plugin);
}
/**
 * @param context context in which the plugin will be called
 * @param phase phase of the plugin
 * @returns array of plugin call return values
 */
function callPlugins(context, phase) {
    var _a, _b;
    return flattenPlugins((_b = (_a = context.createOptions) === null || _a === void 0 ? void 0 : _a.plugins) !== null && _b !== void 0 ? _b : [])
        .map(([_name, plugin]) => { var _a, _b; return (_b = (_a = plugin === null || plugin === void 0 ? void 0 : plugin[phase]) === null || _a === void 0 ? void 0 : _a.call(plugin, context)) !== null && _b !== void 0 ? _b : undefined; })
        .filter(nonNull.is);
}
/**
 * @param context context in which the plugin will be called
 * @param phase phase of the plugin
 * @returns promise that all plugins are resolved
 */
function callAndAwaitPlugins(context, phase) {
    return __awaiter(this, void 0, void 0, function* () {
        yield Promise.all(callPlugins(context, phase));
    });
}
