import Pointer from "ol/interaction/Pointer";
import base from "./_base";
export default {
    type: "PointerInteraction",
    Constructor: Pointer,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        handleDownEvent: "handleDownEvent",
        handleDragEvent: "handleDragEvent",
        handleEvent: "handleEvent",
        handleMoveEvent: "handleMoveEvent",
        handleUpEvent: "handleUpEvent",
    },
};
