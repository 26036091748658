import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../helpers/i18n";
import { setOverlayModalVisible } from "../../store/actions";
import { isOverlayModalVisibleSelector, isViewMobileOrMapOnlySelector, } from "../../store/selectors";
import Logo from "./logo";
function InfoOverlayLeft() {
    const dispatch = useDispatch();
    const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);
    const isOverlayModalVisible = useSelector(isOverlayModalVisibleSelector);
    const expand = useCallback(() => {
        dispatch(setOverlayModalVisible(true));
    }, [dispatch]);
    return (_jsxs("div", { className: "ms3-info-overlay__area ms3-info-overlay__area--left", children: [!isMobileOrMapOnly && (_jsx("div", { className: "ms3-info-overlay__logo ms3-info-overlay__desktop-content", children: _jsx(Logo, {}) })), _jsx("div", { className: "ms3-info-overlay__mobile-content", children: _jsx("button", { type: "button", className: "ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--info", "aria-expanded": isOverlayModalVisible, onClick: expand, children: _jsx("span", { className: "ms3-map-overlay__button__label", children: translate("ui.map-overlay.info.open") }) }) })] }));
}
export default memo(InfoOverlayLeft);
