import { createSelector } from "reselect";
import { ERROR_NOT_ACCURATE } from "./actions";
export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";
export const geolocationStatusSelector = createSelector([(state) => state.error, (state) => state.isRequesting], (error, isRequesting) => isRequesting ? STATUS_LOADING : error ? STATUS_ERROR : null);
export function createUserGeolocationIsEnabledSelector(userGeolocationControllerName) {
    return createSelector([
        (state) => state[userGeolocationControllerName],
    ], (geoLoc) => geoLoc.isEnabled ?? false);
}
export function createUserGeolocationSelector(userGeolocationControllerName, { minimumAccuracy } = {}) {
    return createSelector([
        (state) => state[userGeolocationControllerName],
    ], (geoLoc) => {
        if (minimumAccuracy !== undefined && !geoLoc.error) {
            if (geoLoc.accuracy && geoLoc.accuracy >= minimumAccuracy) {
                return {
                    ...geoLoc,
                    error: ERROR_NOT_ACCURATE,
                };
            }
        }
        return geoLoc;
    });
}
export const createUserGeolocationAsFeatureSelector = (userGeolocationControllerName, { minimumAccuracy } = {}) => createSelector(createUserGeolocationSelector(userGeolocationControllerName, {
    minimumAccuracy,
}), (userGeolocation) => {
    if (userGeolocation.error) {
        return userGeolocation;
    }
    const id = userGeolocationControllerName + "Feature";
    const props = {
        id: id,
        ...userGeolocation,
    };
    return {
        data: {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [
                            userGeolocation.longitude,
                            userGeolocation.latitude,
                        ],
                    },
                    id: id,
                    properties: props,
                },
            ],
        },
    };
});
