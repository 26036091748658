import DoubleClickZoom from "ol/interaction/DoubleClickZoom";
import base from "./_base";
export default {
    type: "DoubleClickZoomInteraction",
    Constructor: DoubleClickZoom,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        duration: "duration",
        delta: "delta",
    },
};
