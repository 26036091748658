var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
function SwitcherStatusIcon(props) {
    const { baseClassName = "ms3-layer-switcher__status", // TODO: Use generic class name
    status, children, onClick, active } = props, rest = __rest(props, ["baseClassName", "status", "children", "onClick", "active"]);
    if (onClick) {
        return (_jsx("button", Object.assign({ type: "button", role: "checkbox", className: `${baseClassName} ${baseClassName}--${status}`, "aria-label": children, "aria-checked": active ? "true" : "false", onClick: onClick }, rest)));
    }
    else {
        return (_jsx("span", Object.assign({ className: `${baseClassName} ${baseClassName}--${status}`, "aria-label": children }, rest)));
    }
}
export default SwitcherStatusIcon;
