import { createFilteredFeatureSourceSelector } from "../../lib/feature-sources/selectors";
export const featureSourceIdSelector = (state) => state?.featureSource;
export function createListFeatureSelector(listControllerName, featureSourcesControllerName) {
    // default which is needed if there's no list source set at first call to this
    // then selector is undefined and keeps undefined as featureSourceId and
    // currentFeatureSourceId are undefined too und updateFeatureSelector does not get called
    let selector = (_) => undefined;
    let currentFeatureSourceId;
    function updateFeatureSelector() {
        selector = createFilteredFeatureSourceSelector(featureSourcesControllerName, currentFeatureSourceId, listControllerName);
    }
    return function listFeatureSelector(state) {
        const listState = state[listControllerName];
        const featureSourceId = featureSourceIdSelector(listState);
        if (featureSourceId && featureSourceId !== currentFeatureSourceId) {
            currentFeatureSourceId = featureSourceId;
            updateFeatureSelector();
        }
        return selector(state);
    };
}
