import getExtent from "../features/getExtentForFeatures";
import { DEFAULT_OPTIONS as SINGLE_FEATURE_DEFAULT_OPTIONS } from "./fitToFeature";
import fitToExtent from "./fitToExtent";
export const DEFAULT_OPTIONS = { ...SINGLE_FEATURE_DEFAULT_OPTIONS };
export default function fitToFeatures(map, features, options = DEFAULT_OPTIONS) {
    if (!features.length) {
        return;
    }
    fitToExtent(map, getExtent(features), options);
}
