import get from "lodash/get";
import set from "lodash/set";
import { observeState } from "./observe-state";
/**
 * Creates a storage object to access local storage.
 *
 * @param [storageKey=null] key to be used in local storage
 * @returns storage adapter
 */
export function createStorage(storageKey = null) {
    function setStorageKey(k) {
        storageKey = k;
    }
    function getLocalStorageState() {
        if (!storageKey) {
            throw Error("@neonaut/lib-redux: Cannot access local storage without a key. Please set a storage key using the `string: storageKey` property on `createStorage(string?: storageKey)` or using the `setStorageKey(string: storageKey)` method on an existing storage object.");
        }
        return (window.localStorage &&
            JSON.parse(window.localStorage.getItem(storageKey) || "null"));
    }
    function setLocalStorageState(state) {
        if (!storageKey) {
            throw Error("@neonaut/lib-redux: Cannot access local storage without a key. Please set a storage key using the `string: storageKey` property on `createStorage(string?: storageKey)` or using the `setStorageKey(string: storageKey)` method on an existing storage object.");
        }
        if (window.localStorage) {
            window.localStorage.setItem(storageKey, JSON.stringify(state));
        }
    }
    function updateLocalStorageState(path, value) {
        if (!storageKey) {
            throw Error("@neonaut/lib-redux: Cannot access local storage without a key. Please set a storage key using the `string: storageKey` property on `createStorage(string?: storageKey)` or using the `setStorageKey(string: storageKey)` method on an existing storage object.");
        }
        setLocalStorageState(set(getLocalStorageState(), path, value));
    }
    /**
     * Observes and synchronizes the state of the given store with local storage.
     *
     * @see lodash/get for path definition
     *
     * @param store the store to observe
     * @param paths array of path strings (string[]) or path arrays (string[][])
     */
    function synchronizePathsToLocalStorage(store, paths) {
        if (!storageKey) {
            throw Error("@neonaut/lib-redux: Cannot access local storage without a key. Please set a storage key using the `string: storageKey` property on `createStorage(string?: storageKey)` or using the `setStorageKey(string: storageKey)` method on an existing storage object.");
        }
        if (window.localStorage) {
            paths.forEach(function synchronizePathToLocalStorage(path) {
                observeState(store, (state) => 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                get(state, path), (value) => updateLocalStorageState(path, value));
            });
        }
    }
    return {
        setStorageKey: setStorageKey,
        getLocalStorageState: getLocalStorageState,
        setLocalStorageState: setLocalStorageState,
        updateLocalStorageState: updateLocalStorageState,
        synchronizePathsToLocalStorage: synchronizePathsToLocalStorage,
    };
}
