import forEach from "lodash/forEach";
import LayerGroup from "ol/layer/Group";
import VectorSource from "ol/source/Vector";
import { ensureNonNullable } from "@neonaut/lib-js/nonNullable";
import matchesPath from "@mapsight/lib-redux/matchesPath";
import reducers from "@mapsight/lib-redux/reducers/immutable-path";
import { di, updateProxyObject } from "../../../ol-proxy";
import { ACTION_SET } from "../../base/reducer";
import { FIT_MAP_VIEW_TO_LAYER_FEATURE, FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT, } from "../actions";
import { getGroupForLayer, tagLayer } from "./tagLayer";
import proxyPassOpenLayersEventsToMapController from "./proxyPassOpenLayersEventsToMapController";
import WithAnimations from "./WithAnimations";
export const LAYER_GROUP_DEFAULT = "default";
export default class WithLayers extends WithAnimations {
    _layers = {};
    _groups = {};
    getOrCreateLayerGroup(id) {
        const map = this.getMap();
        if (!map) {
            throw new Error("Could not get or create layer group. Map is not set.");
        }
        const groupLayer = this._groups[id]?.groupLayer;
        if (groupLayer !== undefined) {
            return groupLayer;
        }
        const groupLayer2 = new LayerGroup();
        tagLayer(groupLayer2, this, id);
        this._groups[id] = { id: id, groupLayer: groupLayer2, layers: {} };
        map.addLayer(groupLayer2);
        return groupLayer2;
    }
    init() {
        this._layers = {};
        this._groups = {};
        const updateLayer = (id, newDefinition, oldDefinitions) => {
            const oldDefinition = oldDefinitions[id];
            // update layer
            updateProxyObject({
                di: di,
                oldObject: this._layers[id],
                oldDefinition: oldDefinition,
                newDefinition: newDefinition,
                remover: (oldObject) => {
                    const group = getGroupForLayer(oldObject);
                    this._groups[group]?.groupLayer
                        .getLayers()
                        .remove(oldObject);
                    delete this._layers[id];
                    delete this._groups[group]?.layers[id];
                },
                adder: (layer) => {
                    const group = newDefinition.group || LAYER_GROUP_DEFAULT;
                    const layerGroup = this.getOrCreateLayerGroup(group);
                    this._layers[id] = layer;
                    ensureNonNullable(this._groups[group]).layers[id] = layer;
                    tagLayer(layer, this, id, group);
                    layerGroup.getLayers().push(layer);
                    proxyPassOpenLayersEventsToMapController(this, layer, newDefinition.type, id);
                },
                parentObject: this,
            });
        };
        this.getAndObserveUncontrolled((state) => state.layers, function updateLayers(newDefinitions = {}, oldDefinitions = {}) {
            forEach(oldDefinitions, (_, id) => updateLayer(id, newDefinitions[id], oldDefinitions));
            forEach(newDefinitions, (newDefinition, id) => updateLayer(id, newDefinition, oldDefinitions));
        });
        this.registerReducer((state, action) => {
            // layer based animation
            if (action.type === FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT) {
                const [_matches, { layerId }] = matchesPath(action.path, "layers/:layerId");
                if (layerId !== undefined && this._layers[layerId]) {
                    const source = this._layers[layerId]?.getSource();
                    if (source instanceof VectorSource) {
                        const sourceExtent = source.getExtent();
                        if (sourceExtent) {
                            this.fit(sourceExtent, action.options);
                        }
                    }
                }
            }
            if (action.type === FIT_MAP_VIEW_TO_LAYER_FEATURE) {
                const [_matches, { layerId }] = matchesPath(action.path, "layers/:layerId");
                if (layerId !== undefined && this._layers[layerId]) {
                    const source = this._layers[layerId]?.getSource();
                    if (source instanceof VectorSource) {
                        const feature = source.getFeatureById(action.featureId);
                        if (feature) {
                            this.fitMapViewToFeature(feature, action.options);
                        }
                    }
                }
            }
            // enforce only one base layer being visible at once
            if (action.type === ACTION_SET && action.value === true) {
                const [_matches, { layerId }] = matchesPath(action.path, "layers/:layerId/options/visible");
                if (layerId !== undefined &&
                    state.layers[layerId].metaData.isBaseLayer) {
                    // go through all base layers and set invisible
                    for (const id of Object.keys(state.layers)) {
                        if (state.layers[id].metaData?.isBaseLayer) {
                            state = reducers.set?.(state, {
                                type: "set",
                                value: false,
                                path: ["layers", id, "options", "visible"],
                            });
                        }
                    }
                }
            }
            return state;
        });
    }
}
