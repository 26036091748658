/**
 * set value for single key in query string
 * @param{String} uri 	uri to parse
 * @param{String} key   key to update or set
 * @param{String} value new value to set. it will get transformed with encodeURI()
 * @returns{String} 	uri with key and new value
 */

module.exports = function updateQueryStringParameter(uri, key, value) {
	value = encodeURI(value);
	var re = new RegExp('([?&])'+key+'=.*?(&|$)', 'i');
	var separator = uri.indexOf('?') !== -1 ? '&' : '?';
	return uri.match(re) ? uri.replace(re, '$1'+key+'='+value+'$2') : uri + separator + key + '=' +value;
};
