// TODO: https://www.npmjs.com/package/i18next ?
import de from "./de";
import en from "./en";
const DEFAULT_LANGUAGE = "de";
let documentLanguage = (typeof document !== "undefined" &&
    document.documentElement &&
    document.documentElement.lang) ||
    DEFAULT_LANGUAGE;
const DICTIONARY = { de, en };
// use this to make dictionary expandable/overwritable by clients
export function getDictionary() {
    return DICTIONARY;
}
export function setDocumentLanguage(value) {
    documentLanguage = value;
}
export function getDocumentLanguage() {
    return documentLanguage;
}
/**
 * @param key key
 * @param language language
 * @returns translation
 */
export function translate(key, language = documentLanguage) {
    var _a, _b, _c, _d;
    return ((_d = (_b = (_a = DICTIONARY[language]) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : (_c = DICTIONARY[DEFAULT_LANGUAGE]) === null || _c === void 0 ? void 0 : _c[key]) !== null && _d !== void 0 ? _d : key);
}
