/**
 * get value of single query string parmeter
 * @param{String} uri 	uri to parse
 * @param{String} key   key to return value for
 * @returns {Array|{index: number, input: string}|*}
 */
module.exports = function (uri, key) {
	var match = new RegExp('(?:^|[?&])' + key + '=(.*?)(?:&|$)', 'i').exec(uri);
	return match && decodeURI(match[1]);
};
