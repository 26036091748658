import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-no-bind */
import React, { memo, useMemo } from "react";
import { getDocumentLanguage } from "../../helpers/i18n";
import GroupedSwitcher from "../switcher/GroupedSwitcher";
import createTagSwitcherEntry from "./createTagSwitcherEntry";
import createTagSwitcherHeader from "./createTagSwitcherHeader";
function TagSwitcher({ groupedTagData, featureSourceId, toggleableGroups = false, sortTags = true, }) {
    function renderEntry(id, group) {
        const E = createTagSwitcherEntry(featureSourceId, group, id);
        return _jsx(E, { count: groupedTagData[group].tags[id] }, id);
    }
    function renderHeader(group, props) {
        const H = createTagSwitcherHeader(featureSourceId, group);
        return (_jsx(H, Object.assign({}, props, { count: groupedTagData[group].count, toggleable: toggleableGroups })));
    }
    const locale = getDocumentLanguage();
    const groupedIds = useMemo(() => {
        // Object.fromEntries needs babel runtime core3
        const groupedIdsTmp = {};
        Object.keys(groupedTagData).forEach((group) => {
            groupedIdsTmp[group] = Object.keys(groupedTagData[group].tags);
            if (sortTags) {
                groupedIdsTmp[group].sort((a, b) => a.localeCompare(b, locale, { numeric: true }));
            }
        });
        return groupedIdsTmp;
    }, [groupedTagData, sortTags, locale]);
    return (_jsx(GroupedSwitcher, { className: "ms3-tag-switcher", groupedIds: groupedIds, renderEntry: renderEntry, renderHeader: renderHeader }));
}
export default memo(TagSwitcher);
