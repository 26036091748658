import { BaseController } from "../../lib/base/controller";
import { addFilterFunction } from "../../lib/filter/selectors";
export class FilterController extends BaseController {
    constructor(controllerName, filterFunction) {
        super(controllerName);
        this.setFilterFunction(filterFunction);
    }
    setFilterFunction(filterFunction) {
        addFilterFunction(this.getName(), filterFunction);
    }
}
