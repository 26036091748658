function filter({ features = [], max }) {
    if (max) {
        features = features.slice(0, max);
    }
    return features;
}
export function getFilteredFeatures(featureSelection) {
    return featureSelection && filter(featureSelection);
}
export const createFeatureSelectionSelector = (controllerName, selectionId) => (state) => {
    const selections = state[controllerName];
    return selections?.[selectionId];
};
export const createAllFeatureIdsSelector = (controllerName, selectionId) => (state) => {
    const selections = state[controllerName];
    return selections?.[selectionId]?.features ?? [];
};
