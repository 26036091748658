import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import useMapsightPanel from "../../hooks/useMapsightPanel";
import { useMainPanelContext } from "./context";
function MainPanelContainer({ children }) {
    const { panelPosition, collapsed } = useMainPanelContext();
    const containerRef = useRef(null);
    useMapsightPanel(containerRef, panelPosition, collapsed);
    const containerClasses = `ms3-panel-container ms3-panel-container--docked-${panelPosition} ${collapsed ? "ms3-panel-container--empty" : ""}`;
    return (_jsx("div", { className: containerClasses, ref: containerRef, children: children }));
}
export default MainPanelContainer;
