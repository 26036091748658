import { set } from "@mapsight/core/lib/base/actions";
import { getGeolocation } from "@mapsight/core/lib/user-geolocation/actions";
import { createUserGeolocationAsFeatureSelector, createUserGeolocationIsEnabledSelector, geolocationStatusSelector, } from "@mapsight/core/lib/user-geolocation/selectors";
import { createStorage } from "@mapsight/lib-redux/local-storage";
import { AbortObserving, getAndObserveState, } from "@mapsight/lib-redux/observe-state";
import { createSelector, createStructuredSelector } from "reselect";
import * as c from "../../config/constants/controllers";
const defaultFeatureSourceId = "userGeolocation";
const defaultFeatureSourcesControllerName = c.FEATURE_SOURCES;
const defaultUserGeolocationControllerName = c.USER_GEOLOCATION;
/**
 * This plugin will bind the use geolocation feature source to the use geolocation controller
 *
 * @param {object} [options] options
 * @param {string} [options.featureSourceId="userGeolocation"] name of the feature source
 * @param {string} [options.featureSourcesControllerName] name of the feature sources controller, defaults to mapsight ui default
 * @param {string} [options.userGeolocationControllerName] name of the user geolocation controller, defaults to mapsight ui default
 * @param {boolean} [options.storeInLocalStorage] Syncs enabled state with localStorage if true
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { featureSourceId = defaultFeatureSourceId, featureSourcesControllerName = defaultFeatureSourcesControllerName, userGeolocationControllerName = defaultUserGeolocationControllerName, storeInLocalStorage = false, } = options;
    const featureSelector = createUserGeolocationAsFeatureSelector(userGeolocationControllerName);
    if (typeof window === "undefined") {
        console.error("This plugin will only work as intended in the browser!");
    }
    const localStorage = createStorage("neonaut.de/ms3-ui/user-geolocation");
    return {
        afterCreate: function searchPlugin(context) {
            context.controllers[featureSourcesControllerName].bindFeatureSourceToStore(featureSourceId, featureSelector);
        },
        afterRender: function ({ store }) {
            if (!storeInLocalStorage) {
                return;
            }
            const lSState = localStorage.getLocalStorageState();
            if (lSState !== null &&
                typeof lSState === "object" &&
                userGeolocationControllerName in lSState &&
                lSState[userGeolocationControllerName] !== null &&
                typeof lSState[userGeolocationControllerName] === "object" &&
                typeof lSState[userGeolocationControllerName].isEnabled ===
                    "boolean") {
                store.dispatch(set([userGeolocationControllerName, "isEnabled"], lSState[userGeolocationControllerName].isEnabled));
            }
            localStorage.synchronizePathsToLocalStorage(store, [
                [userGeolocationControllerName, "isEnabled"],
            ]);
            const geoLocIsEnabledSelector = createUserGeolocationIsEnabledSelector(userGeolocationControllerName);
            const geoLocStatusSelector = createSelector((state) => state[userGeolocationControllerName], geolocationStatusSelector);
            getAndObserveState(store, createStructuredSelector({
                isEnabled: geoLocIsEnabledSelector,
                alreadyRequested: createSelector(geoLocStatusSelector, (status) => status === "loading" || status === "error"),
            }), ({ isEnabled, alreadyRequested }) => {
                if (alreadyRequested) {
                    return AbortObserving;
                }
                if (isEnabled) {
                    store.dispatch(getGeolocation());
                    return AbortObserving;
                }
                return undefined;
            });
        },
    };
}
