import { getDistance } from "ol/sphere";
/**
 * @param feature feature object
 * @returns anchor
 */
function defaultFeatureAnchorSelector(feature) {
    if (!feature) {
        return [null, null];
    }
    // TODO: document/collect magic property names
    const bbox = feature.bbox;
    const x = bbox && bbox[0] && bbox[2] && (bbox[0] + bbox[2]) / 2;
    const y = bbox && bbox[1] && bbox[3] && (bbox[1] + bbox[3]) / 2;
    return [x, y];
}
/**
 * @param sorting sorting state
 * @param places places
 * @returns place to sort by or null
 */
function findPlaceForSorting(sorting, places) {
    const sortingKeys = sorting.split(",");
    let placePointer = places;
    for (const key of sortingKeys) {
        if (!placePointer[key]) {
            return null;
        }
        placePointer = placePointer[key];
        if (Object.prototype.hasOwnProperty.call(placePointer, "entries") &&
            "entries" in placePointer) {
            // NOTE(PG): 'entries' is reserved by JS (Object.entries). should be changed to something else at some point.
            //            We are using hasOwnProperty() as a workaround for now.
            // @ts-expect-error
            placePointer = placePointer.entries;
        }
    }
    if ("x" in placePointer && "y" in placePointer) {
        return placePointer;
    }
    return null;
}
/**
 * sorts features by distance
 *
 * @param features features
 * @param places places
 * @param sorting sorting state
 * @param settings settings
 * @returns sorted features
 */
export default function sortFeaturesByDistance(features, places, sorting, { featureAnchorSelector = defaultFeatureAnchorSelector, } = {}) {
    if (!sorting) {
        return features;
    }
    const placePointer = findPlaceForSorting(sorting, places);
    if (placePointer) {
        const placeCoords = [placePointer.x, placePointer.y];
        return features
            .slice()
            .sort((a, b) => getDistance(placeCoords, featureAnchorSelector(a)) -
            getDistance(placeCoords, featureAnchorSelector(b)));
    }
    return features;
}
