import forEach from "lodash/forEach";
import { di, updateProxyObject } from "../../../ol-proxy";
import proxyPassOpenLayersEventsToMapController from "./proxyPassOpenLayersEventsToMapController";
import WithMap from "./WithMap";
export default class WithControls extends WithMap {
    init() {
        const map = this.getMap();
        if (!map) {
            console.error("Could not initialize WithControls mixin. Map is not defined.");
            return;
        }
        const controls = {};
        this.getAndObserveUncontrolled((state) => state.controls, (newDefinitions = {}, oldDefinitions = {}) => {
            forEach(newDefinitions, (newDefinition, id) => updateProxyObject({
                di: di,
                oldObject: controls[id],
                oldDefinition: oldDefinitions && oldDefinitions[id],
                newDefinition: newDefinition,
                remover: () => {
                    const control = controls[id];
                    if (control !== undefined) {
                        map.removeControl(control);
                    }
                    delete controls[id];
                },
                adder: (object) => {
                    controls[id] = object;
                    map.addControl(object);
                    proxyPassOpenLayersEventsToMapController(this, object, newDefinition.type, id);
                },
                parentObject: this,
            }));
        });
    }
}
