import fetch from "cross-fetch";
const locationBaseUrl = (() => {
    if (typeof window === "undefined" || !window.location) {
        return undefined;
    }
    return window.location.href;
})();
export function load(state) {
    if (!state.url) {
        return Promise.resolve().then(() => Promise.reject("url missing"));
    }
    const url = new URL(state.url, (typeof global !== "undefined" &&
        "baseUrl" in global &&
        typeof global.baseUrl === "string" &&
        global.baseUrl) ||
        locationBaseUrl ||
        "http://localhost/");
    return fetch(url.href, { redirect: "follow" }).then((response) => {
        if (response.status !== 200) {
            return Promise.reject(response.statusText);
        }
        return response.json();
    });
}
