var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { layerIdsIntegratedSwitcherSelector, makeFeatureSourceFromLayerIdSelector, makeFeatureSourceIdFromLayerIdSelector, makeLayerLockedInLayerSwitcherSelector, makeLayerTitleSelector, makeLayerVisibleSelector, } from "@mapsight/core/lib/map/selectors";
import React, { memo, useCallback } from "react";
import { translate } from "../../helpers/i18n";
import GroupedLayerSwitcher from "./GroupedLayerSwitcher";
import LayerSwitcher from "./LayerSwitcher";
import LayerSwitcherEntry from "./LayerSwitcherEntry";
// TODO das berechnen der LayerListen (abhängig von grouped und layerIdSelector) in einen Selector packen,
//  damit diese Berechnung nur bei Änderungen am store berechnet neu wird
// TODO für das visible eine eigenes connect, damit der Tree nicht dauernd neu berechnet wird
function LayerSwitcherContainer(props) {
    const { as: T = "div", baseClassName = "ms3-layer-switcher-container", // TODO: Use generic class name
    onClose, layerIdsSelector = layerIdsIntegratedSwitcherSelector, setFeatureSourceIdPath, grouped = false } = props, attributes = __rest(props, ["as", "baseClassName", "onClose", "layerIdsSelector", "setFeatureSourceIdPath", "grouped"]);
    const renderLayerEntry = useCallback((id) => (
    // TODO: memo comp instance & selectors
    _jsx(LayerSwitcherEntry, { layerId: id, titleSelector: makeLayerTitleSelector(id), lockedSelector: makeLayerLockedInLayerSwitcherSelector(id), layerVisibilitySelector: makeLayerVisibleSelector(id), featureSourceSelector: makeFeatureSourceFromLayerIdSelector(id), featureSourceIdSelector: makeFeatureSourceIdFromLayerIdSelector(id), setFeatureSourceIdPath: setFeatureSourceIdPath }, id)), [setFeatureSourceIdPath]);
    const Switcher = grouped ? GroupedLayerSwitcher : LayerSwitcher;
    return (_jsxs(T, { className: `${baseClassName} ${baseClassName}--${grouped ? "grouped" : "ungrouped"}`, children: [_jsx(Switcher, Object.assign({ layerIdsSelector: layerIdsSelector, renderEntry: renderLayerEntry }, attributes)), onClose && (_jsx("button", { className: "ms3-layer-switcher__close-button [ ms3-dialog-close-button ]", type: "button", onClick: onClose, children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.map-overlay.layer-switcher.closeLayers") }) }))] }));
}
export default memo(LayerSwitcherContainer);
