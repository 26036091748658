import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from "react";
import FocusTrap from "focus-trap-react";
import { useSelector } from "react-redux";
import { translate } from "../../helpers/i18n";
import { isViewMobileOrMapOnlySelector } from "../../store/selectors";
import LayerSwitcher from "../layer-switcher";
import Modal from "../modal";
const LayerSwitcherContent = memo(function LayerSwitcherContent({ isExpanded, closeSearch, }) {
    const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);
    if (isMobileOrMapOnly) {
        return (_jsx(Modal, { isOpen: isExpanded, contentLabel: translate("ui.map-overlay.layer-switcher.modal.label"), closeLabel: translate("ui.map-overlay.layer-switcher.closeLayers"), headline: translate("ui.map-overlay.layer-switcher.layers"), onRequestClose: closeSearch, children: _jsx(LayerSwitcher
            // @ts-expect-error
            , { 
                // @ts-expect-error
                onClose: closeSearch }) }));
    }
    if (isExpanded) {
        return (_jsx(FocusTrap, { focusTrapOptions: {
                clickOutsideDeactivates: true,
                onDeactivate: closeSearch,
            }, children: _jsx("div", { children: _jsx(LayerSwitcher
                // @ts-expect-error
                , { 
                    // @ts-expect-error
                    onClose: closeSearch }) }) }));
    }
    return _jsx("div", { className: "ms3-layer-switcher-placeholder" });
});
function LayerSwitcherOverlay() {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleOpen = useCallback(() => setIsExpanded(true), []);
    const handleClose = useCallback(() => setIsExpanded(false), []);
    return (_jsxs("div", { className: `ms3-layer-switcher-overlay ${isExpanded ? "ms3-layer-switcher-overlay--expanded" : ""}`, children: [_jsx("button", { type: "button", className: "ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--layers", onClick: handleOpen, "aria-expanded": isExpanded, children: _jsxs("span", { className: "ms3-map-overlay__button__label", children: [_jsx("span", { "aria-hidden": "true", children: translate("ui.map-overlay.layer-switcher.layers") }), _jsxs("span", { className: "ms3-visuallyhidden", children: [isExpanded &&
                                    translate("ui.map-overlay.layer-switcher.closeLayers"), !isExpanded &&
                                    translate("ui.map-overlay.layer-switcher.openLayers")] })] }) }), _jsx(LayerSwitcherContent, { isExpanded: isExpanded, closeSearch: handleClose })] }));
}
export default memo(LayerSwitcherOverlay);
