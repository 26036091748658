import * as olArray from "ol/array";
import * as nonNull from "@neonaut/lib-js/nonNullable";
import getVisibleLayersFromFramestate from "@mapsight/lib-ol/map/getVisibleLayersFromFramestate";
import { async, controlled, set } from "../../../lib/base/actions";
import { getIdForLayer } from "./tagLayer";
import throttleDispatch from "./throttleDispatch";
import WithMap from "./WithMap";
export default class WithVisibleLayers extends WithMap {
    init() {
        const map = this.getMap();
        if (!map) {
            console.error("Could not initialize WithVisibleLayers mixin: map is not defined");
            return;
        }
        let visibleLayers = [];
        const updateVisibleLayers = throttleDispatch((frameState) => {
            const newVisibleLayers = getVisibleLayersFromFramestate(frameState)
                .map(getIdForLayer)
                .filter(nonNull.is);
            if (!olArray.equals(newVisibleLayers, visibleLayers)) {
                visibleLayers = newVisibleLayers;
                this.dispatch(async(controlled(set([this.getName(), "visibleLayers"], visibleLayers))));
            }
        });
        map.on("postrender", (event) => {
            const { frameState } = event;
            if (frameState) {
                updateVisibleLayers(frameState);
            }
        });
    }
}
