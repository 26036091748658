import { setAppTitle } from "../../store/actions";
const defaultRendererPropName = "title";
/**
 * This plugin will apply a title on render passed as a prop to the renderer.
 *
 * @param {object} [options] options currently not used
 * @param {string} [options.rendererPropName="title"] title property name passed to the renderer
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { rendererPropName = defaultRendererPropName } = options;
    return {
        beforeRender: function appTitleRenderPlugin(context) {
            const { store, rendererProps } = context;
            const title = rendererProps[rendererPropName];
            if (title !== undefined) {
                store.dispatch(setAppTitle(title));
            }
        },
    };
}
