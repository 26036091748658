import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { isViewMobileOrMapOnlySelector } from "../../store/selectors";
import Tooltip from "./tooltip";
function MapSyncedInterlay({ size }) {
    const isViewMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);
    const [width = "100%", height = "100%"] = size || [];
    return (_jsx("div", { className: "ms3-map-synced-interlay", style: { width: width, height: height }, children: !isViewMobileOrMapOnly && _jsx(Tooltip, {}) }));
}
export default memo(MapSyncedInterlay);
