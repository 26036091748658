import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { titleSelector } from "../../store/selectors";
import { makeReplaceableComponent } from "../../helpers/components";
function TitleBar() {
    const title = useSelector(titleSelector);
    return (_jsx("div", { className: "ms3-flex ms3-flex--row ms3-flex-no-shrink", children: _jsx("div", { className: "ms3-page-title ms3-flex-grow", children: _jsx("h2", { children: title || "" }) }) }));
}
export default makeReplaceableComponent("TitleBar", TitleBar);
export { TitleBar as NonReplaceableTitleBar };
