import { createSelector, createStructuredSelector } from "reselect";
import { applyFilters } from "../../lib/filter/selectors";
export const STATUS_OK = "ok";
export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";
export const ERROR = "error";
export const ERROR_PENDING = "errorPending";
export const ERROR_COLD_CACHE = "errorColdCache";
export const getSourceFilters = (source) => source.filters ?? [];
export const getAllFeatures = (source) => source.data?.features ?? [];
export const getSourceData = (source) => source.data || {};
export const getSourceDataHistory = (source) => source.dataHistory || { past: [], future: [] };
export const canUndo = (source) => !!(source.dataHistory?.past && source.dataHistory.past.length > 0);
export const canRedo = (source) => !!(source.dataHistory?.future && source.dataHistory.future.length > 0);
export const getFeatureSourceStatus = (featureSource) => {
    if (featureSource) {
        return featureSource.error
            ? STATUS_ERROR
            : featureSource.isLoading
                ? STATUS_LOADING
                : STATUS_OK;
    }
    return null;
};
export const findFeatureSourceForFeatureId = (sources, featureId) => Object.values(sources).find((source) => 
// TODO: replace the full scan with something smarter (eg bisecting a sorted list)
source.ids?.includes(featureId));
export const findFeatureInFeatureSourcesById = (sources, featureId) => {
    if (!featureId) {
        return null;
    }
    const source = findFeatureSourceForFeatureId(sources, featureId);
    if (!source) {
        return null;
    }
    const sourceFeatures = getAllFeatures(source);
    if (!sourceFeatures || !sourceFeatures.length) {
        return null;
    }
    // TODO: remove full-scans, store entries in an entry-by-id-map
    // * https://web.archive.org/web/20230607012422/https://redux.js.org/faq/performance#performance
    // * https://web.archive.org/web/20230713130911/https://redux.js.org/tutorials/fundamentals/part-5-ui-react#selecting-data-in-list-items-by-id
    return sourceFeatures.find((feature) => feature.id === featureId) ?? null;
};
export const getRefreshPaused = (source) => !!source.refreshPaused;
export const getFeatureSourceError = (featureSource) => featureSource.error;
function createUnfilteredFeatureSourceSelector(featureSourcesControllerName, featureSourceId) {
    return (state) => state[featureSourcesControllerName]?.[featureSourceId];
}
function createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId) {
    return (state) => state[featureSourcesControllerName]?.[featureSourceId]?.data?.features || [];
}
export function createFilteredFeatureSourceSelector(featureSourcesControllerName, featureSourceId, targetControllerName) {
    const featureSourceSelector = createUnfilteredFeatureSourceSelector(featureSourcesControllerName, featureSourceId);
    let filtersSelector = () => ({});
    // internal state holding
    const cache = {};
    return function (state) {
        let hasChanged = false;
        const source = featureSourceSelector(state);
        if (source !== cache.source) {
            cache.source = source;
            hasChanged = true;
            const filterNames = (() => {
                if (targetControllerName) {
                    const targetState = state[targetControllerName];
                    if (targetState &&
                        "overrideFeatureSourceFilters" in targetState) {
                        return targetState.overrideFeatureSourceFilters;
                    }
                }
                return source !== undefined
                    ? getSourceFilters(source)
                    : undefined;
            })();
            if (filterNames !== cache.filterNames) {
                cache.filterNames = filterNames;
                const filterSelectors = {};
                if (filterNames) {
                    filterNames.forEach((filter) => {
                        filterSelectors[filter] = (filterState) => filterState[filter];
                    });
                }
                filtersSelector = createStructuredSelector(filterSelectors);
            }
        }
        const filters = filtersSelector(state);
        if (filters !== cache.filters) {
            cache.filters = filters;
            hasChanged = true;
        }
        if (hasChanged) {
            cache.state = source && {
                ...source,
                data: filters && source.data?.features
                    ? {
                        ...source.data,
                        // FIXME: Instead of adding type=FeatureCollection here,
                        //    we should add it when reducing the feature sources or something like that.
                        //    This only adds it for filtered sources, but not for unfiltered ones.
                        type: "FeatureCollection",
                        features: applyFilters(filters, source.data.features, featureSourceId),
                    }
                    : source.data,
            };
        }
        return cache.state;
    };
}
/**
 * Returns grouped tags with count
 *
 * @param features features
 * @returns grouped tags with count
 */
export function getGroupedTagsWithCountFromFeatures(features) {
    const result = {};
    if (features) {
        features.forEach((entry) => {
            const featureTagGroups = entry.properties?.tagGroups;
            if (!featureTagGroups) {
                return;
            }
            Object.entries(featureTagGroups).forEach(([name, group]) => {
                const tagsForGroup = group.tags;
                const tagGroupWithCount = result[name] ?? {
                    count: 0,
                    tags: {},
                };
                result[name] = tagGroupWithCount;
                if (tagsForGroup && tagsForGroup.length) {
                    tagGroupWithCount.count += 1;
                    tagsForGroup.forEach((tag) => {
                        tagGroupWithCount.tags[tag] =
                            tagGroupWithCount.tags[tag] ?? 0;
                        tagGroupWithCount.tags[tag] += 1;
                    });
                }
            });
        });
    }
    return result;
}
export function createTagsWithCountFromFeatureSourceSelector(featureSourcesControllerName, featureSourceId) {
    const featureSourceSelector = createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId);
    return createSelector([featureSourceSelector], getGroupedTagsWithCountFromFeatures);
}
export const mapFeaturesToFeatureSource = (features) => ({
    data: {
        type: "FeatureCollection",
        features: features,
    },
});
/**
 * Checks if the feature source state warrants an update
 *
 * @param {object} state state to check
 * @returns {boolean} true if update is due, false otherwise
 */
export function getIsDue(state) {
    // always okay if we do not refresh, but load if we have not loaded yet
    if (state.doRefresh !== true || !state.timer || state.timer < 1) {
        return !state.lastUpdate;
    }
    // otherwise check timer
    const now = +new Date();
    return now - (state.lastUpdate || 0) >= state.timer;
}
