import { createSelector } from "reselect";
import * as nonNull from "@neonaut/lib-js/nonNullable";
import unique from "lodash/uniq";
import reduceByKeys from "@mapsight/lib-redux/reducers/reduce-by-keys";
/**
 * select all layers from state
 */
export const layersSelector = (state) => state.layers;
export const makeLayerTitleSelector = (id) => (state) => state.layers[id]?.metaData?.title;
export const makeLayerLockedInLayerSwitcherSelector = (id) => (state) => state.layers[id]?.metaData?.lockedInLayerSwitcher;
export const makeLayerSelectionSelector = (id, interactionName) => (state) => state.layers[id]?.options?.selections?.[interactionName];
export const layerIdsIntegratedSwitcherSelector = createSelector((state) => state.layers
    ? Object.keys(state.layers).filter((id) => state.layers[id]?.metaData?.visibleInLayerSwitcher)
    : [], (_) => _);
export const layerIdsExternalSwitcherSelector = createSelector((state) => state.layers
    ? Object.keys(state.layers).filter((id) => state.layers[id]?.metaData
        ?.visibleInExternalLayerSwitcher)
    : [], (_) => _);
// createSelector only caches the very last one result, so we have to create a new selector per ID, see ""But there is a problem!", https://github.com/reduxjs/reselect#selectorstodoselectorsjs
export const makeLayerVisibleSelector = (id) => createSelector([(state) => state.layers[id]], (layer) => layer?.options?.visible ?? false);
export const makeFeatureSourceIdFromLayerIdSelector = (layerId) => createSelector([(state) => state.layers[layerId]], (layer) => layer?.options?.source?.options?.featureSourceId);
export const makeFeatureSourceControllerNameFromLayerIdSelector = (layerId) => createSelector([(state) => state.layers[layerId]], (layer) => layer?.options?.source?.options?.featureSourcesControllerName);
/**
 * create selector for featureState corresponding to the featureSource used by a layer
 *
 * @param layerId id of the layer for which the selector will be created
 * @returns selector with parameters state (mapsight selected already) and globalFeatureState (featureSources _not_ selected)
 */
export const makeFeatureSourceFromLayerIdSelector = (layerId) => createSelector([
    (_, state) => state,
    makeFeatureSourceControllerNameFromLayerIdSelector(layerId),
    makeFeatureSourceIdFromLayerIdSelector(layerId),
], (state, featureControllerName, featureSourceId) => featureControllerName &&
    featureSourceId &&
    state[featureControllerName]
    ? state[featureControllerName][featureSourceId]
    : null);
export const mapSizeSelector = (state) => state.size;
export const viewportAnchorSelector = (state) => state.viewportAnchor;
// die tatsächlich von OpenLayer gerenderten Layer. wenn ein Layer mit option.visible grundsätzlich aktiv ist, aber an der angezeigten Stelle nichts beiträgt, ist er hier nicht verzeichnet.
export const visibleLayersSelector = (state) => state.visibleLayers || [];
export const reduceLayersToAttributions = (layers) => {
    if (layers === undefined) {
        return {};
    }
    const entries = Object.keys(layers)
        .map((layerId) => nonNull.map(layers[layerId]?.metaData?.attribution, (attribution) => [layerId, attribution]))
        .filter(nonNull.is);
    return Object.fromEntries(entries);
};
export const layerAttributionsSelector = createSelector([layersSelector], reduceLayersToAttributions);
// TODO Dokumentation notwendig
export const getVisibleLayerAttributions = (visibleLayers, layerAttributions) => {
    const entries = unique(visibleLayers ?? [])
        .map((layerId) => nonNull.map(layerAttributions[layerId], (attribution) => [layerId, attribution]))
        .filter(nonNull.is);
    return Object.fromEntries(entries);
};
export const visibleLayerAttributionsSelector = createSelector([visibleLayersSelector, layerAttributionsSelector], getVisibleLayerAttributions);
export const reduceLayersToLegends = (layers = {}) => {
    const entries = Object.entries(layers)
        .map(([layerId, layer]) => nonNull.map(layer.metaData?.legend, (legend) => [layerId, legend]))
        .filter(nonNull.is);
    return Object.fromEntries(entries);
};
export const reduceToLayersWithLegends = (layers = {}) => {
    const entries = Object.entries(layers).filter(([_, layer]) => layer.metaData?.legend);
    return Object.fromEntries(entries);
};
export const reduceToLayersWithMiniLegends = (layers = {}) => {
    const entries = Object.entries(layers).filter(([_, layer]) => layer?.metaData?.miniLegend);
    return Object.fromEntries(entries);
};
export const layersWithLegendsSelector = createSelector(layersSelector, reduceToLayersWithLegends);
export const layersWithMiniLegendsSelector = createSelector(layersSelector, reduceToLayersWithMiniLegends);
export const visibleLayersWithLegendsSelector = createSelector([visibleLayersSelector, layersWithLegendsSelector], reduceByKeys);
export const visibleLayersWithMiniLegendsSelector = createSelector([visibleLayersSelector, layersWithMiniLegendsSelector], reduceByKeys);
