var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { siteConfig } from "../../config";
function MapsightIcon({ id }) {
    if (!id) {
        return null;
    }
    // TODO make these paths configurable
    const iconFileName = `${id}-plain.png`;
    const iconSrc1x = `${siteConfig.imagesUrl}mapsight-icons/${iconFileName}`;
    const iconSrcSet = `${siteConfig.imagesUrl}mapsight-icons/${iconFileName} 1x, ` +
        `${siteConfig.imagesUrl}mapsight-icons-2x/${iconFileName} 2x`;
    return _jsx("img", { src: iconSrc1x, srcSet: iconSrcSet, alt: "" });
}
function FeatureListIcon(_a) {
    var { selectable, mapsightIconId, as: T } = _a, attributes = __rest(_a, ["selectable", "mapsightIconId", "as"]);
    let className = `ms3-list__icon ms3-list__icon--id-${mapsightIconId || "-"}`;
    if (selectable) {
        className += " ms3-list__icon--selectable";
    }
    return (_jsx(T, Object.assign({ className: className, "aria-hidden": true }, attributes, { children: _jsx(MapsightIcon, { id: mapsightIconId }) })));
}
export default memo(FeatureListIcon);
