import { getFilteredFeatures } from "@mapsight/core/lib/feature-selections/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isViewMobile, viewSelector } from "../../../store/selectors";
import { useFeatureListContext } from "../../feature-list/context";
export default function useFeatureListItemState(feature) {
    const view = useSelector(viewSelector);
    const isMobile = isViewMobile(view);
    const { listUiOptions: { selectionBehavior, showSelectedOnly, detailsInList, selectOnClick, deselectOnClick, highlightOnMouse, }, state: { selectSelection, preselectSelection, highlightSelection }, } = useFeatureListContext();
    const selectSelectionFeatures = useMemo(() => getFilteredFeatures(selectSelection) || [], [selectSelection]);
    const hasSelection = useMemo(() => (selectSelectionFeatures === null || selectSelectionFeatures === void 0 ? void 0 : selectSelectionFeatures.length) > 0, [selectSelectionFeatures === null || selectSelectionFeatures === void 0 ? void 0 : selectSelectionFeatures.length]);
    const isSelected = useMemo(() => selectSelectionFeatures.includes(feature.id), [feature.id, selectSelectionFeatures]);
    const isPreselected = useMemo(() => { var _a; return (_a = getFilteredFeatures(preselectSelection)) === null || _a === void 0 ? void 0 : _a.includes(feature.id); }, [feature.id, preselectSelection]);
    const isHighlighted = useMemo(() => { var _a; return (_a = getFilteredFeatures(highlightSelection)) === null || _a === void 0 ? void 0 : _a.includes(feature.id); }, [feature.id, highlightSelection]);
    return useMemo(() => ({
        selectOnClick: selectOnClick,
        deselectOnClick: deselectOnClick,
        highlightOnMouse: highlightOnMouse,
        view: view,
        isMobile: isMobile,
        hidden: showSelectedOnly && hasSelection && !isSelected,
        hasSelection: hasSelection,
        isSelected: isSelected,
        isHighlighted: isHighlighted,
        isPreselected: isPreselected,
        showDetails: isSelected && (isMobile || detailsInList),
        scrollOnSelection: (selectionBehavior === null || selectionBehavior === void 0 ? void 0 : selectionBehavior[view]) === "expandInList" &&
            !showSelectedOnly,
    }), [
        deselectOnClick,
        detailsInList,
        hasSelection,
        highlightOnMouse,
        isHighlighted,
        isMobile,
        isPreselected,
        isSelected,
        selectOnClick,
        showSelectedOnly,
        selectionBehavior,
        view,
    ]);
}
