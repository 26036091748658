import { unreachableValue } from "@neonaut/lib-js/unreachable";
/**
 * @param name Name of block or element
 * @param mods modifiers
 * @returns class string
 */
export default function modClasses(name, mods) {
    const modsArr = (() => {
        // string to array
        if (typeof mods === "string") {
            return [mods];
        }
        // object to array
        if (mods !== null && typeof mods === "object" && !Array.isArray(mods)) {
            return Object.entries(mods)
                .filter(([_, val]) => val === true)
                .map(([key, _]) => key);
        }
        if (Array.isArray(mods)) {
            return mods.filter(isNotFalsy);
        }
        if (mods === null || mods === false || mods === undefined) {
            return [];
        }
        unreachableValue(mods);
    })();
    return [name, ...modsArr.map((mod) => `${name}--${mod}`)].join(" ");
}
function isNotFalsy(val) {
    return val !== false && val !== null && val !== undefined;
}
