import Map from "ol/Map";
import { OPTION_SET } from "..";
export default {
    type: "Map",
    Constructor: Map,
    optionMap: {
        pixelRatio: OPTION_SET,
        loadTilesWhileAnimating: OPTION_SET,
        loadTilesWhileInteracting: OPTION_SET,
        moveTolerance: OPTION_SET,
        renderer: OPTION_SET,
        size: "setSize",
        logo: "setLogo",
    },
};
