var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, memo } from "react";
import Switcher from "./Switcher";
function GroupedSwitcher(props) {
    const { as: T = "div", className = "ms3-grouped-switcher", renderEntry, renderHeader = undefined, ungroupedIds = [], groupedIds = {} } = props, attributes = __rest(props, ["as", "className", "renderEntry", "renderHeader", "ungroupedIds", "groupedIds"]);
    const children = Object.entries(groupedIds).map(([key, group]) => (_jsx(Switcher, { ids: group, group: key, as: Fragment, renderEntry: renderEntry, renderHeader: renderHeader }, key)));
    return (_jsxs(T, Object.assign({ className: className }, attributes, { children: [ungroupedIds.length !== 0 && (_jsx(Switcher, { ids: ungroupedIds, as: Fragment, renderEntry: renderEntry, renderHeader: renderHeader })), children] })));
}
export default memo(GroupedSwitcher);
