import merge from "lodash/merge";
import { create } from "../index";
import browserDomRenderer from "../renderer/browser-dom";
/**
 * Browser embed
 *
 * @param container the container element to render the app into
 * @param options
 * @returns render function
 */
export default function browserEmbed(container, options) {
    const { styleFunction, baseMapsightConfig = {}, createOptions = {}, } = options;
    const { dehydratedStateAttributeName = "data-dehydrated-state" } = createOptions;
    createOptions.renderer = createOptions.renderer || browserDomRenderer;
    if (!createOptions.reHydratedState &&
        dehydratedStateAttributeName &&
        container) {
        pullDeHydratedStateFromContainer(createOptions, dehydratedStateAttributeName, container);
    }
    createOptions.uiState = merge({}, createOptions.uiState || {});
    if (process.env.NN_JS_LOG_LEVEL === "verbose") {
        console.log("v-- mapsight embed container:", container, "createOptions", createOptions);
    }
    const { render } = create(container, styleFunction, baseMapsightConfig, createOptions);
    // initial render
    if (render !== undefined) {
        render({});
    }
    if (process.env.NN_JS_LOG_LEVEL === "verbose") {
        console.log("^-- mapsight embed after call to render");
    }
    return render;
}
function pullDeHydratedStateFromContainer(createOptions, dehydratedStateAttributeName, container) {
    const stateJson = container.getAttribute(dehydratedStateAttributeName);
    if (stateJson !== null) {
        try {
            createOptions.reHydratedState = JSON.parse(stateJson);
            container.setAttribute(dehydratedStateAttributeName, "");
        }
        catch (e) {
            console.error("mapsight ui: error reading dehydrated state from container", e);
        }
    }
    else {
        if (process.env.NN_JS_LOG_LEVEL === "verbose") {
            console.info("mapsight ui: could not read dehydrated state from container");
        }
    }
}
