import { getFeatureSourceError, getFeatureSourceStatus, } from "@mapsight/core/lib/feature-sources/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FEATURE_LIST } from "../../../config/constants/controllers";
import { featureListContextSelector } from "../../../store/selectors";
import useFeatureListFeatureSource from "./useFeatureListFeatureSource";
import usePaginatedFilteredFeatures from "./usePaginatedFilteredFeatures";
export default function useFeatureListState(listUiOptions, sort = true, filter = true, enableScrollPosition = true, listControllerName = FEATURE_LIST) {
    const { featureSourceId, featureSource } = useFeatureListFeatureSource(listControllerName);
    const { tagSwitcherShow, layerSwitcherShowExternal, scrollPosition, selectSelection, preselectSelection, highlightSelection, } = useSelector(featureListContextSelector);
    const paginatedFilteredFeaturesState = usePaginatedFilteredFeatures(featureSource, listUiOptions, sort, filter);
    const featureSourceState = useMemo(() => ({
        status: featureSource
            ? getFeatureSourceStatus(featureSource)
            : null,
        error: featureSource
            ? getFeatureSourceError(featureSource)
            : null,
    }), [featureSource]);
    return useMemo(() => (Object.assign(Object.assign(Object.assign({}, paginatedFilteredFeaturesState), featureSourceState), { tagSwitcherShow,
        layerSwitcherShowExternal,
        featureSourceId, scrollPosition: enableScrollPosition && scrollPosition, selectSelection,
        preselectSelection,
        highlightSelection })), [
        paginatedFilteredFeaturesState,
        featureSourceState,
        tagSwitcherShow,
        layerSwitcherShowExternal,
        featureSourceId,
        enableScrollPosition,
        scrollPosition,
        selectSelection,
        preselectSelection,
        highlightSelection,
    ]);
}
