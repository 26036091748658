function strictEqualCompare(a, b) {
    return a === b;
}
//const jsonCompare = (a, b)  => JSON.stringify(a) !== JSON.stringify(b);
export const AbortObserving = Symbol(); // TODO: Use Symbol()
function internalObserveState(store, selector, listener, compare = strictEqualCompare, initialValue = null) {
    let currentValue = initialValue;
    const unsubscribe = store.subscribe(function onStateChange() {
        const state = store.getState();
        const newValue = selector(state);
        if (!compare(currentValue, newValue)) {
            const oldValue = currentValue;
            currentValue = newValue;
            const returnValue = listener(newValue, oldValue, state);
            if (returnValue === AbortObserving) {
                unsubscribe();
            }
        }
    });
    return unsubscribe;
}
export function observeState(store, selector, listener, compare = strictEqualCompare) {
    const initialValue = selector(store.getState());
    return internalObserveState(store, selector, listener, compare, initialValue);
}
export function observeStateOnce(store, selector, listener, compare = strictEqualCompare) {
    const unsubscribe = observeState(store, selector, function handleChange(newValue, oldValue, state) {
        listener(newValue, oldValue, state);
        unsubscribe();
    }, compare);
    return unsubscribe;
}
export function getAndObserveState(store, selector, listener, compare = strictEqualCompare) {
    const initialState = store.getState();
    const initialValue = selector(initialState);
    const initialReturnValue = listener(initialValue, null, initialState);
    if (initialReturnValue === AbortObserving) {
        return () => undefined;
    }
    return internalObserveState(store, selector, listener, compare, initialValue);
}
