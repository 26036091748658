// See: https://github.com/jquery/jquery/blob/master/src/ajax/parseXML.js
// See: https://stackoverflow.com/questions/18538192/parse-xml-with-javascript-without-jquery
module.exports = function parseXML(data) {
	if (!data || typeof data !== 'string') {
		return null;
	}

	var xml;
	try {
		// IE <= 11 throws on parseFromString with invalid input.
		xml = (new window.DOMParser()).parseFromString(data, 'text/xml');
	} catch (e) {
		xml = undefined;
	}

	if (!xml || xml.getElementsByTagName('parsererror').length) {
		console.info('Invalid XML: '+data);
		return null;
	}

	return xml;
};
