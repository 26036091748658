import isEqual from "lodash/isEqual";
import { isDescription, } from "../lib/map/types";
import DependencyManager from "./DependencyManager";
export { isDescription } from "../lib/map/types";
// TODO: use symbols?
export const OPTION_SET = "__set__";
export const OPTION_COLLECTION = "__collection__";
export const INITIAL_OPTION_PASS = "__pass__";
export const OPTION_SKIP = "__skip__";
export const di = new DependencyManager();
// Untested idea to simplify injection:
//export function load(group, name) {
//	di.inject({[group]: {[name]: require(`./definitions/${group}/${name}`)}});
//}
// or: add a type-to-definition-mapping somewhere (using dynamic imports):
// async function importDefinition(type: string): Promise<Definition> {
// 	switch (type) {
// 		case "VectorTileLayer":
// 			return (await import("./definitions/layer/vectortile")).default;
// 		default:
// 			throw new Error();
// 	}
// }
// then:
// di.injectDefinitions([await importDefinition("blaa"))]);
/**
 * Updates a proxy openlayers object
 *
 * @private
 */
export function setOptions(object, oldOptions, newOptions, optionMap, parentObject = null) {
    const changedOptions = Object.entries(optionMap)
        .map(([name, updater]) => ({
        name,
        updater,
        newValue: newOptions[name],
        oldValue: oldOptions?.[name],
    }))
        .filter(({ newValue, oldValue }) => 
    // TODO: support option entry removal. Or at least throw an error if there is
    // an entry missing from the current options, but was present in the previous
    // options. Now we are silently keeping the old value.
    newValue !== null &&
        newValue !== undefined &&
        newValue !== oldValue);
    for (const { name, updater, newValue, oldValue } of changedOptions) {
        if (updater === OPTION_SET) {
            // Case: direct set
            if ("set" in object && typeof object.set === "function") {
                object.set(name, newValue);
            }
            else {
                console.error(`cannot set option using updater: ${updater} (OPTION_SET)`);
            }
        }
        else if (updater === OPTION_SKIP) {
            // Case: skip
            // do nothing
        }
        // Case: setter
        else if (typeof updater === "string") {
            const key = updater;
            if (key in object && typeof object[key] === "function") {
                object[key](newValue);
            }
            else {
                console.error(`cannot set option using updater: ${updater} (${typeof updater})`);
            }
        }
        // Case: callback
        else if (typeof updater === "function") {
            updater(object, name, oldValue, newValue, {
                oldOptions,
                newOptions,
                optionMap,
                parentObject,
            });
        }
        // Case: collection
        else if (Array.isArray(updater) && updater[0] === OPTION_COLLECTION) {
            const key = updater[1];
            const collectionGetter = object[key];
            if (typeof collectionGetter !== "function") {
                console.error(`cannot set option using updater: ${updater} (${typeof updater})`);
                continue;
            }
            const collection = collectionGetter.call(object);
            // todo: improve by patching instead of replacing
            collection.clear();
            collection.extend(newValue);
        }
        else {
            console.error(`missing support for updater: ${updater} (${typeof updater})`);
        }
    }
    if (changedOptions.length > 0 &&
        "changed" in object &&
        typeof object.changed === "function") {
        object.changed();
    }
}
/**
 * Maps initial options for a proxy openlayers object
 *
 * @private
 */
function processConstructorOptions(definition, options, parentObject) {
    if (definition.initialOptionMap === undefined) {
        return {};
    }
    const values = Object.entries(definition.initialOptionMap)
        .map(([name, preparer]) => ({
        name,
        preparer,
        value: options[name],
    }))
        .filter(({ value }) => value !== undefined && value !== null);
    const result = {};
    for (const { name, preparer, value } of values) {
        // Case: true or pass
        if (preparer === true || preparer === INITIAL_OPTION_PASS) {
            result[name] = value;
        }
        // Case: string or number
        else if (typeof preparer === "string" || typeof preparer === "number") {
            result[preparer] = value;
        }
        // Case: callback
        else if (typeof preparer === "function") {
            Object.assign(result, preparer({ value, name, parentObject, definition }));
        }
        else {
            console.error(`missing support for preparer: ${preparer} (${typeof preparer})`);
        }
    }
    return result;
}
/**
 * @param diInstance dependency manager
 * @param targetName target option name
 *
 * @returns dependency mapper
 */
export function createDependencyMapper(diInstance, targetName) {
    return ({ value, parentObject }) => {
        if (!isDescription(value)) {
            console.error(`dependency mapper could not get dependency: Invalid description.`);
            return {};
        }
        const { type, options } = value;
        const def = diInstance.getDefinition(type);
        if (!def) {
            console.error(`dependency mapper could not get dependency: ${type}.`);
            return {};
        }
        const constructorOptions = options !== undefined
            ? processConstructorOptions(def, options, parentObject)
            : {};
        return {
            [targetName]: DependencyManager.makeInstance(def, constructorOptions),
        };
    };
}
function createOrReplaceObject(definition, oldObject, args, remover = null, adder = null, parentObject) {
    if (oldObject && remover) {
        remover(oldObject, parentObject);
    }
    // create new object
    const object = DependencyManager.makeInstance(definition, ...args);
    if (object && adder) {
        adder(object, parentObject);
    }
    return object;
}
function hasSomeOptionChanged(optionMap, newOptions, oldOptions) {
    return Object.keys(optionMap).some((key) => {
        const hasChanged = !isEqual(newOptions[key], oldOptions[key]);
        if (hasChanged) {
            console.debug("ol-proxy: v UPDATE triggered by", key);
        }
        return hasChanged;
    });
}
function checkOptionsRequireNewCreation(definition, oldOptions, newOptions) {
    return (oldOptions !== newOptions &&
        definition.initialOptionMap !== undefined &&
        Object.keys(definition.initialOptionMap).some((key) => !Object.prototype.hasOwnProperty.call(definition.optionMap, key) && !isEqual(newOptions[key], oldOptions[key])));
}
function getLeftDistinctValues(leftObject, rightObject) {
    const leftValues = {};
    Object.entries(leftObject).forEach(([key, leftValue]) => {
        if (!Object.prototype.hasOwnProperty.call(rightObject, key) ||
            rightObject[key] === INITIAL_OPTION_PASS) {
            leftValues[key] = leftValue;
        }
    });
    return leftValues;
}
// TODO: add *object* type
export function updateProxyObject({ di: diInstance, oldObject: object, oldDefinition: oldDescription, newDefinition: newDescription, remover, adder, parentObject, }) {
    if (!newDescription) {
        //console.debug('ol-proxy: Removed object ', {group, oldObject, oldDefinition, parentObject});
        if (object && remover) {
            remover(object, parentObject);
        }
        return;
    }
    const type = newDescription.type || (oldDescription && oldDescription.type);
    if (!type) {
        console.error("ol-proxy: Cannot update proxy object. Definition is insufficient. Missing type.");
        return;
    }
    const definition = diInstance.getDefinition(type);
    if (!definition) {
        console.error(`ol-proxy: Cannot update proxy object. Dependency unknown/not injected: ${type}.`);
        return;
    }
    const oldOptions = oldDescription?.options;
    const newOptions = newDescription.options ?? {};
    let creationReason = null;
    if (!object) {
        creationReason = "new";
    }
    else if (!DependencyManager.checkObjectType(definition, object)) {
        creationReason = "type";
    }
    else if (oldOptions !== undefined &&
        checkOptionsRequireNewCreation(definition, oldOptions, newOptions)) {
        creationReason = "options";
    }
    if (creationReason !== null) {
        const obj = createOrReplaceObject(definition, object, [processConstructorOptions(definition, newOptions, parentObject)], remover, adder, parentObject);
        console.debug("ol-proxy: CREATE", creationReason === "new"
            ? "new"
            : creationReason === "options"
                ? "changed options required constr."
                : "type changed", type);
        if (obj && newOptions) {
            setOptions(obj, {}, getLeftDistinctValues(newOptions, definition.initialOptionMap ?? {}), definition.optionMap, parentObject);
        }
    }
    else if ((oldOptions === undefined ||
        (newOptions &&
            hasSomeOptionChanged(definition.optionMap, newOptions, oldOptions))) &&
        object) {
        console.debug("ol-proxy: UPDATE", type);
        setOptions(object, oldOptions, newOptions, definition.optionMap, parentObject);
    }
}
