var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setTagGroupVisible } from "../../store/actions";
import { createTagGroupVisibleSelector } from "../../store/selectors";
import SwitcherHeader from "../switcher/SwitcherHeader";
export default function createTagSwitcherHeader(featureSourceId, tagGroup) {
    return connect(createStructuredSelector({
        visibility: createTagGroupVisibleSelector(featureSourceId, tagGroup),
    }), null, ({ visibility }, { dispatch }, _a) => {
        var { toggleable = false } = _a, ownProps = __rest(_a, ["toggleable"]);
        return (Object.assign({ label: tagGroup, active: !toggleable || visibility, toggleActive: toggleable
                ? () => dispatch(setTagGroupVisible(featureSourceId, tagGroup, !visibility))
                : undefined }, ownProps));
    })(SwitcherHeader);
}
