import Point from "ol/geom/Point";
import OlFeature from "ol/Feature";
import defaultClusterFeaturePropertiesFunction from "./defaultClusterFeaturePropertiesFunction";
function createClusterFeatureId(coordinates, features) {
    return "cluster||" + features.map((feature) => feature.getId()).join("||");
}
/**
 * creates a createCluster* function that will create single point features for each cluster
 */
export default function createCreateClusterIntoSingleFeature(clusterFeaturePropertiesFunction = defaultClusterFeaturePropertiesFunction, options, cache, previousCache) {
    return function clusterIntoSingleFeature(clusterCenter, clusterFeatures) {
        const id = createClusterFeatureId(clusterCenter, clusterFeatures);
        const baseProperties = {
            id: id,
            geometry: new Point(clusterCenter),
            cluster: true,
            clusterSize: clusterFeatures.length,
            clusterFeatures: clusterFeatures,
        };
        const featureProperties = clusterFeaturePropertiesFunction(baseProperties, options);
        // reuse old feature and just update props or create new feature
        let clusterFeature;
        const cachedCluster = previousCache?.get(id);
        if (cachedCluster?.clusterFeature) {
            clusterFeature = cachedCluster.clusterFeature;
            clusterFeature.setProperties(featureProperties);
        }
        else {
            clusterFeature = new OlFeature(featureProperties);
            clusterFeature.setId(id);
        }
        if (cache) {
            cache.set(id, {
                clusterFeature: clusterFeature,
                features: clusterFeatures,
            });
        }
        return clusterFeature;
    };
}
