var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setTagVisible } from "../../store/actions";
import { createTagVisibleSelector } from "../../store/selectors";
import SwitcherEntry from "../switcher/SwitcherEntry";
export default function createTagSwitcherEntry(featureSourceId, tagGroup, tagName) {
    return connect(
    // mapStateToProps:
    createStructuredSelector({
        visibility: createTagVisibleSelector(featureSourceId, tagGroup, tagName),
    }), 
    // mapDispatchToProps:
    null, 
    // mergeProps:
    ({ visibility }, { dispatch }, _a) => {
        var ownProps = __rest(_a, []);
        return (Object.assign({ title: tagName, active: visibility, toggleActive: () => dispatch(setTagVisible(featureSourceId, tagGroup, tagName, !visibility)) }, ownProps));
    })(SwitcherEntry);
}
