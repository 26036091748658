import { EventEmitter } from "eventemitter3";
import { compose } from "redux";
import { baseReducer } from "../../lib/base/reducer";
export class BaseController extends EventEmitter {
    // TODO: Should we also implement: observe, getAndObserve and getAndSubscribe?
    #name;
    #store = null;
    #selector = null;
    #reducers;
    constructor(controllerName) {
        super();
        this.#name = controllerName;
        this.#reducers = [];
    }
    getName() {
        return this.#name;
    }
    getStore() {
        return this.#store;
    }
    getState() {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        if (!this.#selector) {
            throw new Error("Selector not set");
        }
        return this.#selector(store.getState());
    }
    dispatch(action) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        return store.dispatch(action);
    }
    /**
     * Observes the controller's state by the given selector,
     * calling the given handler if the selected state changes (by means of strict equality)
     * but ONLY if the action that changed the state was not controlled by the controller.
     *
     * @param selector observe selector
     * @param handler observe handler
     * @returns unsubscribe function
     */
    observeUncontrolled(selector, handler) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        const boundHandler = handler.bind(this);
        const composedSelector = this.#selector
            ? compose(selector, this.#selector)
            : selector;
        return store.observeUncontrolled(composedSelector, boundHandler);
    }
    /**
     * Observes the controller's state by the given selector,
     * calling the given handler if the selected state changes (by means of strict equality)
     * but ONLY if the action that changed the state was not controlled by the controller.
     *
     * Additionally, the handler is called with the initial state.
     *
     * @param selector observe selector
     * @param handler observe handler
     * @returns unsubscribe function
     */
    getAndObserveUncontrolled(selector, handler) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        const boundHandler = handler.bind(this);
        const composedSelector = (this.#selector ? compose(selector, this.#selector) : selector);
        const unsubscribe = store.observeUncontrolled(composedSelector, boundHandler);
        boundHandler(composedSelector(store.getState()));
        return unsubscribe;
    }
    /**
     * Observes the controller's state
     * calling the given handler if the selected state changes (by means of strict equality).
     *
     * @param {Function} handler subscribe handler
     * @returns {Function} unsubscribe function
     */
    subscribe(handler) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        return store.subscribe(handler.bind(this));
    }
    /**
     * Observes the controller's state
     * calling the given handler if the selected state changes (by means of strict equality)
     * but ONLY if the action that changed the state was not controlled by the controller.
     *
     * @param {Function} handler subscribe handler
     * @returns {Function} unsubscribe function
     */
    subscribeUncontrolled(handler) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        const selector = this.#selector;
        if (!selector) {
            throw new Error("Selector not set");
        }
        return store.observeUncontrolled(selector, handler.bind(this));
    }
    /**
     * Observes the controller's state
     * calling the given handler if the selected state changes (by means of strict equality)
     * but ONLY if the action that changed the state was not controlled by the controller.
     *
     * Additionally, the handler is called with the initial state.
     *
     * @param {Function} handler subscribe handler
     * @returns {Function} unsubscribe function
     */
    getAndSubscribeUncontrolled(handler) {
        const store = this.getStore();
        if (!store) {
            throw new Error("Controller not bound to store");
        }
        const selector = this.#selector;
        if (!selector) {
            throw new Error("Selector not set");
        }
        const boundHandler = handler.bind(this);
        const unsubscribe = store.observeUncontrolled(selector, boundHandler);
        boundHandler(this.getState());
        return unsubscribe;
    }
    /**
     * This method will be called when the controller is bound by createMapsightStore().
     *
     * @param store store to bind to
     */
    bindToStore(store) {
        if (this.#store) {
            console.error("Controller is already bound to a store. Rebinding is NOT supported!");
            return;
        }
        this.#store = store;
        this.#selector = (state) => state[this.getName()];
    }
    /**
     * This method will be called once all controllers are bound to the store.
     * Use this method to implement controller code that requires access to the store or other controllers!
     */
    init() {
        // empty
    }
    registerReducer(reducer) {
        this.#reducers.push(reducer);
    }
    /**
     * @param {object} state that part of the state in control of the controller
     * @param {object} action action to perform on state
     * @returns {object} modified part of the state in control of the controller after performing action
     */
    reduce(state = {}, action) {
        this.#reducers.forEach((reducer) => {
            state = reducer(state, action);
        });
        return baseReducer(state, action);
    }
}
