import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FocusTrap from "focus-trap-react";
import { removeFrom } from "@mapsight/core/lib/base/actions";
import { featureSelectionInfoStickyHeaderSelector } from "../../store/selectors";
import FeatureDetailsContent from "../feature-details-content";
import CloseButton from "./close-button";
import Header from "./header";
import WithStickyHeader from "./with-sticky-header";
import WithoutStickyHeader from "./without-sticky-header";
import Wrapper from "./wrapper";
import { FEATURE_SELECTIONS } from "../../config/constants/controllers";
import { FEATURE_SELECTION_SELECT } from "../../config/feature/selections";
export default function FeatureSelectionInfo({ feature, enableKeyboardControl, }) {
    const dispatch = useDispatch();
    const hasStickyHeader = useSelector(featureSelectionInfoStickyHeaderSelector);
    const renderWrapper = useCallback((attrs) => (_jsx(Wrapper, Object.assign({ feature: feature, enableKeyboardControl: enableKeyboardControl }, attrs))), [enableKeyboardControl, feature]);
    const removeSelection = useCallback(() => {
        dispatch(removeFrom([FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, "features"], feature === null || feature === void 0 ? void 0 : feature.id));
    }, [dispatch, feature === null || feature === void 0 ? void 0 : feature.id]);
    if (!feature) {
        return null;
    }
    const T = hasStickyHeader ? WithStickyHeader : WithoutStickyHeader;
    return (_jsx(FocusTrap, { active: enableKeyboardControl, focusTrapOptions: {
            clickOutsideDeactivates: true,
            onDeactivate: removeSelection,
        }, children: _jsx(T, { feature: feature, close: _jsx(CloseButton, { feature: feature, enableKeyboardControl: enableKeyboardControl }), header: _jsx(Header, { feature: feature }), content: _jsx("div", { className: "ms3-feature-selection-info__content", children: _jsx(FeatureDetailsContent, { feature: feature }) }), renderWrapper: renderWrapper }) }));
}
