import React, { useMemo } from "react";
import getFeatureProperty from "../../../helpers/get-feature-property";
import FeatureListItem from "../../feature-list-item";
/**
 * Determines feature groups
 *
 * @param features features
 * @returns groups
 */
function determineFeatureGroups(features) {
    const groups = [];
    const defaultGroup = createGroup("");
    groups[0] = defaultGroup;
    features.forEach((feature) => {
        const featureGroupName = getFeatureProperty(feature, "group");
        if (!featureGroupName) {
            defaultGroup.features.push(feature);
        }
        else {
            // find group and add feature to it
            let group = findGroup(groups, featureGroupName);
            if (!group) {
                group = createGroup(featureGroupName);
                groups.push(group);
            }
            group.features.push(feature);
        }
    });
    // reset if no groups found in data
    // TODO discuss with paul, if we should show an empty header for the group without name and
    //  if to show that even if there's no other group
    if (groups.length === 1) {
        return [];
    }
    // remove default group if there are no features left
    if (!groups[0].features.length) {
        groups.shift();
    }
    return groups;
}
/**
 * @param name group name
 * @returns group
 */
function createGroup(name) {
    return { name: name, features: [] };
}
/**
 * Finds group by name
 *
 * @param groups groups
 * @param name name
 * @returns group
 */
function findGroup(groups, name) {
    return groups.find((g) => g.name === name);
}
const renderItems = (features, as, itemProps) => {
    const Component = typeof as === "string" ? FeatureListItem : as;
    return features.map((feature) => React.createElement(Component, Object.assign(Object.assign({}, itemProps), { as: as, key: feature.id, feature: feature })));
};
/**
 * @param enableGrouping grouping enabled
 * @param features filtered features
 * @param itemAs item props
 * @param itemProps item props
 * @returns itemGroups
 */
function calcAndRenderGroupedFeatureItems(enableGrouping, features, itemAs, itemProps) {
    if (enableGrouping && features.length) {
        const groups = determineFeatureGroups(features);
        if (groups && groups.length) {
            return {
                groups: groups,
                items: groups.map((group) => renderItems(group.features, itemAs, itemProps)),
            };
        }
    }
    return {
        groups: null,
        items: features.length
            ? [renderItems(features, itemAs, itemProps)]
            : [],
    };
}
/**
 * @param groupAs groupAs
 * @param features features
 * @param itemAs itemAs
 * @param itemProps itemProps
 * @returns groups
 */
export default function useFeatureListItemGroups(groupAs, features, itemAs, 
// TODO: import("react").Props,
itemProps) {
    return useMemo(() => calcAndRenderGroupedFeatureItems(!!groupAs, features, itemAs, itemProps), [groupAs, features, itemAs, itemProps]);
}
