import { FEATURE_SELECTION_PRESELECT } from "../config/feature/selections";
import createAppTitlePlugin from "./browser/app-title";
import createFeatureDeepLinkPlugin from "./browser/feature-deep-link";
import createLocalStoragePlugin from "./browser/local-storage";
import createNoScrollPlugin from "./browser/no-scroll";
import createPiwikTrackFeatureSelectionEventPlugin from "./browser/piwik-track-feature-selection-event";
import createPiwikTrackFullscreenToggleEventPlugin from "./browser/piwik-track-fullscreen-toggle-event";
//import createQuitFullscreenOnRenderPlugin from './browser/quit-fullscreen-on-render';
import createSearchPlugin from "./browser/search";
import createToggleMobileViewsPlugin from "./browser/toggle-mobile-views";
import createUserGeolocationPlugin from "./browser/user-geolocation";
import createViewsPlugin from "./browser/views";
import createFeatureSelectionDetailsUrlPlugin from "./common/feature-selection-details-url";
import createLangPlugin from "./common/lang";
import createOlProxyPlugin from "./common/ol-proxy";
/**
 * Create the default set of plugins for use in the browser IN ORDER!
 *
 * @param {Record<string, object>} options options map for default plugins
 * @returns {import('../types').PluginDefinition[]} sorted list of plugins
 */
export default function createDefaultPlugins(options = {}) {
    return [
        ["lang", createLangPlugin()],
        ["olProxy", createOlProxyPlugin(options.olProxy)],
        ["appTitle", createAppTitlePlugin(options.appTitle)],
        [
            "featurePreselectDeepLink",
            createFeatureDeepLinkPlugin(Object.assign({ getParameter: "preselect", featureSelection: FEATURE_SELECTION_PRESELECT, clearMissingParameters: ["feature"] }, (options.featurePreselectDeepLink || {}))),
        ],
        [
            "featureDeepLink",
            createFeatureDeepLinkPlugin(options.featureDeepLink),
        ],
        [
            "featureSelectionDetailsUrl",
            createFeatureSelectionDetailsUrlPlugin(options.featureSelectionDetailsUrl),
        ],
        [
            "localStoragePlugin",
            createLocalStoragePlugin(options.localStoragePlugin),
        ],
        ["noScroll", createNoScrollPlugin(options.noScroll)],
        [
            "piwikTrackFeatureSelectionEvent",
            createPiwikTrackFeatureSelectionEventPlugin(options.piwikTrackFeatureSelectionEvent),
        ],
        [
            "piwikTrackFullscreenToggleEvent",
            createPiwikTrackFullscreenToggleEventPlugin(options.piwikTrackFullscreenToggleEvent),
        ],
        ["search", createSearchPlugin(options.search)],
        //['quitFullscreenOnRender', createQuitFullscreenOnRenderPlugin(options.quitFullscreenOnRender)],
        [
            "toggleMobileViews",
            createToggleMobileViewsPlugin(options.toggleMobileViews),
        ],
        [
            "userGeolocation",
            createUserGeolocationPlugin(options.userGeolocation),
        ],
        ["views", createViewsPlugin(options.views)],
    ];
}
