import { deselectAll, selectExclusively, } from "@mapsight/core/lib/feature-selections/actions";
import { createFeatureSelectionSelector } from "@mapsight/core/lib/feature-selections/selectors";
import { observeStateOnce } from "@mapsight/lib-redux/observe-state";
import getQueryStringParameter from "@neonaut/lib-js/es/string/get-query-string-parameter";
import removeQueryStringParameter from "@neonaut/lib-js/es/string/remove-query-string-parameter";
import { VIEW_MAP_ONLY } from "../../config/constants/app";
import * as c from "../../config/constants/controllers";
import { FEATURE_SELECTION_SELECT } from "../../config/feature/selections";
import { setView } from "../../store/actions";
import { isViewMobile, viewSelector } from "../../store/selectors";
const defaultFeatureSelectionsController = c.FEATURE_SELECTIONS;
const defaultFeatureSelection = FEATURE_SELECTION_SELECT;
const defaultGetParameter = "feature";
const defaultAutoRemoveParameters = [defaultGetParameter, "cHash"];
function selectFeature(store, featureSelectionsController, featureSelection, featureId, setMapOnlyViewInMobile) {
    store.dispatch(selectExclusively(featureSelectionsController, featureSelection, featureId));
    if (setMapOnlyViewInMobile &&
        isViewMobile(viewSelector(store.getState()))) {
        store.dispatch(setView(VIEW_MAP_ONLY));
    }
}
// TODO: Move to lib-js?
/**
 * Hooks into global window.history[fn] to react to
 * history state changes from the app (as opposed to `window.onpopstate` which
 * triggers when a browser ui actions causes a history state change).
 *
 * @param {string} fn function name to patch (e.g. "pushState" or "replaceState")
 * @param {(state: object) => void} callback will get called _after_ that function has been called
 */
function patchWindowHistoryFunction(fn, callback) {
    const originalFn = window.history[fn];
    window.history[fn] = function (state, ...rest) {
        const returnValue = originalFn.apply(window.history, [state, ...rest]);
        callback(state);
        return returnValue;
    };
}
/**
 * This plugin will select the feature identified by the defined get parameter and optionally remove parameters from the
 * window.location using the history API.
 *
 * @param {object} [options] options
 * @param {string} [options.featureSelectionsController] name of the feature selections controller, defaults to mapsight ui default
 * @param {string} [options.featureSelection="select"] name of the feature selection to track
 * @param {string} [options.getParameter="feature"] name get parameter identifying the feature
 * @param {boolean} [options.setMapOnlyViewInMobile=true] will set MapOnly view on mobile if a feature has been linked
 * @param {string[] | boolean} [options.autoRemoveParameters] list of get parameters to remove once the feature
 *                                            has been selected, defaults to ["feature", "cHash"]. Pass false to disable.
 * @param {string[] | boolean} [options.clearMissingParameters] if set to true or an array clear if getParameter and the mentioned
 *                                            parameters are missing. don't clear if getParameter is missing, but one of the
 *                                            mentioned parameters is there. we keep this to be safe in race condition
 *                                            where ?feature is set but ?preselect is not where we want to keep the implicit
 *                                            preselect of the feature parameter
 *
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { featureSelectionsController = defaultFeatureSelectionsController, featureSelection = defaultFeatureSelection, getParameter = defaultGetParameter, autoRemoveParameters = defaultAutoRemoveParameters, setMapOnlyViewInMobile = true, clearMissingParameters = null, } = options;
    const selectorSelectedFeatures = createFeatureSelectionSelector(featureSelectionsController, featureSelection);
    if (process.env.NN_JS_LOG_LEVEL === "verbose") {
        console.log("feature-deep-link ", options);
    }
    let isHandled = false;
    return {
        afterRender: function handleFeatureDeepLink(context) {
            var _a, _b;
            if (isHandled) {
                return;
            }
            isHandled = true;
            const { store } = context;
            // We always do this action even if we should already have done that
            // by re-hydrating the state as it may not have had the dynamic get parameter passed along
            const featureId = getQueryStringParameter(window.location.search, getParameter);
            if (featureId) {
                selectFeature(store, featureSelectionsController, featureSelection, featureId, setMapOnlyViewInMobile);
                if (autoRemoveParameters && autoRemoveParameters.length) {
                    // if we can, remove initial feature id from url
                    // when the selection changes (e.g. panel is closed/different feature is selected)
                    observeStateOnce(store, selectorSelectedFeatures, () => {
                        let newUrl = window.location.href;
                        autoRemoveParameters.forEach((key) => {
                            newUrl = removeQueryStringParameter(newUrl, key);
                        });
                        window.history.pushState(null, window.document.title, newUrl);
                    });
                }
            }
            else {
                if (clearMissingParameters) {
                    let found = false;
                    while (!found &&
                        clearMissingParameters.shift &&
                        clearMissingParameters[0]) {
                        found = getQueryStringParameter(window.location.search, clearMissingParameters.shift());
                    }
                    if (!found) {
                        store.dispatch(deselectAll(featureSelectionsController, featureSelection));
                    }
                }
                // handle select via state at relaod
                if ((_a = window.history.state) === null || _a === void 0 ? void 0 : _a.featureId) {
                    selectFeature(store, featureSelectionsController, featureSelection, (_b = window.history.state) === null || _b === void 0 ? void 0 : _b.featureId, setMapOnlyViewInMobile);
                }
            }
            const handleHistoryStateChange = (state) => {
                if (state === null || state === void 0 ? void 0 : state.featureId) {
                    selectFeature(store, featureSelectionsController, featureSelection, state.featureId, setMapOnlyViewInMobile);
                }
                else if ((state === null || state === void 0 ? void 0 : state.featureId) === null) {
                    // test for null to inhibit action if featureId is simply omitted
                    store.dispatch(deselectAll(featureSelectionsController, featureSelection));
                }
            };
            patchWindowHistoryFunction("pushState", handleHistoryStateChange);
            patchWindowHistoryFunction("replaceState", handleHistoryStateChange);
        },
    };
}
