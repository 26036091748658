var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
function FeatureListCyclingButton(_a) {
    var { direction, label } = _a, rest = __rest(_a, ["direction", "label"]);
    return (_jsx("button", Object.assign({ type: "button", className: `ms3-list-cycling-box__button ms3-list-cycling-box__button--${direction}` }, rest, { children: _jsx("span", { className: "ms3-visuallyhidden", children: label }) })));
}
export default FeatureListCyclingButton;
