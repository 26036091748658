import { observeState } from "@mapsight/lib-redux/observe-state";
import { trackEvent } from "@neonaut/lib-js/es/misc/piwik";
import { isFullscreenSelector } from "../../store/selectors";
const defaultCategory = "Mapsight";
const defaultActionEnabled = "FullscreenEnabled";
const defaultActionDisabled = "FullscreenDisabled";
/**
 * This plugin will track piwik actions when the fullscreen mode is en-/disabled.
 *
 * @see `@neonaut/lib-js/es/misc/piwik`
 *
 * @param {object} [options] options
 * @param {string} [options.category="Mapsight"] piwik category to track
 * @param {string} [options.actionEnabled="FullscreenEnabled"]  piwik action to track when enabling fullscreen
 * @param {string} [options.actionDisabled="FullscreenDisabled"]  piwik action to track when disabling fullscreen
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { category = defaultCategory, actionEnabled = defaultActionEnabled, actionDisabled = defaultActionDisabled, } = options;
    return {
        afterCreate: function piwikTrackFullscreenToggleEvent(context) {
            const { store } = context;
            // track fullscreen toggles
            observeState(store, isFullscreenSelector, (newValue) => trackEvent(category, newValue ? actionEnabled : actionDisabled));
        },
    };
}
