import fitMapViewToFeature from "@mapsight/lib-ol/map/fitToFeature";
import fitMapViewToFeatures from "@mapsight/lib-ol/map/fitToFeatures";
import centerViewOnFeature from "@mapsight/lib-ol/view/centerOnFeature";
import centerViewOnFeatures from "@mapsight/lib-ol/view/centerOnFeatures";
import { ANIMATE } from "../actions";
import WithMap from "./WithMap";
export default class WithAnimations extends WithMap {
    _pendingAnimation = null;
    _enqueueAnimation(animationFunction) {
        const map = this.getMap();
        this._pendingAnimation = animationFunction;
        map?.render(); // trigger render to ensure the animation is fulfilled timely
    }
    init() {
        const map = this.getMap();
        if (!map) {
            console.error("Could not initialize WithAnimations mixin: map is not defined");
            return;
        }
        const postRenderAnimationHandler = () => {
            if (this._pendingAnimation && map.getSize()) {
                this._pendingAnimation();
                this._pendingAnimation = null;
            }
        };
        map.on("postrender", postRenderAnimationHandler);
        const reduceWithAnimations = (state = {}, action) => {
            if (action.type === ANIMATE) {
                const { center, resolution, bounds, zoom, ...options } = action.options;
                if (bounds) {
                    this.fit(bounds, options);
                }
                else {
                    const view = map.getView();
                    this.animate({
                        zoom: resolution
                            ? view.getZoomForResolution(resolution)
                            : zoom,
                        center: center,
                        ...options,
                    });
                }
            }
            return state;
        };
        this.registerReducer(reduceWithAnimations);
    }
    fit(bounds, options) {
        this._enqueueAnimation(() => {
            this.getMap()?.getView().fit(bounds, options);
        });
    }
    animate(options) {
        this._enqueueAnimation(() => {
            this.getMap()?.getView().animate(options);
        });
    }
    // TODO: make this an action?
    fitMapViewToFeatures(features, options) {
        this._enqueueAnimation(() => {
            const map = this.getMap();
            if (map) {
                fitMapViewToFeatures(map, features, options);
            }
        });
    }
    // TODO: make this an action?
    fitMapViewToFeature(feature, options) {
        this._enqueueAnimation(() => {
            const map = this.getMap();
            if (map) {
                fitMapViewToFeature(map, feature, options);
            }
        });
    }
    // TODO: make this an action?
    centerViewOnFeatures(features, options) {
        this._enqueueAnimation(() => {
            const map = this.getMap();
            if (map) {
                centerViewOnFeatures(map.getView(), features, options);
            }
        });
    }
    // TODO: make this an action?
    centerViewOnFeature(feature, options) {
        this._enqueueAnimation(() => {
            const map = this.getMap();
            if (map) {
                centerViewOnFeature(map.getView(), feature, options);
            }
        });
    }
}
