import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import getFeatureProperty from "../../helpers/get-feature-property";
import { translate } from "../../helpers/i18n";
import { SEARCH_STATUS_EMPTY, SEARCH_STATUS_ERROR, SEARCH_STATUS_FOUND, SEARCH_STATUS_LOADING, searchResultFeaturesSelector, searchStatusSelector, } from "../../store/selectors";
import SearchResultEntry from "./result-entry";
const defaultGroup = "__default__";
function SearchResult({ closeSearch, onReturnFocus }) {
    const status = useSelector(searchStatusSelector);
    const features = useSelector(searchResultFeaturesSelector);
    const groupedEntries = {};
    features.forEach((feature, i) => {
        const group = getFeatureProperty(feature, "group") || defaultGroup;
        groupedEntries[group] = groupedEntries[group] || [];
        groupedEntries[group].push(_jsx(SearchResultEntry, { feature: feature, closeSearch: closeSearch, onReturnFocus: onReturnFocus }, feature.id || i));
    });
    return (_jsxs("div", { className: `ms3-search-result ms3-search-result--${status}`, children: [status === SEARCH_STATUS_ERROR && (_jsx("p", { className: "ms3-search-result__error", children: translate("ui.search.result.error") })), status === SEARCH_STATUS_EMPTY && (_jsx("p", { className: "ms3-search-result__empty-message", children: translate("ui.search.result.empty") })), (status === SEARCH_STATUS_FOUND ||
                status === SEARCH_STATUS_LOADING) &&
                features &&
                Object.keys(groupedEntries).map((group) => (_jsxs("div", { className: "ms3-search-result__group", children: [group && group !== defaultGroup ? (_jsx("h4", { className: "ms3-search-result__group-head", children: group })) : null, _jsx("ul", { className: "ms3-search-result__list", children: groupedEntries[group] })] }, group)))] }));
}
export default memo(SearchResult);
