const filterFunctions = {};
export const addFilterFunction = (filterKey, filterFunction) => {
    filterFunctions[filterKey] = filterFunction;
};
const applyFilter = (features, filterKey, filterState, featureSourceId) => {
    const filterFunction = filterFunctions[filterKey];
    if (filterFunction) {
        return filterFunction(features, filterState, featureSourceId);
    }
    console.info(`No filter function defined for ${filterKey}. skipping filter`);
    return features;
};
/**
 * Applies filter to features
 *
 * @param filters Filters state
 * @param features Array of features to filter
 * @param featureSourceId id of the feature source
 * @returns Array of filtered features
 */
export const applyFilters = (filters, features, featureSourceId) => Object.keys(filters).reduce((filteredFeatures, filterKey) => applyFilter(filteredFeatures, filterKey, filters[filterKey], featureSourceId), features);
