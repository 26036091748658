import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useContext, useEffect, useRef } from "react";
import getFeatureProperty from "../../helpers/get-feature-property";
import { translate } from "../../helpers/i18n";
import { ComponentsContext } from "../../helpers/components";
function FeatureDetailsContentInner({ feature, url, hasError, html, handleContentChange, }) {
    const comps = useContext(ComponentsContext);
    // NOTE(PG): using useCallback in addition to useRef alone so we actually get called when node changes
    const containerRef = useRef();
    const containerRefSetter = useCallback((element) => {
        containerRef.current = element;
        if (element && handleContentChange) {
            handleContentChange(element);
        }
    }, [handleContentChange]);
    const desc = getFeatureProperty(feature, "description");
    // NOTE(PG): Using useEffect to detect content changes (here: props), maybe we could move this up into the parent comp?
    useEffect(() => {
        handleContentChange(containerRef.current);
    }, [handleContentChange, html, hasError, desc]);
    let content = null;
    if (url) {
        if (html) {
            // success
            content = (_jsx("div", { className: "ms3-feature-details-content ms3-feature-details-content--html", dangerouslySetInnerHTML: { __html: html }, ref: containerRefSetter }));
        }
        else if (hasError) {
            // error
            content = (_jsxs("div", { className: "ms3-feature-details-content ms3-feature-details-content--error", ref: containerRefSetter, children: [translate("ui.feature-details.content-inner.error"), _jsx("br", {}), _jsx("a", { href: url, children: translate("ui.feature-details.content-inner.gotoPage") })] }));
        }
        else {
            // loading
            content = (_jsx("div", { className: "ms3-feature-details-content ms3-feature-details-content--loading", ref: containerRefSetter, children: translate("ui.feature-details.content-inner.loading") }));
        }
    }
    else {
        if (desc) {
            content = (_jsx("div", { className: "ms3-feature-details-content ms3-feature-details-content--description", dangerouslySetInnerHTML: { __html: desc }, ref: containerRefSetter }));
        }
    }
    if (comps.FeatureDetailsContent) {
        content = (_jsx(comps.FeatureDetailsContent, { feature: feature, url: url, hasError: hasError, html: html, children: content }));
    }
    return content;
}
export default memo(FeatureDetailsContentInner);
