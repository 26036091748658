const strictEqualCompare = (a, b) => a === b;
function isActionWithMeta(action) {
    return "meta" in action;
}
function isControlledAction(action, controlledActionFlag) {
    return (isActionWithMeta(action) && action.meta?.[controlledActionFlag] === true);
}
/**
 * Enhances the store. Allows actions to be flagged as controlled and adds a new function
 * store.observeUncontrolled(selector, onChange, compare) to the store.
 *
 * @param {object} store redux store
 * @param {string|symbol} controlledActionFlag flag that controlled actions expose (action[controlledActionFlag] == true)
 */
export default function enableControlledDispatchAndObserve(store, controlledActionFlag = "isControlled") {
    let listeners = [];
    function removeListener(listener) {
        listeners = listeners.filter((l) => l !== listener);
    }
    function addListener(listener) {
        listeners.push(listener);
    }
    const subscribeUncontrolled = (listener) => {
        addListener(listener);
        return function removeListenerBound() {
            removeListener(listener);
        };
    };
    const observeUncontrolled = (selector, onChange, compare = strictEqualCompare) => subscribeUncontrolled(function handleUncontrolledChange(previousState, state) {
        const previousValue = selector(previousState);
        const newValue = selector(state);
        if (!compare(previousValue, newValue)) {
            onChange(newValue, previousValue, state);
        }
    });
    let wasControlled = false;
    const baseDispatch = store.dispatch;
    const enhancedDispatch = (action) => {
        wasControlled = isControlledAction(action, controlledActionFlag);
        return baseDispatch(action);
    };
    let previousState = store.getState();
    function listenForUncontrolledActions() {
        const state = store.getState();
        if (!wasControlled) {
            listeners.forEach(function callUncontrolledChangeListener(listener) {
                listener(previousState, state);
            });
        }
        previousState = state;
    }
    store.subscribe(listenForUncontrolledActions);
    Object.assign(store, {
        dispatch: enhancedDispatch,
        subscribeUncontrolled: subscribeUncontrolled,
        observeUncontrolled: observeUncontrolled,
    });
}
