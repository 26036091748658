var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { translate } from "../../helpers/i18n";
function FeatureListEmptyMessage(_a) {
    var { hasSource, as: T = "div" } = _a, attributes = __rest(_a, ["hasSource", "as"]);
    const emptyMessage = hasSource
        ? translate("ui.feature-list.content.noEntries")
        : translate("ui.feature-list.content.noListSelected");
    return (_jsx(T, Object.assign({ className: "ms3-list__empty" }, attributes, { children: emptyMessage })));
}
export default FeatureListEmptyMessage;
