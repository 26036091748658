var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FEATURE_LIST } from "../../config/constants/controllers";
import { layerSwitcherConfigInternalSelector } from "../../store/selectors";
import LayerSwitcherContainer from "./LayerSwticherContainer";
const TRUE_SET_FEATURE_SOURCE_PATH = [FEATURE_LIST, "featureSource"];
/**
 * @param {object} props properties
 * @param {Function} props.onClose callback when closed
 * @param {(state: object) => object} [props.configSelector] config selector, default = layerSwitcherConfigInternalSelector
 */
export default connect(createStructuredSelector({
    layerIdsSelector: (state, { configSelector = layerSwitcherConfigInternalSelector }) => configSelector(state).layerIdsSelector,
    grouped: (state, { configSelector = layerSwitcherConfigInternalSelector }) => configSelector(state).grouped,
    setFeatureSourceIdPath: function setFeatureSourceIdPath(state, { configSelector = layerSwitcherConfigInternalSelector }) {
        const config = configSelector(state);
        if (config.setFeatureSourceId === true) {
            return TRUE_SET_FEATURE_SOURCE_PATH;
        }
        else {
            return config.setFeatureSourceId;
        }
    },
}), null, (stateProps, dispatchProps, _a) => {
    var { configSelector } = _a, ownProps = __rest(_a, ["configSelector"]);
    return (Object.assign(Object.assign({}, ownProps), stateProps));
})(LayerSwitcherContainer);
