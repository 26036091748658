import { load, LOAD_FEATURE_SOURCE_ERROR, LOAD_FEATURE_SOURCE_SUCCESS, } from "@mapsight/core/lib/feature-sources/actions";
import get from "@neonaut/lib-js/es/object/getPath";
import { compose } from "redux";
import { FEATURE_LIST, FEATURE_SOURCES, } from "../../config/constants/controllers";
import createActionWatcher from "../../helpers/create-action-watcher";
const defaultLoadOptions = {};
const defaultListControllerName = FEATURE_LIST;
/**
 * This plugin will delay the render until the feature source for the list is loaded
 *
 * @param {object} [options] options
 * @param {string} [options.loadOptions] options passed to the load function (depending on source type)
 * @param {string} [options.listControllerName="list"] list controller name
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { loadOptions = defaultLoadOptions, listControllerName = defaultListControllerName, } = options;
    const actionWatcher = createActionWatcher();
    return {
        afterInit: function renderAwaitListFeatureSourcesLoadedInitPlugin(context) {
            context.storeEnhancer = compose(context.storeEnhancer, actionWatcher.enhancer);
        },
        beforeRender: function renderAwaitListFeatureSourcesLoadedRenderPlugin(context) {
            if (!context.canPluginsDelayRender) {
                return Promise.resolve(undefined);
            }
            return new Promise(function (resolve) {
                let featureSourcesToBeLoaded = getFeatureSourcesToBeLoadedFromConfig(context.baseMapsightConfig, listControllerName);
                if (!featureSourcesToBeLoaded.length) {
                    resolve();
                    return;
                }
                actionWatcher.handler = (action) => {
                    // wait for feature sources to be loaded
                    if (action.type === LOAD_FEATURE_SOURCE_SUCCESS ||
                        action.type === LOAD_FEATURE_SOURCE_ERROR) {
                        if (featureSourcesToBeLoaded.indexOf(action.id) > -1) {
                            featureSourcesToBeLoaded =
                                featureSourcesToBeLoaded.filter((f) => f !== action.id);
                        }
                        if (!featureSourcesToBeLoaded.length) {
                            resolve();
                        }
                    }
                };
                featureSourcesToBeLoaded.forEach((featureSourceId) => context.store.dispatch(load(FEATURE_SOURCES, featureSourceId, loadOptions)));
            });
        },
    };
}
function getFeatureSourcesToBeLoadedFromConfig(baseMapsightConfig, listControllerName) {
    return [
        get(baseMapsightConfig, [listControllerName, "featureSource"]),
    ].filter((a) => a);
}
