import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector, useStore } from "react-redux";
//import {viewportAnchorSelector} from "@mapsight/core/lib/map/selectors";
//import {MAP} from "../config/constants/controllers"; // TODO: Use context for this?
import useUpdateMapSizeOnRender from "../hooks/useUpdateMapSizeOnRender";
import useUpdateMapSizeOnTransitionEnd from "../hooks/useUpdateMapSizeOnTransitionEnd";
import useUpdateMapSizeOnViewChange from "../hooks/useUpdateMapSizeOnViewChange";
import { setMapIsOutOfViewport } from "../store/actions";
import { viewSelector } from "../store/selectors";
import { APP_EVENT_SCROLL_TO_MAP, useAppChannelEventListener, } from "./helping/app-channel";
function MapWrapper(props) {
    const { children,
    //controllerName = MAP
     } = props;
    const dispatch = useDispatch();
    const mapWrapperRef = useRef();
    useUpdateMapSizeOnViewChange(useSelector(viewSelector), useStore());
    useUpdateMapSizeOnTransitionEnd(mapWrapperRef.current, dispatch);
    useUpdateMapSizeOnRender(dispatch);
    useAppChannelEventListener(APP_EVENT_SCROLL_TO_MAP, useCallback(() => {
        var _a;
        (_a = mapWrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });
    }, [mapWrapperRef]));
    //	const anchor = useSelector(
    //		useMemo(
    //			() => (state) => viewportAnchorSelector(state[controllerName]),
    //			[controllerName],
    //		),
    //	);
    const { ref: inViewRef } = useInView({
        threshold: 0,
        initialInView: true,
        fallbackInView: true,
        onChange: (inView) => dispatch(setMapIsOutOfViewport(!inView)),
    });
    const setRef = useCallback((node) => {
        mapWrapperRef.current = node !== null && node !== void 0 ? node : undefined;
        inViewRef(node);
    }, [inViewRef]);
    return (_jsx("div", { ref: setRef, 
        // ms3-map-wrapper--anchored-${anchor}
        className: `ms3-map-wrapper  [ ms3-flex ms3-flex--column ] [ ms3-scroll-target ]`, children: children }));
}
export default memo(MapWrapper);
