import PinchZoom from "ol/interaction/PinchZoom";
import base from "./_base";
export default {
    type: "PinchZoomInteraction",
    Constructor: PinchZoom,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
    },
};
