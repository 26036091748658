import getExtent from "../feature/getExtent";
import fitToExtent, { DEFAULT_OPTIONS as DEFAULT_OPTIONS_BASE, } from "./fitToExtent";
export const DEFAULT_OPTIONS = { ...DEFAULT_OPTIONS_BASE };
// TODO: Document keepZoom option!
// TODO: Document skipIfInView option!
export default function fitToFeature(map, feature, options = DEFAULT_OPTIONS) {
    if (!feature) {
        return;
    }
    const extent = getExtent(feature);
    if (!extent) {
        return;
    }
    fitToExtent(map, extent, options);
}
