import { BaseController } from "../../../lib/base/controller";
export default class WithClusterFeatureFunction extends BaseController {
    _clusterFeaturePropertiesFunction;
    setClusterFeaturePropertiesFunction(clusterFeaturePropertiesFunction) {
        this._clusterFeaturePropertiesFunction =
            clusterFeaturePropertiesFunction;
    }
    getClusterFeaturePropertiesFunction() {
        return this._clusterFeaturePropertiesFunction;
    }
}
