import GeoJSON from "ol/format/GeoJSON";
export default {
    type: "GeoJSONFormat",
    Constructor: GeoJSON,
    optionMap: {},
    initialOptionMap: {
        defaultDataProjection: "defaultDataProjection",
        featureProjection: "featureProjection",
        geometryName: "defaultDataProjection",
    },
};
