import forEach from "lodash/forEach";
import { di, updateProxyObject } from "../../../ol-proxy";
import { getIdForLayer, tagLayer } from "./tagLayer";
import WithMap from "./WithMap";
export const Z_INDEX_OVERLAY = 2;
export const LAYER_GROUP_DEFAULT = "default";
export const LAYER_TYPE = "VectorOverlayLayer";
function getOverlayFeatureCollectionForLayer(overlays, layer) {
    const layerId = getIdForLayer(layer);
    return layerId
        ? overlays[layerId]?.getSource()?.getFeaturesCollection()
        : undefined;
}
export default class WithLayerOverlays extends WithMap {
    _overlays = {};
    init() {
        this._overlays = {};
        const updateLayerOverlay = (id, newDefinition, oldDefinitions) => {
            const oldDefinition = oldDefinitions[id];
            newDefinition = newDefinition && {
                zIndex: Z_INDEX_OVERLAY,
                ...newDefinition,
            };
            // update overlay
            // TODO: make overlay optional?
            const featureSelections = newDefinition?.options?.selections;
            updateProxyObject({
                di: di,
                oldObject: this._overlays[id],
                oldDefinition: { ...oldDefinition, type: LAYER_TYPE },
                newDefinition: featureSelections && {
                    ...newDefinition,
                    type: LAYER_TYPE,
                },
                remover: () => {
                    this._overlays[id]?.setMap(null);
                    delete this._overlays[id];
                },
                adder: (overlay) => {
                    overlay.setMap(this.getMap());
                    this._overlays[id] = overlay;
                    tagLayer(overlay, this, id);
                },
                parentObject: this,
            });
        };
        this.getAndObserveUncontrolled((state) => state.layers, function updateLayerOverlays(newDefinitions = {}, oldDefinitions = {}) {
            forEach(oldDefinitions, (_, id) => updateLayerOverlay(id, newDefinitions[id], oldDefinitions));
            forEach(newDefinitions, (newDefinition, id) => updateLayerOverlay(id, newDefinition, oldDefinitions));
        });
    }
    moveFeatureToOverlay(layer, feature) {
        const overlayFeatureCollection = getOverlayFeatureCollectionForLayer(this._overlays, layer);
        if (overlayFeatureCollection &&
            overlayFeatureCollection.getArray().indexOf(feature) === -1) {
            overlayFeatureCollection.push(feature);
        }
    }
    removeFeatureFromOverlay(layer, feature) {
        const overlayFeatureCollection = getOverlayFeatureCollectionForLayer(this._overlays, layer);
        if (overlayFeatureCollection) {
            overlayFeatureCollection.remove(feature);
        }
    }
}
