var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, memo } from "react";
import SwitcherButton from "./SwitcherButton";
function SwitcherHeader(props) {
    const { as: T = "h3", baseClass = "ms3-switcher-header", label, toggleActive = null, active = true, count = null } = props, attributes = __rest(props, ["as", "baseClass", "label", "toggleActive", "active", "count"]);
    const activeClassModified = active ? "active" : "inactive";
    if (T !== Fragment) {
        attributes.className = `[ ${baseClass} ${baseClass}--${activeClassModified} ] ${attributes.className || ""}`;
    }
    if (toggleActive) {
        return (_jsx(T, Object.assign({}, attributes, { children: _jsxs(SwitcherButton, { status: activeClassModified, toggleActive: toggleActive, active: active, children: [_jsx("span", { className: `${baseClass}__label`, children: label }), !!count && (_jsx("span", { className: `${baseClass}__count`, "data-ms3-count": count, children: count }))] }) })));
    }
    return (_jsxs(T, Object.assign({}, attributes, { children: [_jsx("span", { className: `${baseClass}__label`, children: label }), !!count && (_jsx("span", { className: `${baseClass}__count`, "data-ms3-count": count, children: count }))] })));
}
export default memo(SwitcherHeader);
