import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../helpers/i18n";
import useDebounce from "../../hooks/useDebounce";
import { search } from "../../store/actions";
import { searchQuerySelector } from "../../store/selectors";
import SearchQueryInput from "./query-input";
import SearchResult from "./result";
import useHandleEscapeKeyDown from "../../hooks/useHandleEscapeKeyDown";
const preventDefault = (e) => e.preventDefault();
function Search({ closeSearch, autoFocus }) {
    const dispatch = useDispatch();
    const query = useSelector(searchQuerySelector);
    const debouncedQuery = useDebounce(query, 200);
    useEffect(() => {
        if (debouncedQuery !== "") {
            dispatch(search(debouncedQuery));
        }
    }, [dispatch, debouncedQuery]);
    // needed to "override" debounce if the value is ""
    useEffect(() => {
        if (query === "") {
            dispatch(search(query));
        }
    }, [dispatch, query]);
    const handleInput = useCallback((searchQuery) => {
        dispatch({ type: "SEARCH", query: searchQuery }); // action without ajax side effect
    }, [dispatch]);
    const inputRef = useRef();
    useHandleEscapeKeyDown(inputRef, useCallback(() => dispatch(search(""), [dispatch])));
    const handleReturnFocus = useCallback(() => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, []);
    return (_jsxs("form", { className: "ms3-search", onSubmit: preventDefault, children: [_jsxs("div", { className: "ms3-search__query-input", children: [_jsx(SearchQueryInput, { ref: inputRef, query: query, onChange: handleInput, name: "search", autoFocus: autoFocus }), _jsx("button", { className: "ms3-search__send-button", type: "submit", children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.search.send") }) })] }), query && (_jsx("output", { className: "ms3-search__output", name: "search-output", htmlFor: "search", children: _jsx(SearchResult, { onReturnFocus: handleReturnFocus, closeSearch: closeSearch }) }))] }));
}
export default memo(Search);
