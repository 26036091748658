import { useEffect } from "react";
import useUpdateMapSizeCallback from "./useUpdateMapSizeCallback";
export default function useUpdateMapSizeOnRender(
// TODO: use hook
dispatch) {
    const updateMapSize = useUpdateMapSizeCallback(dispatch, undefined, false);
    useEffect(() => {
        console.log("UPDATE MAP SIZE ON RENDER");
        return updateMapSize();
    }, [updateMapSize]);
}
