import VectorLayer from "ol/layer/Vector";
import { di, isDescription, OPTION_SET, updateProxyObject, } from "../../../ol-proxy";
import base from "./_base";
export function createVectorLayerSourceOptionMapper() {
    return (layer, _name, oldDefinition, newDefinition) => {
        if (oldDefinition !== undefined && !isDescription(oldDefinition)) {
            // TODO: Should we log an error here?
            return;
        }
        if (!isDescription(newDefinition)) {
            // TODO: Should we log an error here?
            return;
        }
        updateProxyObject({
            di,
            oldObject: layer.getSource(),
            oldDefinition: oldDefinition,
            newDefinition: newDefinition,
            parentObject: layer,
            remover: () => layer.setSource(null),
            adder: (source) => {
                if (source.setLayer) {
                    source.setLayer(layer);
                }
                layer.setSource(source);
            },
        });
    };
}
export function createVectorLayerStyleOptionMapper() {
    return (layer, _, __, style, { parentObject: mapController }) => {
        const styleEnv = typeof style === "string"
            ? { style: style }
            : style;
        const styleFunction = mapController.createStyleFunction(styleEnv);
        layer.setStyle(styleFunction);
    };
}
export default {
    type: "VectorLayer",
    Constructor: VectorLayer,
    optionMap: {
        ...base.optionMap,
        source: createVectorLayerSourceOptionMapper(),
        style: createVectorLayerStyleOptionMapper(),
        styleFunction: "setStyle",
        preload: "setPreload",
        renderBuffer: OPTION_SET,
        renderOrder: OPTION_SET,
        useInterimTilesOnError: "setUseInterimTilesOnError",
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        preload: "preload",
        styleFunction: "style",
        renderBuffer: "renderBuffer",
        renderMode: "renderMode",
        renderOrder: "renderOrder",
        declutter: "declutter",
        updateWhileAnimating: "updateWhileAnimating",
        updateWhileInteracting: "updateWhileInteracting",
        useInterimTilesOnError: "useInterimTilesOnError",
    },
};
