import { ensureNonNullable } from "@neonaut/lib-js/nonNullable";
/**
 * Gets distance between points
 *
 * @param a point a
 * @param b point b
 * @returns distance
 */
function getDistance(a, b) {
    const [aX, aY] = a;
    const [bX, bY] = b;
    return (Math.abs(ensureNonNullable(aX) - ensureNonNullable(bX)) +
        Math.abs(ensureNonNullable(aY) - ensureNonNullable(bY)));
}
/**
 * Finds the closest point to given basePoint [x, y] in map. Returns the key.
 *
 * @param basePoint base point
 * @param pointsMap map of
 * @returns key of the closest point in given pointsMap
 */
export default function findClosestPoint(basePoint, pointsMap) {
    const keys = Object.keys(pointsMap);
    const keyDistances = keys.map((key) => [
        key,
        getDistance(basePoint, ensureNonNullable(pointsMap[key])),
    ]);
    const reduceToSmallestDistance = (a, b) => (a !== null && a[1] > b[1] ? a : b);
    const smallest = keyDistances.reduce(reduceToSmallestDistance, null);
    return smallest === null ? null : smallest[0];
}
