import * as c from "../../config/constants/controllers";
import { searchResultSelectionFeatureSourceSelector } from "../../store/selectors";
const defaultFeatureSourceId = "searchResult";
const defaultFeatureSourcesControllerName = c.FEATURE_SOURCES;
/**
 * This plugin will bind the search result feature source to the mapsight ui state
 *
 * @param {object} [options] options
 * @param {string} [options.featureSourceId] name of the feature source, default = "searchResult"
 * @param {string} [options.featureSourcesControllerName] name of the feature sources controller, defaults to mapsight ui default
 * @returns {import('../../types').PluginInstance} plugin instance
 */
export default function createPlugin(options = {}) {
    const { featureSourceId = defaultFeatureSourceId, featureSourcesControllerName = defaultFeatureSourcesControllerName, } = options;
    return {
        afterCreate: function searchPlugin(context) {
            context.controllers[featureSourcesControllerName].bindFeatureSourceToStore(featureSourceId, searchResultSelectionFeatureSourceSelector);
        },
    };
}
