import isFiniteExtent from "../extent/isFiniteExtent";
import containsExtentWithPadding from "./containsExtentWithPadding";
export const DEFAULT_OPTIONS = {
    duration: 300,
    padding: [60, 100, 60, 100],
    keepZoom: false,
    maxZoom: 17,
    skipIfInView: true, // TODO: Document skipIfInView option!
};
// TODO: Document keepZoom option!
// TODO: Document skipIfInView option!
export default function fitToExtent(map, extent, options = DEFAULT_OPTIONS) {
    if (!isFiniteExtent(extent)) {
        return;
    }
    const padding = (options.padding || [0, 0, 0, 0]);
    if (options.skipIfInView === false ||
        !containsExtentWithPadding(map, extent, padding)) {
        const view = map.getView();
        view.fit(extent, {
            ...options,
            maxZoom: options.keepZoom ? view.getZoom() : options.maxZoom,
        });
    }
}
