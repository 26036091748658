export function forwardRefValue(source, dest) {
    if (dest !== null) {
        if (typeof dest === "function") {
            dest(source.current);
        }
        else {
            dest.current = source.current;
        }
    }
}
