import { async, quiet as quietAction } from "../../../../lib/base/actions";
import { addFeature, addFeatures, load, removeAllFeatures, removeFeature, updateFeature, updateFeatureGeometry, updateFeatures, } from "../../../../lib/feature-sources/actions";
import SharedReadonlyVectorFeatureSource from "./SharedReadonlyVectorFeatureSource";
export default class FeatureSourceConnector {
    _id = null;
    _targetControllerName = undefined;
    _unsubscribeFeatureSource = null;
    _store = null;
    _internalProjection;
    _externalProjection;
    _onUpdate;
    _controllerName = undefined;
    _format;
    _source = null;
    constructor({ onUpdate, format, internalProjection, externalProjection, }) {
        this._internalProjection = internalProjection;
        this._externalProjection = externalProjection;
        this._format = format;
        this._onUpdate = onUpdate;
    }
    _dispatchIfPossible(action) {
        if (this._controllerName && this._id && this._store) {
            this._store.dispatch(action);
        }
    }
    load() {
        if (this._controllerName && this._id)
            this._dispatchIfPossible(load(this._controllerName, this._id));
    }
    getFeatures() {
        return this._source ? this._source.getFeatures() : [];
    }
    getFeaturesInExtent(...args) {
        return this._source ? this._source.getFeaturesInExtent(...args) : [];
    }
    addFeature(feature) {
        if (this._controllerName && this._id)
            this._dispatchIfPossible(async(addFeature(this._controllerName, this._id, feature)));
    }
    addFeatures(features) {
        if (this._controllerName && this._id)
            this._dispatchIfPossible(async(addFeatures(this._controllerName, this._id, features)));
    }
    updateFeature(featureId, feature, { quiet = false } = {}) {
        if (!this._controllerName || !this._id)
            return;
        let action = async(updateFeature(this._controllerName, this._id, featureId, feature));
        if (quiet) {
            action = quietAction(action);
        }
        this._dispatchIfPossible(action);
    }
    updateFeatures(features, { quiet = false } = {}) {
        if (!this._controllerName || !this._id)
            return;
        let action = async(updateFeatures(this._controllerName, this._id, features));
        if (quiet) {
            action = quietAction(action);
        }
        this._dispatchIfPossible(action);
    }
    updateFeatureGeometry(featureId, geometry, { quiet = false } = {}) {
        if (!this._controllerName || !this._id)
            return;
        let action = async(updateFeatureGeometry(this._controllerName, this._id, featureId, geometry));
        if (quiet) {
            action = quietAction(action);
        }
        this._dispatchIfPossible(action);
    }
    removeFeature(featureId) {
        if (!this._controllerName || !this._id)
            return;
        this._dispatchIfPossible(async(removeFeature(this._controllerName, this._id, featureId)));
    }
    clear() {
        if (!this._controllerName || !this._id)
            return;
        this._dispatchIfPossible(async(removeAllFeatures(this._controllerName, this._id)));
    }
    setId(id) {
        this._id = id;
        this._subscribeFeatureSource();
    }
    setStore(store) {
        this._store = store;
        this._subscribeFeatureSource();
    }
    setControllerName(featureSourcesControllerName) {
        this._controllerName = featureSourcesControllerName;
        this._subscribeFeatureSource();
    }
    setTargetControllerName(ctrName) {
        this._targetControllerName = ctrName;
        this._subscribeFeatureSource();
    }
    setInternalProjection(projection) {
        this._internalProjection = projection;
        this._subscribeFeatureSource();
    }
    setProjection(projection) {
        this._externalProjection = projection;
        this._subscribeFeatureSource();
    }
    _subscribeFeatureSource() {
        if (this._unsubscribeFeatureSource) {
            this._unsubscribeFeatureSource();
            this._source = null;
            this._unsubscribeFeatureSource = null;
        }
        if (this._id &&
            this._store &&
            this._controllerName &&
            this._targetControllerName) {
            const { instance, unsubscribe } = SharedReadonlyVectorFeatureSource.subscribe(this._store, this._controllerName, this._id, this._targetControllerName, this._format, this._internalProjection, this._externalProjection, this._onUpdate);
            this._source = instance;
            this._unsubscribeFeatureSource = unsubscribe;
        }
    }
}
