import { useEffect } from "react";
import { isEventWithNoModKeys } from "../helpers/events";
export default function useHandleEscapeKeyDown(ref, onEscape) {
    useEffect(() => {
        const elem = ref.current;
        if (elem === null) {
            return;
        }
        const handleEsc = (ev) => {
            if (isEventWithNoModKeys(ev) && ev.key === "Escape") {
                onEscape();
            }
        };
        elem.addEventListener("keydown", handleEsc);
        return () => {
            elem.removeEventListener("keydown", handleEsc);
        };
    }, [ref.current, onEscape]);
}
