var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, Fragment, memo, useRef } from "react";
import FeatureListContent from "./content";
import { useFeatureListContext } from "./context";
import useFeatureListItemGroups from "./hooks/useFeatureListItemGroups";
import useKeyboardNavigation from "./hooks/useKeyboardNavigation";
function FeatureListGroupedContent(_a, forwardedRef) {
    var { as: T = "div", groupAs = null, itemAs } = _a, listProps = __rest(_a, ["as", "groupAs", "itemAs"]);
    const ownRef = useRef();
    const ref = forwardedRef || ownRef;
    const { state: { filteredFeatures: features = [] }, itemProps, enableKeyboardControl, } = useFeatureListContext();
    const rootProps = Object.assign(Object.assign({}, useKeyboardNavigation(enableKeyboardControl, ref)), { ref: ref });
    listProps.emptyAs = itemProps.as;
    // we have to separate calculation of items from wrapping them,
    // as the outer tags depend on restProps, and we do not want to recreate list items on changes to restProps.
    const itemGroups = useFeatureListItemGroups(groupAs, features, itemAs, itemProps);
    if (!features.length) {
        return _jsx(FeatureListContent, Object.assign({}, listProps, rootProps));
    }
    if (itemGroups.groups) {
        const GroupNameT = groupAs;
        return (_jsx(T, Object.assign({ className: "ms3-list-groups ms3-scroll-target" }, rootProps, { children: itemGroups.groups.map(function composeGroup(group, index) {
                return (_jsxs(Fragment, { children: [group.name ? (_jsx(GroupNameT, { className: "ms3-list__group ms3-list__group--name", children: group.name })) : null, _jsx(FeatureListContent, Object.assign({ "data-ms3-group-name": group.name }, listProps, { children: itemGroups.items[index] }))] }, group.name));
            }) })));
    }
    return (_jsx(FeatureListContent, Object.assign({}, listProps, rootProps, { children: itemGroups.items[0] })));
}
export default memo(forwardRef(FeatureListGroupedContent));
