import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import App from "../components/app";
import AppContext from "../components/helping/app-context";
/**
 * Browser renderer (DOM) with re-hydration
 */
const mapsightUiBrowserDomRenderer = (container, props, hydrate = false) => {
    ReactDOM[hydrate ? "hydrate" : "render"](_jsx(AppContext, Object.assign({}, props, { children: _jsx(App, {}) })), container);
};
export default mapsightUiBrowserDomRenderer;
