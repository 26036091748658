var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
function FeatureSelectButton(_a) {
    var { featureId, onSelect = null, onDeselect = null, permanentLink = undefined, isSelected = false, selectOnClick = true, deselectOnClick = true } = _a, attributes = __rest(_a, ["featureId", "onSelect", "onDeselect", "permanentLink", "isSelected", "selectOnClick", "deselectOnClick"]);
    const handleClick = useCallback((e) => {
        if (permanentLink) {
            if (e.which === 2 || e.metaKey) {
                // Continue as normal for external links and cmd clicks etc
                return;
            }
            e.preventDefault();
        }
        if (isSelected && deselectOnClick) {
            if (onDeselect) {
                onDeselect(featureId);
            }
        }
        else if (selectOnClick && onSelect) {
            onSelect(featureId, { keyboard: false });
        }
    }, [
        permanentLink,
        isSelected,
        deselectOnClick,
        selectOnClick,
        onDeselect,
        featureId,
        onSelect,
    ]);
    const handleKeyDown = useCallback((e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (selectOnClick && onSelect) {
                onSelect(featureId, { keyboard: true });
            }
        }
    }, [selectOnClick, onSelect, featureId]);
    Object.assign(attributes, {
        onClick: handleClick,
        onKeyDown: handleKeyDown,
    });
    if (permanentLink) {
        return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        _jsx("a", Object.assign({ href: permanentLink, role: "button" }, attributes)));
    }
    return _jsx("button", Object.assign({ type: "button" }, attributes));
}
export default FeatureSelectButton;
