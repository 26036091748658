import flattenActions from "@mapsight/lib-redux/flatten-actions"; // FIXME: use es once default export is fixed in @mapsight/lib-redux
import { applyMiddleware } from "redux";
import { isNonNullable } from "@neonaut/lib-js/nonNullable";
// TODO: This is a candidate for lib-redux
export default function createActionWatcher() {
    const context = {
        handler: null,
    };
    context.enhancer = applyMiddleware((_store) => (nextMiddleware) => (action) => {
        const result = nextMiddleware(action);
        if (isNonNullable(context.handler)) {
            const { handler } = context;
            flattenActions(action).map((singleAction) => handler(singleAction));
        }
        return result;
    });
    return context;
}
