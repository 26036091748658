var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { isEmbeddedMapSelector, mapVisible, viewSelector, } from "../../store/selectors";
function AppWrapper(_a) {
    var { children } = _a, attributes = __rest(_a, ["children"]);
    const view = useSelector(viewSelector);
    const isEmbeddedMap = useSelector(isEmbeddedMapSelector);
    const isMapVisible = useSelector(mapVisible);
    const className = `ms3-wrapper ms3-wrapper--${view} ${isEmbeddedMap ? "ms3-wrapper--embedded" : ""} ${isMapVisible ? "" : "ms3-wrapper--withoutmap"} ${attributes.className || ""}`;
    return (_jsx("div", Object.assign({ className: className }, attributes, { children: children })));
}
export default memo(AppWrapper);
