import UrlTile from "ol/source/UrlTile";
import base from "./_base";
export default {
    type: "UrlTileSource",
    Constructor: UrlTile,
    optionMap: {
        ...base.optionMap,
        url: "setUrl",
        urls: "setUrls",
        tileLoadFunction: "setTileLoadFunction",
        tileUrlFunction: "setTileUrlFunction",
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        url: "url",
        urls: "urls",
        tileLoadFunction: "tileLoadFunction",
        tileUrlFunction: "tileUrlFunction",
    },
};
