import { unreachable } from "@neonaut/lib-js/unreachable";
/**
 * Feature filter to filter by when property in a time range.
 * Features are expected to have a when object property with
 * start and end datetimes.
 */
const timeFilter = (features, filterState) => {
    const filterOptions = filterState.options;
    if (!filterOptions) {
        return features;
    }
    const { fromDate, toDate, allowEmpty = false } = filterOptions;
    if (!allowEmpty && !fromDate && !toDate) {
        return features;
    }
    // TODO: document/collect magic property names (here: when.start, when.end)
    const predicate = toDate
        ? (feature) => {
            var _a, _b;
            return ((_a = feature === null || feature === void 0 ? void 0 : feature.when) === null || _a === void 0 ? void 0 : _a.start) <= toDate &&
                ((_b = feature === null || feature === void 0 ? void 0 : feature.when) === null || _b === void 0 ? void 0 : _b.end) >= (fromDate || Date.now());
        }
        : fromDate
            ? (feature) => { var _a; return ((_a = feature === null || feature === void 0 ? void 0 : feature.when) === null || _a === void 0 ? void 0 : _a.end) >= fromDate; }
            : unreachable();
    return features.filter(predicate);
};
export default timeFilter;
