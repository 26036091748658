var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactModal from "react-modal";
import CloseOverlayButton from "./close-overlay-button";
const Modal = (_a) => {
    var { children, closeLabel, headline, onRequestClose } = _a, props = __rest(_a, ["children", "closeLabel", "headline", "onRequestClose"]);
    return (_jsx(ReactModal, Object.assign({ onRequestClose: onRequestClose, className: "ms3-modal", overlayClassName: "ms3-app-overlay", shouldCloseOnOverlayClick: true }, props, { children: _jsxs("div", { className: "ms3-modal__inner ms3-modal__inner--search", children: [_jsxs("div", { className: "ms3-modal__header", children: [_jsx("h3", { className: "ms3-modal__headline", children: headline }), _jsx("div", { className: "ms3-modal__close", children: _jsx(CloseOverlayButton, { label: closeLabel, onClose: onRequestClose }) })] }), children] }) })));
};
export default Modal;
