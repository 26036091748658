var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { STATUS_ERROR, STATUS_LOADING, STATUS_OK, } from "@mapsight/core/lib/feature-sources/selectors";
import React, { Fragment, memo } from "react";
import { translate } from "../../helpers/i18n";
import SwitcherButton from "./SwitcherButton";
import SwitcherStatusIcon from "./SwitcherStatusIcon";
export const STATUS_ACTIVE = "active";
export const STATUS_INACTIVE = "inactive";
const mapStatusClassName = (status) => ({
    [STATUS_ERROR]: "error",
    [STATUS_LOADING]: "loading",
    [STATUS_ACTIVE]: "active",
    [STATUS_INACTIVE]: "inactive",
})[status];
const mapStatusLabel = (status) => translate("ui.switcher.entry.label" + status);
const determineDisplayStatus = (status, active) => {
    if (!status || status === STATUS_OK) {
        return active ? STATUS_ACTIVE : STATUS_INACTIVE;
    }
    return status;
};
function SwitcherEntry(props) {
    const { as: T = "li", className = "", baseClassName = "ms3-layer-switcher__entry", // TODO: Use generic class name
    title, count = null, toggleActive, toggleActiveCheckbox: _toggleActiveCheckbox, toggleActiveText: _toggleActiveText, active, activeCheckbox: _activeCheckbox, activeText: _activeText, status, locked = false } = props, attributes = __rest(props, ["as", "className", "baseClassName", "title", "count", "toggleActive", "toggleActiveCheckbox", "toggleActiveText", "active", "activeCheckbox", "activeText", "status", "locked"]);
    // `toggleActive` manages the "split" mode. If `toggleActive` isn't set we switch to the
    // "split" mode, it's enabling split active statuses and toggle handlers for the checkbox and
    // text.
    const isSplit = !toggleActive;
    const activeCheckbox = isSplit ? _activeCheckbox : active;
    const activeText = isSplit ? _activeText : active;
    const toggleActiveCheckbox = isSplit ? _toggleActiveCheckbox : undefined;
    const toggleActiveText = isSplit ? _toggleActiveText : undefined;
    const checkboxDisplayStatus = determineDisplayStatus(status, activeCheckbox);
    const checkboxStatusClass = mapStatusClassName(checkboxDisplayStatus);
    const checkboxStatusLabel = mapStatusLabel(checkboxDisplayStatus);
    const checkbox = (_jsx(SwitcherStatusIcon, { status: checkboxStatusClass, onClick: !toggleActive && toggleActiveCheckbox, active: activeCheckbox, children: checkboxStatusLabel }));
    const text = (_jsxs(Fragment, { children: [_jsx("span", { className: `${baseClassName}__label`, children: title }), !!count && (_jsx("span", { className: `${baseClassName}__count`, "data-ms3-count": count, children: count }))] }));
    return (_jsx(T
    // TODO: remove checkboxStatusClass
    , Object.assign({ 
        // TODO: remove checkboxStatusClass
        className: `${className} [ ${baseClassName} ${baseClassName}--${checkboxStatusClass} ${baseClassName}--${isSplit ? "split" : "joint"} ${locked ? `${baseClassName}--locked` : ""} ]` }, attributes, { children: (() => {
            if (toggleActive) {
                return (_jsxs(SwitcherButton, { status: checkboxStatusClass, toggleActive: toggleActive, active: active, children: [checkbox, text] }));
            }
            else {
                const textDisplayStatus = determineDisplayStatus(status, activeText);
                const textStatusClass = mapStatusClassName(textDisplayStatus);
                const textStatusLabel = mapStatusLabel(textDisplayStatus);
                return (_jsxs(Fragment, { children: [checkbox, _jsx("button", { type: "button", role: "checkbox", onClick: toggleActiveText, className: `${baseClassName}__text-button ${baseClassName}__text-button--${textStatusClass}`, "aria-checked": activeText ? "true" : "false", "aria-label": textStatusLabel, children: text })] }));
            }
        })() })));
}
export default memo(SwitcherEntry);
