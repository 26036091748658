import WithMap from "./WithMap";
export default class WithStyleFunction extends WithMap {
    _styleFunctionRef;
    setStyleFunction(styleFunction) {
        this._styleFunctionRef = styleFunction;
    }
    createStyleFunction(env = {}) {
        return (feature, _resolution) => {
            const map = this.getMap();
            if (!map) {
                console.error("Cannot style feature, because map is not set.");
                return undefined;
            }
            const view = map.getView();
            if (!this._styleFunctionRef) {
                console.error("Cannot style feature, because styleFunction is missing. Set it with mapController.setStyleFunction(fn).");
                return undefined;
            }
            const zoom = view.getZoom();
            return this._styleFunctionRef({
                ...env,
                // TODO: Use store state instead of view object?
                zoom: zoom !== undefined ? Math.round(zoom) : zoom,
                resolution: view.getResolution(),
                rotation: view.getRotation(),
                size: map.getSize(),
            }, feature);
        };
    }
}
