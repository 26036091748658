var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { removeFrom } from "@mapsight/core/lib/base/actions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FEATURE_SELECTIONS } from "../../config/constants/controllers";
import { FEATURE_SELECTION_SELECT } from "../../config/feature/selections";
import { translate } from "../../helpers/i18n";
import { APP_EVENT_FOCUS_FEATURE_LIST, useAppChannelDispatchEvent, } from "../helping/app-channel";
function CloseButton(_a) {
    var { feature, enableKeyboardControl, className = "" } = _a, attrs = __rest(_a, ["feature", "enableKeyboardControl", "className"]);
    const dispatch = useDispatch();
    const removeSelection = useCallback(() => {
        console.log("click close fSI");
        dispatch(removeFrom([FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, "features"], feature === null || feature === void 0 ? void 0 : feature.id));
    }, [dispatch, feature === null || feature === void 0 ? void 0 : feature.id]);
    const appChannelDispatch = useAppChannelDispatchEvent();
    const handleCloseByKeyboard = useCallback(
    /** @param {KeyboardEvent} ev ev */ (ev) => {
        if (enableKeyboardControl) {
            console.log("keyboard close fSI");
            if (ev.key === "Enter" ||
                ev.key === "Spacebar" ||
                ev.key === " ") {
                appChannelDispatch(new Event(APP_EVENT_FOCUS_FEATURE_LIST));
            }
        }
    }, [enableKeyboardControl, appChannelDispatch]);
    return (_jsx("button", Object.assign({ type: "button", className: `ms3-button ms3-feature-selection-info__close-button ${className}`, onClick: removeSelection, onKeyDown: handleCloseByKeyboard }, attrs, { children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.feature-selection-info.close") }) })));
}
export default CloseButton;
