import { ensureNonNullable } from "@neonaut/lib-js/nonNullable";
import { getSourceDataHistory } from "../../../lib/feature-sources/selectors";
function createSourceDataSnapshot(source) {
    return {
        data: source.data || { features: [] },
        lastActionType: source.lastActionType || null,
        lastUpdate: source.lastUpdate || null,
    };
}
export function undoChange(source) {
    const snapshot = createSourceDataSnapshot(source);
    const { past, future } = getSourceDataHistory(source);
    const nextPresent = ensureNonNullable(past[past.length - 1]);
    return {
        dataHistory: {
            past: past.slice(0, past.length - 1),
            future: [snapshot, ...future],
        },
        data: nextPresent.data,
        lastActionType: nextPresent.lastActionType,
        lastUpdate: nextPresent.lastUpdate,
    };
}
export function redoChange(source) {
    const snapshot = createSourceDataSnapshot(source);
    const { past, future } = getSourceDataHistory(source);
    const [nextPresent, ...nextFuture] = future;
    return {
        dataHistory: {
            past: [...past, snapshot],
            future: nextFuture,
        },
        data: nextPresent?.data,
        lastActionType: nextPresent?.lastActionType,
        lastUpdate: nextPresent?.lastUpdate,
    };
}
export function nextDataHistory(featureSourceState) {
    // TODO: Implement history limit
    const oldDataHistory = getSourceDataHistory(featureSourceState);
    return {
        ...oldDataHistory,
        past: [
            ...oldDataHistory.past,
            createSourceDataSnapshot(featureSourceState),
        ],
    };
}
