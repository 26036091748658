import * as loadingStrategy from "ol/loadingstrategy";
import GeoJSONFormat from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import JSONFeature from "ol/format/JSONFeature";
import { getOlFeatureId } from "../../../../lib/helpers/ol";
import { getControllerForLayer } from "../tagLayer";
import FeatureSourceConnector from "./FeatureSourceConnector";
import FeatureSelectionStatesManager from "./FeatureSelectionStatesManager";
import MapAnimationManager from "./MapAnimationManager";
import FeatureClusterManager from "./FeatureClusterManager";
import { updateFeaturesInSource } from "./updateFeaturesInSource";
const FALLBACK_INTERNAL_PROJECTION_CODE = "EPSG:3857";
const featureShouldOverlay = (feature) => !!feature.get("state");
/**
 * @classdesc
 * Extended openlayers vector source that is connected to a mapsight feature source
 */
export default class VectorFeatureSource extends VectorSource {
    _internalProjection;
    _featureSourceConnector;
    _featureSelectionStates;
    _mapAnimator = undefined;
    _featureClusterManager = undefined;
    _mapController = null;
    _featureSelections = [];
    _layer = null;
    _active = false;
    _featureIds = new Set();
    _externalProjection;
    _drawInteraction = null;
    _options;
    constructor(options = {}) {
        options = {
            canAnimate: true,
            canCluster: true,
            useSelectionOverlay: true,
            ...options,
        };
        super({
            strategy: loadingStrategy.all,
            format: new GeoJSONFormat(), // TODO: Support other formats?!
        });
        this._options = options;
        this.setLoader(this._loader.bind(this));
        const stateChangeHandler = this.refresh.bind(this);
        const format = this.getFormat();
        if (!(format instanceof JSONFeature)) {
            throw new Error("Format must be an instance of JSONFeature");
        }
        // mapsight
        this._internalProjection = FALLBACK_INTERNAL_PROJECTION_CODE;
        this._featureSourceConnector = new FeatureSourceConnector({
            format,
            internalProjection: FALLBACK_INTERNAL_PROJECTION_CODE,
            onUpdate: stateChangeHandler,
        });
        this._featureSelectionStates = new FeatureSelectionStatesManager();
        this._featureSelectionStates.addChangeListener(stateChangeHandler);
        // externalised functionality
        if (this._options.canAnimate) {
            this._mapAnimator = new MapAnimationManager(this._featureSourceConnector, this._featureSelectionStates);
        }
        if (this._options.canCluster) {
            this._featureClusterManager = new FeatureClusterManager(this._featureSourceConnector, this._featureSelectionStates);
        }
    }
    _loader(extentToLoad, resolution, projection) {
        // TODO: What to do with extent and resolution
        // TODO: Check why projection is sometimes missing (workaround: hard coded fallback to EPSG:3857);
        const nextProjection = projection?.getCode() ||
            this._internalProjection ||
            FALLBACK_INTERNAL_PROJECTION_CODE;
        this._featureSourceConnector.setInternalProjection(nextProjection);
        this._featureSourceConnector.load();
    }
    /**
     * Refreshes this source
     *
     * @param {boolean} active active
     */
    setActive(active = true) {
        this._active = active;
        this.refresh();
    }
    /** @override */
    loadFeatures(extentToLoad, resolution, projection) {
        this._active = true;
        super.loadFeatures(extentToLoad, resolution, projection);
        if (this._options.canCluster && this._featureClusterManager) {
            this._featureClusterManager.setResolution(resolution);
            if (this._featureClusterManager.needsRefresh()) {
                this.refresh();
            }
        }
    }
    /** @override */
    refresh() {
        if (!this._active) {
            return;
        }
        const layer = this._layer;
        const mapController = this._mapController;
        // remove (all) features from overlay
        if (this._options.useSelectionOverlay && mapController && layer) {
            this.getFeatures().forEach((feature) => {
                mapController.removeFeatureFromOverlay(layer, feature);
            });
        }
        // get, filter, cluster and update features
        let features = this._featureSourceConnector.getFeatures();
        features = this._filter(features);
        // check if we got new features before clustering
        //   or cluster features could yield false positives
        const hasAdded = this._checkNewFeatures(features);
        // apply clustering
        features = this._cluster(features);
        // update source (array of features -> internal ol collection)
        updateFeaturesInSource(this, features);
        // apply selection state
        const hasSelectionStateChanged = this._applySelectionStateToFeatures();
        // maybe animate the map
        if (this._mapAnimator) {
            if (hasAdded) {
                this._mapAnimator.handleNewFeatures();
            }
            if (hasSelectionStateChanged) {
                this._mapAnimator.handleFeatureSelectionStateChange();
            }
        }
        // add features to overlay that have a selection state
        if (this._options.useSelectionOverlay && mapController && layer) {
            this.getFeatures()
                .filter(featureShouldOverlay)
                .forEach((feature) => {
                mapController.moveFeatureToOverlay(layer, feature);
            });
        }
        super.changed();
    }
    _checkNewFeatures(nextFeatures) {
        let hasAdded = false;
        const oldIds = this._featureIds || new Set();
        const newIds = new Set();
        nextFeatures.forEach(function checkNewFeature(nextFeature) {
            const newId = String(nextFeature.getId());
            if (newId) {
                newIds.add(newId);
                if (!oldIds.has(newId)) {
                    hasAdded = true;
                }
            }
        });
        this._featureIds = newIds;
        return hasAdded;
    }
    _cluster(features) {
        if (this._options.canCluster && this._featureClusterManager) {
            const getFilteredFeaturesInExtent = (extent) => {
                const featuresInExtent = this._featureSourceConnector.getFeaturesInExtent(extent);
                return this._filter(featuresInExtent);
            };
            features = this._featureClusterManager.applyClusteringToFeatures(features, getFilteredFeaturesInExtent);
        }
        return features;
    }
    _filter(features) {
        // filter by selections
        if (this._featureSelections?.length) {
            features = features.filter((feature) => {
                const id = getOlFeatureId(feature);
                if (id === undefined) {
                    return false;
                }
                const selectionState = this._featureSelectionStates.get(id);
                if (selectionState === null || selectionState === undefined) {
                    return false;
                }
                return this._featureSelections.includes(selectionState);
            });
        }
        return features;
    }
    _applySelectionStateToFeatures() {
        let hasChanged = false;
        const applySelectionStateToFeature = (feature) => {
            const id = getOlFeatureId(feature);
            if (id === undefined)
                return;
            const previousSelectionState = this._featureSelectionStates.getPrevious(id);
            let selectionState = this._featureSelectionStates.get(id);
            if (!selectionState &&
                feature.get("cluster") &&
                feature.get("clusterFeatures") &&
                this._isClusterHighlighted(feature)) {
                selectionState = "clusterHighlight";
            }
            feature.set("previousState", previousSelectionState, true);
            feature.set("state", selectionState, true);
            if (selectionState !== previousSelectionState) {
                hasChanged = true;
                feature.changed();
            }
        };
        this.getFeatures().forEach(applySelectionStateToFeature);
        return hasChanged;
    }
    _isClusterHighlighted(cluster) {
        let doHighlight = false;
        const features = cluster.get("clusterFeatures");
        for (const feature of features) {
            const featureId = getOlFeatureId(feature);
            if (featureId === undefined)
                continue;
            const state = this._featureSelectionStates.get(featureId);
            if (state) {
                doHighlight = true;
                break;
            }
        }
        return doHighlight;
    }
    addFeature(feature) {
        const format = this.getFormat();
        this._featureSourceConnector.addFeature(format.writeFeatureObject(feature, {
            dataProjection: this._externalProjection,
            featureProjection: this._internalProjection,
        }));
    }
    addFeatures(features) {
        const format = this.getFormat();
        this._featureSourceConnector.addFeatures(format.writeFeaturesObject(features, {
            dataProjection: this._externalProjection,
            featureProjection: this._internalProjection,
        }));
    }
    updateFeature(feature, { quiet = false } = {}) {
        const featureId = getOlFeatureId(feature);
        if (featureId === undefined) {
            console.error("Cannot update feature without id");
            return;
        }
        const format = this.getFormat();
        this._featureSourceConnector.updateFeature(featureId, format.writeFeatureObject(feature, {
            dataProjection: this._externalProjection,
            featureProjection: this._internalProjection,
        }), { quiet: quiet });
    }
    updateFeatures(features, { quiet = false } = {}) {
        if (!features || !Array.isArray(features)) {
            console.error("Cannot update features. Expected array, got: ", features);
            return;
        }
        const format = this.getFormat();
        this._featureSourceConnector.updateFeatures(format.writeFeaturesObject(features, {
            dataProjection: this._externalProjection,
            featureProjection: this._internalProjection,
        }).features, { quiet: quiet });
    }
    updateFeatureGeometry(feature, { quiet = false } = {}) {
        if (!feature) {
            console.error("Cannot update feature. Missing feature.");
            return;
        }
        const id = getOlFeatureId(feature);
        if (id === null || id === undefined) {
            console.error("Cannot update feature. Missing id");
            return;
        }
        const geom = feature.getGeometry();
        if (!geom) {
            console.error("Cannot update feature. Missing geometry");
            return;
        }
        const format = this.getFormat();
        this._featureSourceConnector.updateFeatureGeometry(id, format.writeGeometryObject(geom, {
            dataProjection: this._externalProjection,
            featureProjection: this._internalProjection,
        }), { quiet: quiet });
    }
    updateFeatureGeometries(features, options = {}) {
        features.forEach((feature) => this.updateFeatureGeometry(feature, options));
    }
    clear() {
        this._featureSourceConnector.clear();
    }
    setProjection(projection) {
        this._externalProjection = projection;
        this._featureSourceConnector.setProjection(projection);
    }
    setFeatureSourceId(id) {
        this._featureSourceConnector.setId(id);
    }
    setFeatureSourcesControllerName(featureSourcesControllerName) {
        this._featureSourceConnector.setControllerName(featureSourcesControllerName);
    }
    setFeatureSelectionsControllerName(featureSelectionsControllerName) {
        this._featureSelectionStates.setFeatureSelectionsControllerName(featureSelectionsControllerName);
    }
    setFeatureSelections(featureSelections) {
        this._featureSelections = featureSelections;
    }
    setLayer(layer) {
        this._layer = layer;
        const mapController = getControllerForLayer(layer);
        if (mapController) {
            this.setMapController(mapController);
        }
        if (this._featureClusterManager) {
            this._featureClusterManager.setLayer(layer);
        }
        this.setActive(true);
    }
    setDrawInteraction(drawInteraction) {
        this._drawInteraction = drawInteraction;
    }
    setMapController(mapController) {
        const store = mapController.getStore();
        if (!store) {
            console.error("Cannot set map controller without store");
            return;
        }
        this._mapController = mapController;
        this._featureSourceConnector.setStore(store);
        this._featureSelectionStates.bindToStore(store);
        this._featureSourceConnector.setTargetControllerName(mapController.getName());
        if (this._mapAnimator) {
            this._mapAnimator.setMapController(mapController);
        }
    }
    setKeepFeaturesInViewSelections(selections) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("keepFeaturesInViewSelections", selections);
        }
    }
    setKeepFeaturesInViewOptions(options) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("keepFeaturesInViewOptions", options);
        }
    }
    setKeepAllFeaturesInView(value) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("keepAllFeaturesInView", value);
        }
    }
    setFitFeaturesInViewSelections(selections) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("fitFeaturesInViewSelections", selections);
        }
    }
    setFitFeaturesInViewOptions(options) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("fitFeaturesInViewOptions", options);
        }
    }
    setFitAllFeaturesInView(value) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("fitAllFeaturesInView", value);
        }
    }
    setCenterFeaturesInViewSelections(selections) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("centerFeaturesInViewSelections", selections);
        }
    }
    setCenterFeaturesInViewOptions(options) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("centerFeaturesInViewOptions", options);
        }
    }
    setCenterAllFeaturesInView(value) {
        if (this._mapAnimator) {
            this._mapAnimator.setOption("centerAllFeaturesInView", value);
        }
    }
    setClusterFeatures(value) {
        if (this._featureClusterManager) {
            this._featureClusterManager.setActive(value);
        }
    }
    setClusterFeaturesOptions(options) {
        if (this._featureClusterManager) {
            this._featureClusterManager.setOptions(options);
        }
    }
}
