import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import ReactModal from "react-modal";
import getFeatureProperty from "../../helpers/get-feature-property";
import { translate } from "../../helpers/i18n";
const selectInputContent = (e) => e.target.setSelectionRange(0, e.target.value.length);
/* eslint-disable jsx-a11y/label-has-for */
/* NOTE: we use a wrapping label around the input element which is fine a11y as well. */
function ShareFeatureLinkModal({ isOpen, onRequestClose, feature }) {
    return (_jsx(ReactModal, { isOpen: isOpen, 
        // contentLabel="onRequestClose Example" // aria-label="" on .ms3-modal
        onRequestClose: onRequestClose, className: "ms3-modal", overlayClassName: "ms3-app-overlay", shouldCloseOnOverlayClick: true, children: _jsxs("div", { className: "ms3-modal__inner", children: [_jsxs("div", { className: "ms3-share-link", children: [_jsx("h3", { className: "ms3-share-link__head", children: translate("ui.feature-details.share-link.head") }), _jsxs("label", { className: "ms3-share-link__label", children: [translate("ui.feature-details.share-link.place"), _jsx("br", {}), _jsx("input", { className: "ms3-share-link__input", value: getFeatureProperty(feature, "permanentLink"), onClick: selectInputContent, readOnly: true })] })] }), _jsx("button", { className: "ms3-dialog-close-button", type: "button", onClick: onRequestClose, children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.feature-details.share-link.close") }) })] }) }));
}
export default memo(ShareFeatureLinkModal);
