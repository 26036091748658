import KeyboardZoom from "ol/interaction/KeyboardZoom";
import base from "./_base";
export default {
    type: "KeyboardZoomInteraction",
    Constructor: KeyboardZoom,
    optionMap: {
        ...base.optionMap,
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        condition: "condition",
        duration: "duration",
        delta: "delta",
    },
};
