var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef } from "react";
import usePrevious from "../../hooks/usePrevious";
function useAutofocus(enabled, ref, feature) {
    const previousFeature = usePrevious(feature);
    const previousRef = usePrevious(ref.current);
    useEffect(() => {
        if (!enabled || !feature || !ref.current) {
            return;
        }
        // Autofocus on mount or if feature is selected
        if (feature &&
            (ref.current !== previousRef || feature !== previousFeature)) {
            //ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            ref.current.focus({ preventScroll: true });
        }
    }, [enabled, feature, previousFeature, previousRef, ref]);
}
const Wrapper = forwardRef(function Wrapper(_a, forwardedRef) {
    var { enableKeyboardControl, feature, className = "" } = _a, attrs = __rest(_a, ["enableKeyboardControl", "feature", "className"]);
    const ownRef = useRef();
    const ref = forwardedRef || ownRef;
    useAutofocus(enableKeyboardControl, ref, feature);
    return (_jsx("div", Object.assign({ ref: ref, className: `ms3-feature-selection-info__wrapper ms3-scroll-target ${className}`, tabIndex: enableKeyboardControl ? -1 : undefined }, attrs)));
});
export default Wrapper;
