var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useRef } from "react";
import { useStore } from "react-redux";
import { MAP } from "../config/constants/controllers";
import { translate } from "../helpers/i18n";
import useMountable from "../hooks/useMountable";
function MapsightMap(_a) {
    var { controllerName = MAP, enableKeyboardControl = true } = _a, attributes = __rest(_a, ["controllerName", "enableKeyboardControl"]);
    const store = useStore();
    const ref = useRef();
    const handleMount = useMountable(store.getController(controllerName));
    useEffect(() => {
        handleMount(ref.current);
    }, [ref, handleMount]);
    if (enableKeyboardControl) {
        attributes.tabIndex = 0;
    }
    return (_jsxs("div", Object.assign({ className: "ms3-map-target", ref: ref }, attributes, { children: [_jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.map.visuallyhidden") }), _jsx("div", { className: "ol-viewport", children: _jsx("canvas", { className: "ol-unselectable" }) })] })));
}
export default memo(MapsightMap);
