import { set, setAll, unset, withPath } from "../../lib/base/actions";
export const UPDATE_SIZE = "MAPSIGHT_MAP_UPDATE_SIZE";
export const ANIMATE = "MAPSIGHT_MAP_ANIMATE";
export const FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT = "MAPSIGHT_MAP_FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT";
export const FIT_MAP_VIEW_TO_LAYER_FEATURE = "MAPSIGHT_MAP_FIT_MAP_VIEW_TO_LAYER_FEATURE";
export const setViewportAnchor = (controllerName, viewportAnchor) => set([controllerName, "viewportAnchor"], viewportAnchor);
export const updateMapSize = (controllerName, options = {}) => withPath({
    type: UPDATE_SIZE,
    from: options.from ?? null,
    to: options.to ?? null,
    reCenter: options.reCenter ?? true,
}, [controllerName]);
export const animate = (controllerName, options) => withPath({
    type: ANIMATE,
    options: options,
}, [controllerName]);
export const fitMapViewToLayerSourceExtent = (controllerName, layerId, options) => withPath({
    type: FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT,
    options: options,
}, [controllerName, "layers", layerId]);
export const fitMapViewToLayerFeature = (controllerName, layerId, featureId, options) => withPath({
    type: FIT_MAP_VIEW_TO_LAYER_FEATURE,
    featureId: featureId,
    options: options,
}, [controllerName, "layers", layerId]);
export const setViewZoomAndResolution = (controllerName, zoom, resolution) => setAll([controllerName, "view"], {
    zoom: Math.round(zoom),
    resolution: resolution,
});
export const setMapSizeAfterUpdate = (controllerName, size) => setAll([controllerName], {
    size,
    pendingUpdateSize: false,
});
export const setViewCenter = (controllerName, viewCenter) => set([controllerName, "view", "center"], viewCenter);
export const setViewRotation = (controllerName, viewRotation) => set([controllerName, "view", "rotation"], viewRotation);
export const setViewMaxResolution = (controllerName, maxResolution) => set([controllerName, "view", "maxResolution"], maxResolution);
export const setViewMinResolution = (controllerName, minResolution) => set([controllerName, "view", "minResolution"], minResolution);
export const setLayerVisibility = (controllerName, layer, visibility) => set([controllerName, "layers", layer, "options", "visible"], visibility);
export const setLayerStyle = (controllerName, layer, style) => set([controllerName, "layers", layer, "options", "style"], style);
export const setMapCursor = (controllerName, cursorName) => set([controllerName, "cursor"], cursorName);
export const setInteractionStatus = (controllerName, interactionName, status) => set([controllerName, "interactions", interactionName, "options", "active"], status);
export const activateInteraction = (controllerName, interactionName) => setInteractionStatus(controllerName, interactionName, true);
export const deactivateInteraction = (controllerName, interactionName) => setInteractionStatus(controllerName, interactionName, false);
export const addInteractionSelection = (controllerName, layerName, interaction, selectionId) => set([
    controllerName,
    "layers",
    layerName,
    "options",
    "selections",
    interaction,
], selectionId);
export const removeInteractionSelection = (controllerName, layerName, interaction) => unset([
    controllerName,
    "layers",
    layerName,
    "options",
    "selections",
    interaction,
]);
export const setInteractionSelections = (controllerName, layerName, interactionSelections) => set([controllerName, "layers", layerName, "options", "selections"], interactionSelections);
