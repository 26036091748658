import OlVectorSource from "ol/source/Vector";
import Collection from "ol/Collection";
import { createDependencyMapper, di, OPTION_COLLECTION, OPTION_SET, } from "../../../ol-proxy";
import base from "./_base";
class VectorSource extends OlVectorSource {
    constructor(options) {
        super({
            // NOTE: We pass an empty Collection to make sure
            // we have getFeaturesCollection() available to
            // be able to replace features dynamically:
            features: new Collection(),
            ...options,
        });
    }
}
export default {
    type: "VectorSource",
    Constructor: VectorSource,
    optionMap: {
        ...base.optionMap,
        logo: OPTION_SET,
        overlaps: OPTION_SET,
        strategy: OPTION_SET,
        useSpatialIndex: OPTION_SET,
        wrapX: OPTION_SET,
        features: [OPTION_COLLECTION, "getFeaturesCollection"],
    },
    initialOptionMap: {
        ...base.initialOptionMap,
        logo: "logo",
        overlaps: "overlaps",
        strategy: "strategy",
        useSpatialIndex: "useSpatialIndex",
        wrapX: "wrapX",
        url: "url",
        loader: "loader",
        format: createDependencyMapper(di, "format"), // TODO: features
    },
};
