import forEach from "lodash/forEach";
import { di, updateProxyObject } from "../../../ol-proxy";
import proxyPassOpenLayersEventsToMapController from "./proxyPassOpenLayersEventsToMapController";
import WithMap from "./WithMap";
export default class WithInteractions extends WithMap {
    init() {
        const map = this.getMap();
        if (!map) {
            console.error("Could not initialize WithInteractions mixin. Map is not defined.");
            return;
        }
        const interactions = {};
        let oldDefinitions = {};
        this.getAndObserveUncontrolled((state) => state.interactions, (newDefinitions = {}) => {
            forEach(newDefinitions, (newDefinition, id) => updateProxyObject({
                di: di,
                oldObject: interactions[id],
                oldDefinition: oldDefinitions && oldDefinitions[id],
                newDefinition: newDefinition,
                remover: () => {
                    const interaction = interactions[id];
                    if (interaction !== undefined) {
                        map.removeInteraction(interaction);
                    }
                    delete interactions[id];
                },
                adder: (object) => {
                    interactions[id] = object;
                    map.addInteraction(object);
                    proxyPassOpenLayersEventsToMapController(this, object, newDefinition.type, id);
                },
                parentObject: this,
            }));
            oldDefinitions = newDefinitions;
        });
    }
}
