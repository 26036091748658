import { async } from "@mapsight/core/lib/base/actions";
import { updateMapSize } from "@mapsight/core/lib/map/actions";
import { useCallback } from "react";
import { MAP } from "../config/constants/controllers";
export default function useUpdateMapSizeCallback(
// TODO: use hook
dispatch, 
// TODO: why is this a ref?
positionRef, reCenter = true) {
    return useCallback(function updateMapSizeCallback() {
        dispatch(async(updateMapSize(MAP, {
            from: positionRef === null || positionRef === void 0 ? void 0 : positionRef.current,
            reCenter,
        })));
    }, [dispatch, positionRef, reCenter]);
}
