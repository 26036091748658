export { TIME_FILTER, TAG_FILTER } from "../constants/controllers";
/**
 * Create an empty feature source that can be filled using actions
 * and cannot not load features itself.
 *
 * @returns config for a plain feature source
 */
export function plain() {
    return {
        type: "noop",
    };
}
/**
 * Create a feature source that loads data from a remote url.
 *
 * @param url url to fetch data from
 * @returns config for a xhr json feature source
 */
export function xhrJson(url) {
    return {
        type: "xhr-json",
        url: url,
    };
}
/**
 * Create a feature source that fetches data from a remote url.
 *
 * Additionally, it will fetch the data every {timer} milliseconds.
 *
 * @param url url to fetch data from
 * @param timer time in milliseconds to fetch new data
 * @returns config for a xhr json feature source that will refresh regularly
 */
export function xhrJsonRefreshing(url, timer) {
    return {
        type: "xhr-json",
        url: url,
        doRefresh: true,
        timer: timer,
    };
}
/**
 * add a filter to the definition of a feature source.
 *
 * may be used iteratively.
 *
 * @param featureSource     definition to extend by filterName
 * @param filterName        name of filter to add to the filter collection.
 *                                   The feature filter will be applied when using the appropriate feature source selectors.
 * @returns               extended definition of feature source
 */
export function withFilter(featureSource, filterName) {
    return Object.assign(Object.assign({}, featureSource), { filters: [
            ... // @ts-expect-error
            (featureSource.filters || []),
            filterName,
        ] });
}
