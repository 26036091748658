import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { visibleLayerAttributionsSelector } from "@mapsight/core/lib/map/selectors";
import { MAP } from "../../config/constants/controllers";
import AttributionEntries from "./attribution-entries";
function selectVisibleLayerAttributions(state) {
    return visibleLayerAttributionsSelector(state[MAP]);
}
function Attribution({ children }) {
    const attributions = useSelector(selectVisibleLayerAttributions);
    return (_jsx(AttributionEntries, { attributions: attributions, children: children }));
}
export default Attribution;
