import setDocumentScroll from "@neonaut/lib-js/es/dom/access/set-document-scroll";
import { useEffect } from "react";
import { isViewFullscreen, isViewMobile } from "../../../store/selectors";
export default function useRestoreDocumentScroll(view, scrollPosition) {
    useEffect(() => {
        if ((isViewMobile(view) || isViewFullscreen(view)) && scrollPosition) {
            setDocumentScroll(scrollPosition);
        }
    }, [view, scrollPosition]);
}
