import { applyMiddleware, combineReducers, compose, createStore as reduxCreateStore, } from "redux";
import { batchDispatchMiddleware } from "redux-batched-actions";
import forEach from "lodash/forEach";
import mapValues from "lodash/mapValues";
import { ensureNonNullable, isNonNullable } from "@neonaut/lib-js/nonNullable";
import createPrefixedAsyncActionMiddleware from "@mapsight/lib-redux/create-prefixed-async-action-middleware";
import enableAsyncDispatch from "@mapsight/lib-redux/enable-async-dispatch";
import enableControlledDispatchAndObserve from "@mapsight/lib-redux/enable-controlled-dispatch-and-observe";
import createFilteredReducerForPath from "@mapsight/lib-redux/create-filtered-reducer-for-path";
import { ASYNC_ACTION_FLAG, CONTROLLED_ACTION_FLAG, STATE_PATH_KEY, } from "./lib/base/actions";
import defaultSanitizeAction from "./redux-devtools/defaultSanitizeAction";
import defaultPredicate from "./redux-devtools/defaultPredicate";
/**
 * Creates an enhanced redux store, which contains all the state of a mapsight application.
 *
 * @param controllers map of controller instances
 * @param appReducers optional map of key => Reducer to add
 * @param preLoadedState optional plain object pre-loaded state to be merged
 * @param appEnhancer optional Enhancer to compose
 * @param options additional options for the store creation, see below:
 * @returns created, enhanced, store
 */
export function createMapsightStore(controllers, appReducers = {}, preLoadedState = {}, appEnhancer = null, options = {}) {
    const { reduxDevToolsOptions = {} } = options;
    // Get reducers from controllers bound to the controller
    const coreReducers = mapValues(controllers, (controller, key) => createFilteredReducerForPath((state, action) => controller.reduce(state, action), key, STATE_PATH_KEY));
    const reducer = combineReducers({
        ...coreReducers,
        ...appReducers,
    });
    const coreEnhancer = ((createStore) => (baseReducer, preloadedState) => {
        const store = createStore(baseReducer, preloadedState);
        enableAsyncDispatch(store, ASYNC_ACTION_FLAG);
        enableControlledDispatchAndObserve(store, CONTROLLED_ACTION_FLAG);
        store.getController = (name) => ensureNonNullable(controllers[name]);
        return store;
    });
    const coreMiddlewareEnhancer = applyMiddleware(createPrefixedAsyncActionMiddleware(ASYNC_ACTION_FLAG), batchDispatchMiddleware);
    const enhancers = [
        coreEnhancer,
        appEnhancer,
        coreMiddlewareEnhancer,
    ].filter(isNonNullable);
    let enhancer;
    // Enable redux dev tools
    if (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            actionSanitizer: defaultSanitizeAction,
            predicate: defaultPredicate,
            ...reduxDevToolsOptions,
        });
        enhancer = devCompose(...enhancers);
    }
    else {
        enhancer = compose(...enhancers);
    }
    const store = reduxCreateStore(reducer, preLoadedState, enhancer);
    // bind controllers to store
    forEach(controllers, (controller) => controller.bindToStore(store));
    // after all are bound, init each
    forEach(controllers, (controller) => controller.init());
    return store;
}
