import { createFeatureSelectionSelector, getFilteredFeatures, } from "@mapsight/core/lib/feature-selections/selectors";
import { createTagsWithCountFromFeatureSourceSelector, findFeatureInFeatureSourcesById, mapFeaturesToFeatureSource, } from "@mapsight/core/lib/feature-sources/selectors";
import { createListFeatureSelector, featureSourceIdSelector, } from "@mapsight/core/lib/list/selectors";
import { visibleLayersWithMiniLegendsSelector } from "@mapsight/core/lib/map/selectors";
import getPath from "@neonaut/lib-js/es/object/getPath";
import { createSelector, createStructuredSelector } from "reselect";
import { DETAILS_CONTENT_STATE_KEY, VIEW_FULLSCREEN, VIEW_MAP_ONLY, VIEW_MOBILE, } from "../config/constants/app";
import { FEATURE_LIST, FEATURE_SELECTIONS, FEATURE_SOURCES, MAP, TAG_FILTER, USER_GEOLOCATION, } from "../config/constants/controllers";
import { FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT, } from "../config/feature/selections";
import { FETCH_JSON_STATUS_ERROR, FETCH_JSON_STATUS_LOADING, FETCH_JSON_STATUS_SUCCESS, FETCH_TEXT_STATUS_ERROR, FETCH_TEXT_STATUS_SUCCESS, } from "./actions";
export const SEARCH_STATUS_INACTIVE = "inactive";
export const SEARCH_STATUS_EMPTY = "empty";
export const SEARCH_STATUS_FOUND = "found";
export const SEARCH_STATUS_LOADING = "loading";
export const SEARCH_STATUS_ERROR = "error";
/**
 * @param {object} state state
 * @returns {import('../config/constants/app').View} view
 */
export const viewSelector = (state) => state.app.view;
export const isViewFullscreen = (view) => view === VIEW_FULLSCREEN;
/**
 * returns `true` if view is "mobile" or "mapOnly"
 *
 * @param {import('../config/constants/app').View} view view
 * @returns {boolean} is mobile?
 */
export const isViewMobile = (view) => view === VIEW_MOBILE || view === VIEW_MAP_ONLY;
/**
 * @param {import('../config/constants/app').View} view view
 * @returns {boolean} is mapOnly?
 */
export const isViewMapOnly = (view) => view === VIEW_MAP_ONLY;
export const isViewMobileOrMapOnlySelector = createSelector(viewSelector, isViewMobile);
export const isViewMapOnlySelector = createSelector(viewSelector, isViewMapOnly);
export const isFullscreenSelector = createSelector(viewSelector, isViewFullscreen);
export const mapVisible = (state) => { var _a; return (_a = state.app.map) === null || _a === void 0 ? void 0 : _a.show; };
export const listVisible = (state) => { var _a; return (_a = state.app.list) === null || _a === void 0 ? void 0 : _a.show; };
export const timeFilterVisible = (state) => { var _a; return (_a = state.app.timeFilter) === null || _a === void 0 ? void 0 : _a.show; };
export const mapAndListVisible = createSelector([mapVisible, listVisible], (map, list) => map && list);
export const viewBreakpointsSelector = (state) => state.app.viewBreakpoints;
export const titleSelector = (state) => state.app.title;
export const isMapOutOfViewportSelector = (state) => state.app.mapIsOutOfViewport;
/**
 * @param {any} state state
 * @returns {null | Record<string, any>} regions
 */
export const regionsSelector = (state) => { var _a; return ((_a = state.app) === null || _a === void 0 ? void 0 : _a.regions) || null; };
export const placesSelector = (state) => state.app.places;
export const lastListScrollPositionSelector = (state) => state.app.lastListScrollPosition;
export const listSortingSelector = (state) => state.app.listSorting;
export const listQuerySelector = (state) => state.app.listQuery;
export const listPageSelector = (state) => state.app.listPage || 0;
export const listFilterOptionsSelector = createStructuredSelector({
    query: listQuerySelector,
    sorting: listSortingSelector,
    places: createSelector([(state) => state[USER_GEOLOCATION], placesSelector], (userGeolocation, places) => (Object.assign(Object.assign({}, places), { geolocation: {
            x: userGeolocation.longitude,
            y: userGeolocation.latitude,
        } }))),
});
export const searchQuerySelector = (state) => state.app.searchQuery;
export const searchResultSelector = (state) => getPath(state, ["app", "searchResult"]);
export const getSearchResultStatus = (searchResult) => searchResult === null || searchResult === void 0 ? void 0 : searchResult.status;
export const getSearchResultFeatures = (searchResult) => getPath(searchResult, ["data", "features"], []);
export const searchResultFeaturesSelector = createSelector(searchResultSelector, getSearchResultFeatures);
export const haveSearchInMapSelector = (state) => state.app.searchInMap;
export const isEmbeddedMapSelector = (state) => state.app.embeddedMap;
export const listUiOptionsSelector = (state) => { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.list) || {}; };
export const listUiOptionDetailsSelector = (state) => state.app.list.detailsInList;
export const listUiOptionIntegratedSelector = (state) => state.app.list.integratedList;
export const listUiOptionSelectedOnlySelector = (state) => state.app.list.showSelectedOnly;
export const listUiOptionSelectionBehaviorSelector = createSelector(listUiOptionsSelector, (list) => list.selectionBehavior || {});
export const listUiScrollToItemOnPreselectSelector = createSelector(listUiOptionsSelector, (list) => list.scrollToItemOnPreselect === true);
export const listUiOptionSelectionSelectionSelector = (state) => state.app.list.selectionBehaviorSelection || FEATURE_SELECTION_SELECT;
export const listUiOptionsShowVaryingInfoOnlySelector = (state) => state.app.list.showVaryingListInfoOnly;
export const layerSwitcherShowInternalSelector = (state) => state.app.layerSwitcher.show.internal;
export const layerSwitcherShowExternalSelector = (state) => state.app.layerSwitcher.show.external;
export const layerSwitcherConfigInternalSelector = (state) => state.app.layerSwitcher.internal;
export const layerSwitcherConfigExternalSelector = (state) => state.app.layerSwitcher.external;
export const pageTitleShowSelector = (state) => state.app.pageTitle.show;
export const tagSwitcherShowSelector = (state) => state.app.tagSwitcher.show;
export const tagSwitcherToggleableGroups = (state) => state.app.tagSwitcher.toggleableGroups;
export const tagSwitcherSortTags = (state) => state.app.tagSwitcher.sortTags;
export const tagSwitcherFeatureSourceIdSelector = (state) => state.app.tagSwitcher.featureSourceId;
export const tagSwitcherFeatureSourcesControllerNameSelector = (state) => state.app.tagSwitcher.featureSourcesControllerName;
export const tagSwitcherTagsSelector = (state) => {
    const controllerName = tagSwitcherFeatureSourcesControllerNameSelector(state);
    const featureSourceId = tagSwitcherFeatureSourceIdSelector(state);
    return createTagsWithCountFromFeatureSourceSelector(controllerName, featureSourceId)(state);
};
export const viewToggleShowSelector = (state) => state.app.viewToggle.show;
export const viewToggleOptionsSelector = (state) => state.app.viewToggle;
export const searchStatusSelector = createSelector(searchResultSelector, searchQuerySelector, createSelector(getSearchResultStatus, getSearchResultFeatures, (_, query) => !!query, (status, features, hasQuery) => ({
    [FETCH_JSON_STATUS_ERROR]: SEARCH_STATUS_ERROR,
    [FETCH_JSON_STATUS_LOADING]: SEARCH_STATUS_LOADING,
    [FETCH_JSON_STATUS_SUCCESS]: hasQuery
        ? features.length
            ? SEARCH_STATUS_FOUND
            : SEARCH_STATUS_EMPTY
        : SEARCH_STATUS_INACTIVE,
})[status] || SEARCH_STATUS_INACTIVE));
export const userPreferenceListVisibleSelector = (state) => state.app.userPreferenceListVisible;
export const searchResultSelectionFeaturesSelector = (state) => state.app.searchResultSelectionFeatures;
export const searchResultSelectionFeatureSourceSelector = createSelector(searchResultSelectionFeaturesSelector, mapFeaturesToFeatureSource);
export const featureDetailsSelector = (state) => state.app[DETAILS_CONTENT_STATE_KEY];
export const featureDetailsUrlSelector = (state) => { var _a; return (_a = state.app[DETAILS_CONTENT_STATE_KEY]) === null || _a === void 0 ? void 0 : _a.url; };
export const featureDetailsHasErrorSelector = createSelector(featureDetailsSelector, (detailsContent) => detailsContent && detailsContent.status === FETCH_TEXT_STATUS_ERROR);
export const featureDetailsHtmlSelector = createSelector(featureDetailsSelector, (detailsContent) => detailsContent && detailsContent.status === FETCH_TEXT_STATUS_SUCCESS
    ? detailsContent.data
    : null);
export const createTagVisibleSelector = (featureSourceId, tagGroup, tag) => (state) => !!getPath(state, [
    TAG_FILTER,
    "visibleTags",
    featureSourceId,
    tagGroup,
    tag,
]);
export const createTagGroupVisibleSelector = (featureSourceId, tagGroup) => (state) => !!getPath(state, [
    TAG_FILTER,
    "visibleTagGroups",
    featureSourceId,
    tagGroup,
]);
export const isOverlayModalVisibleSelector = (state) => state.app.isOverlayModalVisible === true;
export const selectedRegionIdSelector = (state) => state.app.selectedRegion || null;
export const selectedRegionSelector = createSelector(selectedRegionIdSelector, regionsSelector, (id, regions) => (regions && regions[id]) || null);
export const miniLegendLayerIdSelector = createSelector((state) => visibleLayersWithMiniLegendsSelector(state[MAP]), (state) => state.app.miniLegendLayer, (visibleLayers, layerId) => {
    // no layers visible => return early
    if (!visibleLayers) {
        return null;
    }
    const visibleLayerIds = Object.keys(visibleLayers);
    // layer is defined and visible?
    if (layerId && visibleLayerIds.includes(layerId)) {
        return layerId;
    }
    // otherwise, default to first visible layer
    return visibleLayerIds[0] || null;
});
/**
 * @params {object} state state
 * @returns {import('../types').MapsightUiFeature | null} feature
 */
export const selectedFeatureSelector = createSelector((state) => state[FEATURE_SELECTIONS][FEATURE_SELECTION_SELECT], (state) => state[FEATURE_SOURCES], function getFeature(featureSelection, featureSources) {
    const features = getFilteredFeatures(featureSelection);
    const featureId = features && features[0];
    return featureId
        ? findFeatureInFeatureSourcesById(featureSources, featureId)
        : null;
});
/**
 * @param {Pick<import('../types').MainPanelContextOptions, 'showSelectionInfo'| 'showList' | 'collapsible'>} options options
 * @returns {(state: object) => import('../types').MainPanelContextState} selector
 */
export const createMainPanelContentTypeSelector = (options) => createSelector(selectedFeatureSelector, userPreferenceListVisibleSelector, 
/**
 * @param {import('../types').MapsightUiFeature | null} feature feature
 * @param {boolean} userPreferenceListVisible user preference list visible
 * @returns {import('../types').MainPanelContextState} state
 */
function getMainPanelState(feature, userPreferenceListVisible) {
    const { showSelectionInfo, showList, collapsible } = options;
    /** @type {import('../types').MainPanelContentType} */
    let contentType = null;
    if (showSelectionInfo && feature) {
        contentType = "selectionInfo";
    }
    else if (showList) {
        contentType = "list";
    }
    return {
        feature: feature,
        contentType: contentType,
        collapsed: contentType === null ||
            (contentType === "list" &&
                collapsible &&
                !userPreferenceListVisible),
    };
});
export function createSelectionIndexSelector(featureIds, targetSelection) {
    return createSelector((state) => { var _a; return (_a = state[FEATURE_SELECTIONS][targetSelection]) === null || _a === void 0 ? void 0 : _a.features; }, (selection) => (featureIds === null || featureIds === void 0 ? void 0 : featureIds.length) && (selection === null || selection === void 0 ? void 0 : selection.length)
        ? featureIds.indexOf(selection[0])
        : -1);
}
export const featureSelectionInfoUiOptionsSelector = (state) => state.app.featureSelectionInfo || {};
export const featureSelectionInfoStickyHeaderSelector = createSelector(featureSelectionInfoUiOptionsSelector, ({ stickyHeader }) => stickyHeader);
export const featureSelectionInfoStuckHeaderHeightSelector = createSelector(featureSelectionInfoUiOptionsSelector, ({ stuckHeaderHeight }) => stuckHeaderHeight);
const selectSelectionSelector = createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT);
const preselectSelectionSelector = createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT);
const highlightSelectionSelector = createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT);
export const featureListContextSelector = createStructuredSelector({
    tagSwitcherShow: tagSwitcherShowSelector,
    layerSwitcherShowExternal: layerSwitcherShowExternalSelector,
    scrollPosition: lastListScrollPositionSelector,
    selectSelection: selectSelectionSelector,
    preselectSelection: preselectSelectionSelector,
    highlightSelection: highlightSelectionSelector,
});
// TODO: type out feature source state (probably in @mapsight/core)
/**
 * @param {string} [listControllerName=FEATURE_LIST] listControllerName
 * @returns {(state: object) => {featureSourceId: string, featureSource: object}} state
 */
export const createFeatureSourceSelector = (listControllerName = FEATURE_LIST) => createStructuredSelector({
    featureSourceId: createSelector((state) => state[listControllerName], featureSourceIdSelector),
    featureSource: createListFeatureSelector(listControllerName, FEATURE_SOURCES),
});
