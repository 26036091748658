export default function createPrefixedAsyncActionMiddleware(actionFlag = "isAsync", prefix, extraArgument = undefined) {
    return function prefixedAsyncActionMiddleware(api) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        let getState = api.getState;
        if (typeof prefix === "string") {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const baseGetState = api.getState;
            getState = function getStateWithPrefix() {
                return baseGetState()[prefix];
            };
        }
        return function (next) {
            return function prefixedAsyncActionMiddlewareWithAction(action) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (typeof action === "function" && action.meta?.[actionFlag]) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
                    return action(api.dispatch, getState, extraArgument);
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return next(action);
            };
        };
    };
}
