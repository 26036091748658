import { useEffect, useState } from "react";
/**
 * Mounts the mountable object to the element
 *
 * @param mountable mountable object
 * @returns callback to set target
 */
export default function useMountable(mountable) {
    const [target, setTarget] = useState();
    useEffect(() => {
        if (!mountable) {
            console.error("mount failed, no mountable");
            return;
        }
        if (!target) {
            console.error("mount failed, no target");
            return;
        }
        mountable.mount(target);
        return () => {
            mountable.unmount(target);
        };
    }, [target, mountable]);
    return setTarget;
}
