import { getOlFeatureId } from "../../../../lib/helpers/ol";
import FeatureSelectionConnector from "./FeatureSelectionConnector";
export default class FeatureSelectionStatesManager {
    _store;
    _featureSelectionsControllerName;
    _connector;
    _changeHandler;
    _changeListeners;
    constructor() {
        this._store = null;
        this._featureSelectionsControllerName = null;
        this._connector = null;
        this._changeHandler = this._handleChange.bind(this);
        this._changeListeners = [];
    }
    addChangeListener(listener) {
        this._changeListeners.push(listener);
    }
    removeChangeListener(listener) {
        this._changeListeners = this._changeListeners.filter((fn) => fn !== listener);
    }
    get(featureId) {
        return this._connector && this._connector.get(featureId);
    }
    getPrevious(featureId) {
        return this._connector && this._connector.getPrevious(featureId);
    }
    getAllFeaturesWithState(state) {
        return this._connector?.getAllFeaturesWithState(state);
    }
    select(state, featureId) {
        if (this._connector) {
            this._connector.select(state, featureId);
        }
    }
    deselect(state, featureId) {
        if (this._connector) {
            this._connector.deselect(state, featureId);
        }
    }
    filterFeaturesByActiveSelections(features, selections) {
        return features.filter((feature) => {
            const featureId = getOlFeatureId(feature);
            if (featureId === undefined) {
                return false;
            }
            const selection = this.get(featureId);
            if (selection === null || selection === undefined) {
                return false;
            }
            return selections.indexOf(selection) > -1;
        });
    }
    filterChangedFeatures(features) {
        return features.filter((feature) => {
            const featureId = getOlFeatureId(feature);
            return featureId === undefined ? false : this._isChanged(featureId);
        });
    }
    setFeatureSelectionsControllerName(featureSelectionsControllerName) {
        this._featureSelectionsControllerName = featureSelectionsControllerName;
        this._updateConnector();
    }
    bindToStore(store) {
        this._store = store;
        this._updateConnector();
    }
    _isChanged(featureId) {
        return this.get(featureId) !== this.getPrevious(featureId);
    }
    _updateConnector() {
        if (this._connector) {
            this._connector.unsubscribe(this._changeHandler);
        }
        const featureSelectionsController = this._featureSelectionsControllerName &&
            this._store?.getController(this._featureSelectionsControllerName);
        if (featureSelectionsController) {
            this._connector = FeatureSelectionConnector.getInstance(featureSelectionsController);
            this._connector.subscribe(this._changeHandler);
        }
        else {
            this._connector = null;
        }
    }
    _handleChange() {
        this._changeListeners.forEach((listener) => {
            listener();
        });
    }
}
