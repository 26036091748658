import * as olProj4 from "ol/proj/proj4";
import { BaseController } from "../base/controller";
import { ProjectionsController } from "../projections/controller";
import WithMap from "./lib/WithMap";
import WithMounting from "./lib/WithMounting";
import WithAnimations from "./lib/WithAnimations";
import WithLayerOverlays from "./lib/WithLayerOverlays";
import WithClusterFeatureFunction from "./lib/WithClusterFeatureFunction";
import WithControls from "./lib/WithControls";
import WithCursor from "./lib/WithCursor";
import WithFeatureInteractions from "./lib/WithFeatureInteractions";
import WithSize from "./lib/WithSize";
import WithStyleFunction from "./lib/WithStyleFunction";
import WithVisibleLayers from "./lib/WithVisibleLayers";
import WithLayers from "./lib/WithLayers";
import WithInteractions from "./lib/WithInteractions";
export { DEFAULT_CURSOR } from "./lib/WithCursor";
export const ANIMATE_ONCE = "once";
class BaseMapController extends BaseController {
    init() {
        olProj4.register(ProjectionsController.getProj4());
    }
}
export class MapController extends BaseMapController {
}
const initFunctions = [];
for (const mixin of [
    WithClusterFeatureFunction,
    WithMap,
    WithVisibleLayers,
    WithStyleFunction,
    WithCursor,
    WithControls,
    WithMounting,
    WithAnimations,
    WithLayers,
    WithLayerOverlays,
    WithSize,
    WithInteractions,
    WithFeatureInteractions,
    //WithAnchoredViewport,
]) {
    for (const name of Object.getOwnPropertyNames(mixin.prototype)) {
        if (name === "init") {
            initFunctions.push(mixin.prototype[name]);
        }
        else if (name === "constructor") {
            // ignore
        }
        else if (Object.hasOwn(MapController.prototype, name)) {
            console.info(`Could not apply mixin because member "${name}" already exists on target!`);
        }
        else {
            const propertyDescriptor = Object.getOwnPropertyDescriptor(mixin.prototype, name);
            Object.defineProperty(MapController.prototype, name, propertyDescriptor || Object.create(null));
        }
    }
}
MapController.prototype.init = function init() {
    BaseMapController.prototype.init.apply(this);
    for (const fn of initFunctions) {
        fn.apply(this);
    }
};
