import { useCallback, useEffect, useRef, useState, } from "react";
export default function useStickyHeader({ stuckHeaderSize = 30, resetDeps = [], }) {
    var _a;
    const [isHeaderStuck, setIsHeaderStuck] = useState(false);
    const [stickThreshold, setStickThreshold] = useState(0);
    const stickyHeaderRef = useRef();
    const stickyScrollAreaRef = useRef();
    const onScroll = useCallback((e) => {
        setIsHeaderStuck(stickThreshold < e.currentTarget.scrollTop);
    }, [stickThreshold]);
    useEffect(() => {
        if (stickyScrollAreaRef.current) {
            stickyScrollAreaRef.current.scrollTop = 0;
        }
        setIsHeaderStuck(false);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...resetDeps]);
    useEffect(() => {
        if (stickyHeaderRef.current) {
            const headerSize = stickyHeaderRef.current.offsetHeight;
            setStickThreshold(headerSize - stuckHeaderSize);
        }
    }, [(_a = stickyHeaderRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight, stuckHeaderSize]);
    return {
        isHeaderStuck,
        stickyHeaderRef,
        stickyScrollAreaRef,
        onScroll,
    };
}
