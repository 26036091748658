export default {
    optionMap: {
        extent: "setExtent",
        maxResolution: "setMaxResolution",
        minResolution: "setMinResolution",
        opacity: "setOpacity",
        visible: "setVisible",
        zIndex: "setZIndex",
    },
    initialOptionMap: {
        extent: "extent",
        maxResolution: "maxResolution",
        minResolution: "minResolution",
        opacity: "opacity",
        visible: "visible",
        zIndex: "zIndex",
    },
};
