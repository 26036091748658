var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { connect, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { MAP } from "../../config/constants/controllers";
import GroupedSwitcher from "../switcher/GroupedSwitcher";
function getGroupedIdsForIds(ids, layersState) {
    const groupedLayerIds = {};
    const withoutGroupLayerIds = [];
    ids.forEach((layerId) => {
        const group = layersState[layerId].metaData.group;
        if (!group) {
            withoutGroupLayerIds.push(layerId);
            return;
        }
        groupedLayerIds[group] = groupedLayerIds[group] || [];
        groupedLayerIds[group].push(layerId);
    });
    return {
        ungroupedIds: withoutGroupLayerIds,
        groupedIds: groupedLayerIds,
    };
}
const GroupedLayerSwitcher = connect(createSelector((state, { layerIdsSelector }) => layerIdsSelector(state[MAP]), (state) => state[MAP].layers, getGroupedIdsForIds), null, (stateProps, dispatchProps, _a) => {
    var { layerIdsSelector } = _a, ownProps = __rest(_a, ["layerIdsSelector"]);
    return (Object.assign(Object.assign({}, ownProps), stateProps));
}, { areStatesEqual: shallowEqual })(GroupedSwitcher);
export default GroupedLayerSwitcher;
