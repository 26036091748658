import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../helpers/i18n";
import { haveSearchInMapSelector, isViewMobileOrMapOnlySelector, } from "../../store/selectors";
import Modal from "../modal";
import Search from "../search";
function SearchOverlay() {
    const [isExpanded, setIsExpanded] = useState(false);
    const searchInMap = useSelector(haveSearchInMapSelector);
    const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);
    const toggleIsExpanded = useCallback(() => {
        setIsExpanded((prevVal) => !prevVal);
    }, [setIsExpanded]);
    const closeSearch = useCallback(() => {
        setIsExpanded(false);
    }, [setIsExpanded]);
    if (searchInMap === false) {
        return null;
    }
    let children;
    if (isMobileOrMapOnly) {
        children = (_jsxs("div", { children: [_jsx("button", { type: "button", className: "ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--search", onClick: toggleIsExpanded, children: _jsx("span", { className: "ms3-map-overlay__button__label", children: _jsx("span", { className: "ms3-visuallyhidden", children: translate(isExpanded
                                ? "ui.map-overlay.search.close"
                                : "ui.map-overlay.search.open") }) }) }), _jsx(Modal, { isOpen: isExpanded, contentLabel: translate("ui.map-overlay.search.modal.label"), closeLabel: translate("ui.map-overlay.search.close"), headline: translate("ui.map-overlay.search.modal.headline"), onRequestClose: closeSearch, children: _jsx(Search, { closeSearch: closeSearch, autoFocus: true }) })] }));
    }
    else {
        children = _jsx(Search, {});
    }
    return (_jsx("div", { className: `ms3-search-overlay ${isExpanded ? "ms3-search-overlay--expanded" : ""}`, children: children }));
}
export default memo(SearchOverlay);
