import createFeatureSelectionDetailsUrlPlugin from "./common/feature-selection-details-url";
import createLangPlugin from "./common/lang";
import createOlProxyPlugin from "./common/ol-proxy";
import createFeatureDeepLinkPlugin from "./server/feature-deep-link";
/**
 * Create the default set of plugins for use on the server IN ORDER!
 *
 * @param {Record<string, object>} options options map for default plugins
 * @returns {import('../types').PluginDefinition[]} sorted list of plugins
 */
export default function createDefaultPlugins(options = {}) {
    return [
        ["lang", createLangPlugin()],
        ["olProxy", createOlProxyPlugin(options.olProxy)],
        [
            "featureDeepLink",
            createFeatureDeepLinkPlugin(options.featureDeepLink),
        ],
        [
            "featureSelectionDetailsUrl",
            createFeatureSelectionDetailsUrlPlugin(options.featureSelectionDetailsUrl),
        ],
    ];
}
