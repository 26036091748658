import DragPan from "ol/interaction/DragPan";
import { Kinetic } from "ol";
import PointerInteraction from "./PointerInteraction";
export default {
    type: "DragPanInteraction",
    Constructor: DragPan,
    optionMap: {
        ...PointerInteraction.optionMap,
    },
    initialOptionMap: {
        ...PointerInteraction.initialOptionMap,
        condition: "condition",
        // a: [decay, minVelocity, delay]
        kinetic(a) {
            if (a && Array.isArray(a)) {
                return {
                    kinetic: new Kinetic(a[0], a[1], a[2]),
                };
            }
            else {
                return {};
            }
        },
    },
};
